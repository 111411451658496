import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import {
  Header,
  Container,
  Table,
  Button,
  Menu,
  Input,
  Checkbox,
  Pagination,
  Message
} from 'semantic-ui-react'
import * as dayjs from 'dayjs'

import { getJwt } from '../utilities/jwt_utils'
import { BASE_URL } from '../settings'

const UserAdmin = () => {
  const {push} = useHistory()

  const [ searchTerm, setSearchTerm ] = useState("")
  const [ infoMessages, setInfoMessages ] = useState([])
  const [ activePage, setActivePage ] = useState(1)
  const [ numPages, setNumPages ] = useState(1)
  const [ showHidden, setShowHidden ] = useState(false)
  const [ sortInfo, setSortInfo ] = useState(
    {name: "datetime_updated", direction: 'descending'}
  )
  const [ users, setUsers ] = useState([])
  const [ needsUpdate, setNeedsUpdate ] = useState(false)


  useEffect(() => {
    setNeedsUpdate(false)
    getJwt()
    .then(jwt => {
      return axios.get(
        BASE_URL + '/users',
        {
          params: {
            _page: activePage,
            q: searchTerm,
            _limit: 12,
            show_hidden: showHidden,
            sort_name: sortInfo.name,
            sort_direction: sortInfo.direction
          },
          headers: {
            'Authorization': "Bearer ".concat(jwt)
          }
        }
      )
    })
    .then(res => {
      console.log('res.data.data: ', res.data.data)
      setNumPages(res.data.data.num_pages || 1)
      setUsers(res.data.data.users || [])
      if ("messages" in res.data) {
        setInfoMessages(res.data.messages)
      }
    })
    .catch(err => {
      console.log("fetchUser error: ", err, "response: ", err.response)
      if (
        err.response !== undefined
        && err.response.data !== undefined
        && err.response.data.messages !== undefined
      ) {
        setInfoMessages(err.response.data.messages)
      }
    })
  }, [
    activePage,
    searchTerm,
    showHidden,
    sortInfo,
    needsUpdate
  ])

  const deleteUser = email => {
    getJwt()
    .then(jwt => axios.delete(
      BASE_URL + '/user',
      {
        params: {
          email: email
        },
        headers: {
          'Authorization': "Bearer ".concat(jwt)
        }
      }
    ))
    .then(res => {
      setInfoMessages(res?.data?.messages || [])
      setNeedsUpdate(true)
    })
    .catch(res => {
      setInfoMessages(res?.response?.data?.messages || [
        {
            "header": "Unspecified error",
            "message": 'Something went wrong, but was not specified.',
            "icon": "database",
            "level": "negative"
        }
      ])
      setNeedsUpdate(true)
    })
  }

  const rowClick = e => {
    let rowId = e.currentTarget.getAttribute('data-key')
    push(`/profile/${rowId}`)
  }

  const handlePageChange = (_, pageInfo) => {
    setActivePage(pageInfo.activePage)
  }

  const handleSearch = (_, searchInfo) => {
    setSearchTerm(searchInfo.value)
    // Go back to first page when searching otherwise might be blank
    setActivePage(1)
  }

  const handleDelete = (e, data) => {
    e.stopPropagation()
    deleteUser(data['data-key'])
  }

  const handleMessageDismiss = (e, data) => {
    let message_id = data["data-key"]
    setInfoMessages(infoMessages.filter(
      (_, idx) => parseInt(idx) !== parseInt(message_id))
    )
  }

  const handleShowHidden = () => {
    setShowHidden(curState => !curState)
  }

  const handleFocus = e => {
    e.target.select()
  }

  const handleSort = name => {
    if (name === sortInfo.name) {
      setSortInfo(info => {
        return {
          direction: (
            info.direction === "ascending" ? 'descending' : "ascending"
          ),
          name: name
        }
      })
    } else{
      setSortInfo(info => ({
        name: name,
        direction: "ascending"
      }))
    }
  }
  return <>
    <Container>
      <Header
        as='h1'
        style={{
          fontSize: '3em',
          fontWeight: 'normal',
          marginBottom: 0,
          marginTop: '1.5em',
        }}
      >
        User Administration
      </Header>
    </Container>
    <Container>
      <Menu>
        <Menu.Item position='left'>
          <Input
            icon='search'
            placeholder='Search...'
            onChange={handleSearch}
            onFocus = {handleFocus}
            autoComplete="off"
          />
        </Menu.Item>
        <Menu.Item position="right">
          <Checkbox
            label="Show hidden"
            name="show_hidden"
            onClick={handleShowHidden}
          />
        </Menu.Item>
      </Menu>
      <Table celled fixed singleLine striped sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              width={2}
              content='Name'
              sorted={
                sortInfo.name === 'user_name' ?
                sortInfo.direction : null
              }
              onClick={() => handleSort('user_name')}
            />
            <Table.HeaderCell
              width={2}
              content='Email'
              sorted={
                sortInfo.name === 'user_email' ?
                sortInfo.direction : null
              }
              onClick={() => handleSort('user_email')}
            />
            <Table.HeaderCell
              width={2}
              content='Affiliation'
              sorted={
                sortInfo.name === 'user_affiliation' ?
                sortInfo.direction : null
              }
              onClick={() => handleSort('user_affiliation')}
            />
            <Table.HeaderCell
              width={2}
              content='Last Updated'
              sorted={
                sortInfo.name === 'datetime_updated' ?
                sortInfo.direction : null
              }
              onClick={() => handleSort('datetime_updated')}
            />
            <Table.HeaderCell
              width={2}
              content='Role'
              sorted={
                sortInfo.name === 'user_role' ? sortInfo.direction : null
              }
              onClick={() => handleSort('user_role')}
            />
            <Table.HeaderCell width={1} content='' />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {users.map((p) =>
            <Table.Row key={p.user_id} data-key={p.user_email} onClick={rowClick}>
              <Table.Cell content={p.user_name}/>
              <Table.Cell content={p.user_email}/>
              <Table.Cell content={p.user_affiliation}/>
              <Table.Cell content={
                dayjs(p.datetime_updated).format('MMM D, YYYY h:mm A')
              }/>
              <Table.Cell content={p.user_role}/>
              <Table.Cell>
                {/* {localStorage.getItem('identity') === p.user_email ? null : */}
                  <Button.Group size='tiny' fluid>
                    <Button
                      basic
                      negative
                      content='Delete'
                      data-key={p.user_email}
                      onClick={handleDelete}
                      floated='right'
                      compact
                    />
                  </Button.Group>
                {/* } */}
              </Table.Cell>
            </Table.Row>
          )}
      </Table.Body>
    </Table>
    {numPages > 1 ?
      <Pagination
        activePage={activePage}
        totalPages={numPages}
        onPageChange={handlePageChange}
      />
      : null
    }
    {infoMessages.map((msg, index) =>
      <Message
        key={index}
        data-key={index}
        attached='bottom'
        icon={msg.icon}
        header={msg.header}
        content={msg.message}
        warning={!Boolean(msg.level) || msg.level === "warning"}
        negative={msg.level === "negative"}
        positive={msg.level === "positive"}
        onDismiss={handleMessageDismiss}
      />
    )}
    </Container>
  </>
}

export default UserAdmin
