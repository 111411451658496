import axios from "axios"
import { BASE_URL } from "../../../settings"

const commonFormSubmitRequest = ({
  isCsv,
  setIsLoading,
  setInfoMessages,
  setTableData,
  formData
}) => {
  const makeReqData = () => {
    // Copy data and remove undefined
    let data = {...formData}
    Object.keys(data).forEach(key => {
      if (data[key] === undefined) {
        delete data[key]
      }
    })

    // Apply the drive filters
    if ('drive_range' in data) {
      if (data.drive_range.includes('Drive')) {
        data.filter_drives = 'drive'
        data.filter_drives_value = parseInt(data.drive_range.split(' ')[1])
      } else if (data.drive_range.includes('Phase')) {
        data.filter_drives = 'phase'
        data.filter_drives_value = parseInt(data.drive_range.split(' ')[1])
      } else {
        data.filter_drives = 'all'
      }
      delete data.drive_range
    }

    // Translate other things if they need it

    return data
  }

  setIsLoading(true)

  let reqData = makeReqData()
  console.log("request:", reqData)

  axios.post(
    BASE_URL + '/playground_common',
    reqData,
    isCsv ? {headers: {Accept: "text/csv"}} : null
  )
  .then(res => {
    if (typeof res.data === "object" && "messages" in res.data) {
      setInfoMessages(res.data.messages)
    } else {
      setInfoMessages([])
    }
    return res
  })
  .then(res => {
    if (isCsv) {
      const type = res.headers['content-type']
      const blob = new Blob([res.data], { type: type, encoding: 'UTF-8' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'playground.csv'
      link.click()
    } else {
      console.log(res.data.data)
      let newTableDat = {
        columns: res.data.data.columns || [],
        data_types: res.data.data.data_types || [],
        data: res.data.data.data || [],
        scalers: res.data.data.scalers || {}
      }
      console.log(newTableDat)
      return newTableDat
    }
  })
  .then(res => {
    if (res) {
      setTableData(res)
    }
  })
  .then(() => setIsLoading(false))
  .catch(err => {
    console.log(err)
    setIsLoading(false)
    setInfoMessages(err?.response?.data?.messages || [
      {
          "header": "Unspecified error",
          "message": 'Something went wrong, but was not specified.',
          "icon": "database",
          "level": "negative"
      }
  ])
  })
}

export default commonFormSubmitRequest
