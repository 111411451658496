import { Fragment } from "react"
import { Dropdown, Form } from "semantic-ui-react"

const DriveSelector = ({handleChange, phaseDrives, queryData, hideDrives}) => {
  const handleDriveChange = (e, { name }) => {
    handleChange(null, {name: "drive_range", value: name})
  }

  return <Form.Group>
    <Form.Dropdown
      label='Select a data range'
      placeholder="Select a data range"
      text={queryData?.drive_range}
    >
      <Dropdown.Menu>
        <Dropdown.Item
          text="All"
          key={'All'}
          name={'All'}
          onClick={handleDriveChange}
        />
        <Dropdown.Item>
          <Dropdown text="Select by phase">
            <Dropdown.Menu>
              {
                Object.keys(phaseDrives)
                .map(phase =>
                  <Dropdown.Item
                    text={`Phase ${phase}`}
                    key={`Phase ${phase}`}
                    onClick={handleDriveChange}
                    name={`Phase ${phase}`}
                  />
                )
              }
            </Dropdown.Menu>
          </Dropdown>
        </Dropdown.Item>
        {hideDrives ? null : <Dropdown.Item>
          <Dropdown text="Select drive" scrolling>
            <Dropdown.Menu>
              {
                Object.entries(phaseDrives)
                .map(phase => {
                  const [phaseNum, driveNums] = phase
                  const drives = driveNums.map(driveNum => <Dropdown.Item
                    text={`Drive ${driveNum}`}
                    key={`Drive ${driveNum}`}
                    onClick={handleDriveChange}
                    name={`Drive ${driveNum}`}
                  />)
                  return (<Fragment key={phaseNum}>
                    <Dropdown.Header
                      content={`Phase ${phaseNum}`}
                      key={`Phase ${phaseNum}`}
                    />
                    {drives}
                  </Fragment>)
                })
              }
            </Dropdown.Menu>
          </Dropdown>
        </Dropdown.Item>}
      </Dropdown.Menu>
    </Form.Dropdown>
    <Form.Button
      label='remove'
      icon='cancel'
      onClick={handleChange}
      name='drive_range'
      value={undefined}
    />
  </Form.Group>
}

export default DriveSelector
