import { useEffect, useState } from "react"
import { Form } from "semantic-ui-react"

import DriveSelector from "./DriveSelector"


const Takeover = ({queryData, handleChange, phaseDrives}) => {
  const [currentSelection, setCurrentSelection] = useState(
    "takeover_gps_location"
  )

  useEffect(() => {
    if (queryData?.child_query_name !== currentSelection) {
      handleChange(
        null, {"name": "child_query_name", "value": currentSelection}
      )
    }

  }, [currentSelection, handleChange, queryData?.child_query_name])


  return (
    <Form>
      <Form.Group>
        <Form.Dropdown
          label="Select a common query"
          value={currentSelection}
          name={'child_query_name'}
          onChange={(e, {value}) => setCurrentSelection(value)}
          options={[
            {
              key: "takeover_gps_location",
              text: "Takeovers",
              value: "takeover_gps_location"
            },
          ]}
        />
      </Form.Group>
      <DriveSelector
        handleChange={handleChange}
        phaseDrives={phaseDrives}
        queryData={queryData}
        hideDrives={false}
      />
    </Form>
  )
}

export default Takeover
