import { useHistory } from "react-router-dom"
import { Card, GridColumn, Grid, Image, Header } from "semantic-ui-react"

const icons = {
  Clear: "/weather_icons/clear.png",
  Clouds: "/weather_icons/clouds.png",
  Rain: "/weather_icons/rain.png",
  Mist: "/weather_icons/mist.png",
  Snow: "/weather_icons/snow.png",
  Haze: "/weather_icons/haze.png"
}

const Weather = ({data}) => {
  const {push} = useHistory()
  const handleWeatherClick = weather => {
    push(`/drive_list/weather/${weather}`)
  }
  return <GridColumn>
    <Card fluid>
      <Card.Content header="Weather"/>
      <Card.Content>
        <Grid columns={(data.weathers || []).length || 1}>
          {
            (data.weathers || []).map(weather => {
              return <Grid.Column key={weather}>
                <Card onClick={() => handleWeatherClick(weather)}>
                  <Card.Content textAlign="center">
                    <Image src={icons[weather]} size='mini' />
                  </Card.Content>
                  <Card.Content style={{padding: "1em 0"}} textAlign='center'>
                    <Header as={'h4'} content={
                    `${weather}: ${(((data?.weather || {})[weather] || 0) * 100).toFixed(0)}%`
                  }/>
                  </Card.Content>
                </Card>
              </Grid.Column>
            })
          }
        </Grid>
      </Card.Content>
    </Card>
  </GridColumn>
}

export default Weather
