import {
  Header,
  List,
  Segment
} from "semantic-ui-react"


const phaseDescriptions = {
  '1': <Segment.Group>
    <Segment content={<>
      <b>Phase 1:</b>
      <Header as={'h1'} content={'Interstate (Divided Highway)'}/>
    </>}/>
    <Segment.Group>
      <Segment>
        <p>
          Phase 1 focused on vehicle navigation along divided, controlled-access
          highways with mixed traffic which included heavy trucks and slow-slow
          moving vehicles. Traffic on these types of roadways travels in the same
          direction and generally adopts similar speeds due to geometric and
          speed limit constraints. For these reasons, controlled-access highways
          and interstates were considered to be the easiest candidates for
          implementing automation and were therefore adopted as the logical
          starting point for the project.
        </p><p>
          Twelve data collection drives were completed as part of Phase 1.
          These drives took place between October 19 and November 3, 2021.
          They occurred at different times of day and during varying lighting and
          weather conditions.
        </p><p>
          Data of specific interest in Phase 1 includes:
        </p>
        <List ordered>
          <List.Item
            content={
              'How the vehicle responded to mixed traffic, which included heavy '
              + 'trucks, slow-moving vehicles, and vulnerable road users'
            }
          />
          <List.Item
            content={
              'How the vehicle responded to merging or vehicle cut-ins'
            }
          />
          <List.Item
            content={
              "The vehicle's reaction to unexpected events"
            }
          />
        </List>
      </Segment>
    </Segment.Group>
  </Segment.Group>,
  '2': <Segment.Group>
    <Segment content={<>
      <b>Phase 2:</b>
      <Header
        as={'h1'}
        content={'Two Lane Undivided Highway / On and Off Ramps'}
      />
    </>}/>
    <Segment.Group>
      <Segment>
        <p>
          The focus of Phase 2 was vehicle navigation along 2-lane undivided
          highways as well as on- and off-ramps. The traffic on undivided
          highways travels in opposite directions, has more variable vehicle
          speeds, and vehicles that may pass in oncoming traffic lanes. On-
          and off-ramps were seen as a unique challenge due to the variable
          geometries and vast differences in speeds of vehicles entering and
          exiting the highways as well as the unpredictability of driver
          behavior that can occur in these locations.
        </p>
        <p>
          Eighteen data collection drives were completed as part of Phase 2.
          These drives took place between February 11 and March 8, 2022. They
          occurred at different times of day and during varying lighting and
          weather conditions.
        </p>
        <p>
          Data of specific interest in Phase 2 includes:
        </p>
        <List ordered>
          <List.Item
            content={
              "How the vehicle responded to mixed traffic, which included "
              + "heavy trucks, slow-moving vehicles, and vulnerable road users"
            }
          />
          <List.Item
            content={
              "The vehicle's ability to merge onto the divided highway"
            }
          />
          <List.Item
            content={
              "The vehicle's reaction to cut-ins"
            }
          />
        </List>
      </Segment>
    </Segment.Group>
  </Segment.Group>,
  '3': <Segment.Group>
    <Segment content={<>
      <b>Phase 3:</b>
      <Header
        as={'h1'}
        content={'Urban Driving'}
      />
    </>}/>
    <Segment.Group>
      <Segment>
        <p>
          Urban areas have a wide variety of intersections and higher traffic
          densities. This phase adds higher levels of automation to navigate
          urban roadways and these intersections under automation.
        </p>
      </Segment>
    </Segment.Group>
  </Segment.Group>,
  '4': <Segment.Group>
    <Segment content={<>
      <b>Phase 4:</b>
      <Header
        as={'h1'}
        content={'Unmarked Roads'}
      />
    </>}/>
    <Segment.Group>
      <Segment>
        <p>
          The lack of any lane marking presents a unique challenge, as there is
           no well-defined lane for either direction of travel. This challenge
           becomes more daunting on unpaved roads. While travel is allowed in
           both directions, drivers often to choose to drive in the center of
           the road for safety reasons, due to a lack of shoulder. As an
           oncoming vehicle approaches, drivers then move closer to edge of
           the roadway.
        </p><p>
          In this phase, our goal will be to have the vehicle drive these
          unmarked and unpaved roads under automation.
        </p>
      </Segment>
    </Segment.Group>
  </Segment.Group>,
  '5': <Segment.Group>
    <Segment content={<>
      <b>Phase 5:</b>
      <Header
        as={'h1'}
        content={'V2X'}
      />
    </>}/>
    <Segment.Group>
      <Segment>
        <p>
        Slow-moving and stopped vehicles pose hazards across our nation's rural
        roads, particularly on steep grades and around curves.
        </p><p>
        This phase will outfit a number of school buses and tractors/slow-moving
        vehicles with on-board telemetry processors. Multiple interactions with
        these vehicles will be orchestrated throughout each drive. During the
        drives, onboard telemetry processors will provide location and speed
        information to our vehicle, enabling it to slow down and/or stop even
        without direct line of sight. This phase will build upon the previous
        phases and augment the automation with connected vehicle data and our
        experience in instrumenting rural school buses with connected vehicle
        technology for demonstrations.
        </p>
      </Segment>
    </Segment.Group>
  </Segment.Group>,
  '6': <Segment.Group>
    <Segment content={<>
      <b>Phase 6:</b>
      <Header
        as={'h1'}
        content={'Parking Areas / Entire Route'}
      />
    </>}/>
    <Segment.Group>
      <Segment>
        <p>
        Parking areas present unique challenges, as streets and parking areas
        each have their own structure and lanes of travel. Parking areas expose
        the ADS to other vehicles and pedestrians in tight spaces.
        </p><p>
        In this phase, we will look at higher levels of automation in two on-street
        parking areas and in two parking lots where we will pick up and drop off
        passengers. We anticipate that some of the enhancements will be parking
        area specific, while other automation enhancements could be more generally
        applicable to navigating all parking areas.
        </p>
      </Segment>
    </Segment.Group>
  </Segment.Group>,
}

const DriveDescription = ({phaseId}) => {
  return <>
    {
      phaseDescriptions[phaseId]
      || <Header as={'h2'} content='No description availible'/>
    }
  </>
}

export default DriveDescription
