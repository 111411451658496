const BASE_URL = (
  (
    window.location.href.indexOf('localhost') !== -1
    || window.location.href.indexOf('127.0.0.1') !== -1
  )
  ? 'http://127.0.0.1:5000/api/v1'
  : '/api/v1'
)

export {
  BASE_URL
}
