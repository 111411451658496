import { useState, useEffect } from 'react'
import axios from 'axios'

import {
  Container,
  Header,
  Form,
  Message,
  Button,
  Grid,
  List
} from "semantic-ui-react"
import { BASE_URL } from '../settings'
import { useHistory } from 'react-router-dom'


const Register = () => {
  const [ regInfo, setRegInfo ] = useState({
    name: '',
    email: '',
    hawkid: '',
    affiliation: '',
    password: '',
    passwordConf: ''
  })
  const [ warning, setWarning ] = useState('')
  const [ infoMessages, setInfoMessages ] = useState([])

  useEffect(() => {
    let newWarning = []
    if (!regInfo.name.trim().length) {
      newWarning.push("Name is required")
      // newWarning.push(<br/>)
    }
    if (!regInfo.email.trim().length) {
      newWarning.push("Email is required")
      // newWarning.push(<br/>)
    }
    if (!regInfo.password.trim().length) {
      newWarning.push("Password is required")
      // newWarning.push(<br/>)
    }
    if (
      regInfo.password !== regInfo.passwordConf
      && regInfo.hawkid.trim().length === 0
    ) {
      newWarning.push("Passwords do not match")
      // newWarning.push(<br/>)
    }
    if (newWarning.length){
      setWarning(newWarning)
    } else {
      setWarning(false)
    }

  }, [regInfo])

  const { push } = useHistory()

  const submitRegistration = () => {
    axios.post(BASE_URL + '/auth/register', regInfo)
    .then(res => {
      if ("messages" in res.data) {
        setInfoMessages(res.data.messages)
      }
    })
    .then(() => {
      setTimeout(() => {
        push('/')
      }, 2000)
    })
    .catch(err => {
      setInfoMessages(err?.response?.data?.messages || [
        {
            "header": "Unspecified error",
            "message": 'Something went wrong, but was not specified.',
            "icon": "database",
            "level": "negative"
        }
    ])
    })
  }

  const handleRegInfoChange = (_, data) => {
    const name = data.name
    const value = data.value
    if (name==='hawkid') {
      setRegInfo(x => ({ ...x, hawkid: value, passwordConf: ''}))
    } else {
      setRegInfo(x => ({ ...x, [name]: value }))
    }
  }

  function handleMessageDismiss(e, data) {
    let message_id = data["data-key"]
    setInfoMessages(infoMessages.filter(
      (_, idx) => parseInt(idx) !== parseInt(message_id)
    ))
  }

  const handleFocus = e => e.target.select()

  return <>
    <Container>
      <Header
        as='h1'
        style={{
          fontSize: '3em',
          fontWeight: 'normal',
          marginBottom: 0,
          marginTop: '1.5em',
        }}
        content='Register'
      />
    </Container>
    <Container
      style={{
        marginTop: '1.5em',
        marginBottom: '1.5em',
      }}
    ><p>
      Individuals affiliated with The University of Iowa may use their HawkID and password
      to register. Users not using a HawkID will receive an email confirmation as part
      of the registration process. Once registration has been completed staff at NADS will
      review the provided information and allocate permissions accordingly.
    </p></Container>
    <Container textAlign='left' style={{marginBottom: '1.5em'}}>
      <Grid>
        <Grid.Column width='9'>
        <Form error={Boolean(warning)}>
          <Form.Input
            label='Email'
            placeholder='name@domain.com'
            name="email"
            value={regInfo.email || ""}
            onChange={handleRegInfoChange}
            autoComplete="off"
            onFocus={handleFocus}
            required
          />
          <Form.Input
            label='HawkID'
            placeholder='UIowa HawkID'
            name="hawkid"
            value={regInfo.hawkid || ""}
            onChange={handleRegInfoChange}
            autoComplete="off"
            onFocus={handleFocus}
          />
          <Form.Input
            label='Name'
            placeholder='Full name'
            name="name"
            value={regInfo.name || ""}
            onChange={handleRegInfoChange}
            autoComplete="off"
            onFocus={handleFocus}
            required
          />
          <Form.Input
            label='Affiliation'
            placeholder='Affiliation'
            name="affiliation"
            value={regInfo.affiliation || ""}
            onChange={handleRegInfoChange}
            autoComplete="off"
            onFocus={handleFocus}
          />
          <Form.Input
            label='Password'
            type='password'
            name="password"
            value={regInfo.password || ""}
            onChange={handleRegInfoChange}
            autoComplete="off"
            onFocus={handleFocus}
            required
          />
          {regInfo.hawkid.trim().length ? null :
            <Form.Input
              label='Password confirmation'
              type='password'
              name="passwordConf"
              value={regInfo.passwordConf || ""}
              onChange={handleRegInfoChange}
              autoComplete="off"
              onFocus={handleFocus}
            />
          }
          <Message
            error
            key='validationError'
            header='Failed validation'
            list={warning || []}
          />
          <Button
            content={'Submit'}
            disabled={Boolean(warning || false)}
            onClick={submitRegistration}
          />
        </Form>
        </Grid.Column>
        <Grid.Column width='5'>
          <Header as='h2'
            style={{
              // fontSize: '1.7em',
              // fontWeight: 'normal',
            }}>
            Password policy:
          </Header>
          <List bulleted>
            <List.Item content={(
              "Passwords must be a minimum of nine (9) characters long, using "
              + "a combination of alphabetic, numeric, and special characters"
            )} key='rule1' />
            <List.Item content={(
              "Passwords cannot contain consecutive, repeated characters "
              + "(e.g., aaaaa11111)"
            )} key='rule2' />
            <List.Item content={(
              "Passwords must be changed annually (every 365 days)"
            )} key='rule3' />
            <List.Item content={(
              "Spaces do not count when determining password complexity or "
              + "length requirements"
            )} key='rule4' />
          </List>
        </Grid.Column>
      </Grid>
    </Container>
    {infoMessages.map((msg, index) =>
        <Message
          key={index}
          data-key={index}
          attached='bottom'
          icon={msg.icon}
          header={msg.header}
          content={msg.message}
          warning={!Boolean(msg.level) || msg.level === "warning"}
          negative={msg.level === "negative"}
          positive={msg.level === "positive"}
          onDismiss={handleMessageDismiss}
        />
      )
    }
  </>
}

export default Register
