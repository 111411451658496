import { Bar } from "react-chartjs-2"
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Card, GridColumn } from "semantic-ui-react"

const miInKm = 0.621371

const RoadTypes = ({data, colors, units}) => {
  return <GridColumn>
    <Card centered fluid>
      <Card.Content>
        <div>Distance by</div>
        <Card.Header
          content={`Federal Functional Code (${units === 'standard' ? 'mi' : 'km'})`} />
      </Card.Content>
      <Card.Content style={{padding: "0"}} >
        <Bar
          data={{
            labels: Object.keys(data.roadTypes),
            datasets: [
              {
                type: 'bar',
                label: 'Manual',
                backgroundColor: colors.manualBackground,
                data: Object.keys(data.roadTypes).map(x =>
                  data.roadTypes[x].Manual * (units === 'standard' ? miInKm : 1)
                ),
                borderColor: colors.manualBorder,
                borderWidth: 2,
                datalabels: {
                  align: 'left',
                }
              },
              {
                type: 'bar',
                label: 'Automated',
                backgroundColor: colors.automatedBackground,
                borderColor: colors.automatedBorder,
                borderWidth: 2,
                data: Object.keys(data.roadTypes).map(x =>
                  data.roadTypes[x].Auto * (units === 'standard' ? miInKm : 1)
                ),
                datalabels: {
                  align: 'left',
                }
              },
            ],
          }}
          plugins={[ChartDataLabels]}
          height="300px"
          options={{
            plugins: {
              datalabels: {
                formatter: x => `${(x || 0).toFixed(0) || ''}`,
                anchor: 'end',
                borderWidth: 1,
                borderRadius: 3,
                padding: 3,
                align: 'right',
                backgroundColor: 'white',
                borderColor: 'grey'
              }
            },
            scales: {
              x: {
                stacked: true,
              },
              y: {
                stacked: true
              }
            },
            maintainAspectRatio: false
          }}
        />
      </Card.Content>
    </Card>
  </GridColumn>
}

export default RoadTypes
