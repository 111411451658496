import ReactMarkdown from "react-markdown"

const formatString = x => {
    if (x.constructor !== String) {
      return x
    }
    // Replace <br> with the MD equivalent and trim lines
    x = x.split('<br>').map(x => x.trim()).join('  \n')
    return <ReactMarkdown children={x}/>
  }

export default formatString
