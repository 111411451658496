import { useHistory, useLocation } from 'react-router-dom'

import {
  Accordion,
  Container,
  Grid,
  Input,
  List,
} from 'semantic-ui-react'

import {
  useState,
  useEffect,
  useRef
} from 'react'

import dictionary_data from '../data/dictionary_data'
import Definition from './DictionaryComponents/Definition'
import formatString from '../utilities/formatString'

let nicks = {}
Object.values(dictionary_data).forEach(topicList => {
  topicList.forEach(topic => {
    let name = topic.name
    let nick = topic.nickname || topic.name
    nicks[name] = nick
  })
})
let keyWords = {}
Object.values(dictionary_data).forEach(topicList => {
  topicList.forEach(topic => {
    let name = topic.name
    let keyWord = topic.key_words || null
    keyWords[name] = keyWord
  })
})

const rootTopics = {
  topics_vehicle: 'Vehicle Sensors',
  topics_v2v: 'V2X',
  topics_passenger_tablet_interface: 'Passenger Interface',
  topics_physiological_data: 'Physiological Data',
  topics_survey: 'Survey Data',
}

const Dictionary = () => {
  const { push } = useHistory()
  const { pathname } = useLocation()
  const accordionRef = useRef()

  const [dictionaryTree, setDictionaryTree] = useState([])
  const [descriptions, setDescriptions] = useState({})
  const [section, setSection] = useState()
  const [topic, setTopic] = useState()
  const [accordionWidth, setAccordionWidth] = useState(250)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchList, setSearchList] = useState([])

  // Load up the dictionary from JSON and set up accordion resize
  useEffect(() => {
    let newDictTree = Object.keys(dictionary_data).map(rootName => ({
      parent: rootName,
      topics: dictionary_data[rootName].map(topic => topic.name)
    }))

    const newWidth = accordionRef.current.clientWidth
    setAccordionWidth(newWidth)

    setDictionaryTree(newDictTree)
    window.addEventListener("resize", () => {
      const newWidth = accordionRef?.current?.clientWidth || 300
      setAccordionWidth(newWidth)
    })
  }, [])

  useEffect(() => {
    let searchTerms = (
      searchTerm
      .split(' ')
      .filter(x => x.trim().length !== 0)
      .map(x => x.toLowerCase().replaceAll(/[-]/g, ''))
    )
    let newTermList = Object.keys(nicks).filter(name =>
      searchTerms.every(term =>
        name.toLowerCase().includes(term)
      )
    )
    let newKeyWordList = Object.keys(keyWords).filter(name =>
      searchTerms.every(term => (keyWords[name] || []).some(
        kw => kw.toLowerCase().includes(term)
      ))
    )

    setSearchList([...new Set([...newTermList, ...newKeyWordList])])
  }, [searchTerm])

  // When path changes update section and topic
  useEffect(() => {
    let curSection = (
      Object.keys(rootTopics).filter(
        x => pathname.indexOf(x) >= 0
      ) || [undefined]
    )[0]

    if (curSection !== undefined) {
      let idx = pathname.indexOf(curSection)
      // need to confirm legit
      let curTopic = pathname.slice(curSection.length + idx) || undefined
      console.log('root:', curSection, ', topic:', curTopic)
      setSection(curSection)
      setTopic(curTopic)
    } else {
      setSection(undefined)
      setTopic(undefined)
    }
  }, [pathname])

  // When topic changes update the definition
  useEffect(() => {
    // Set the descriptions for a selected topic
    const getDescription = (clickedSection, clickedTopic) => {
      let topicObj
      if (clickedSection in dictionary_data) {
        topicObj = dictionary_data[clickedSection].find(x => x.name === clickedTopic) || undefined
      } else {
        setDescriptions({})
        push('/dictionary')
        return null
      }
      if (topicObj === undefined) {
        console.log('tried to find:', clickedTopic, 'in', dictionary_data[clickedSection])
        setDescriptions({})
        push(`/dictionary/${clickedSection}`)
        return null
      }

      let topicOut = {}
      Object.keys(topicObj).filter(
        x => !['data_table', 'key_words'].includes(x)
      ).forEach(key => {
        topicOut[key] = topicObj[key]
      })
      // Add root table
      let neededTables = []
      let dataTable = topicObj.data_table
      let rootTable = {}
      Object.keys(topicObj).forEach(key => {
        if (
          !['data_table', 'key_words'].includes(key)
          && topicObj[key].trim())
        {
          rootTable[key] = topicObj[key]
        }
      })
      rootTable['rows'] = dataTable?.map(row => {
        if (row.data_type.constructor === Array) {
          neededTables.push([row.name])
          return {
            ...row,
            data_type: "sub-table",
            description: formatString(row.description)
          }
        } else {
          return {
            ...row,
            description: formatString(row.description)
          }
        }
      })
      let tables = [rootTable]
      // Add sub-tables
      while (neededTables.length > 0) {
        let curTable = neededTables.shift()
        let curTableData = [...dataTable]
        let curName = [clickedTopic].concat(curTable).join('/')
        curTable.forEach(sub => {
          curTableData = (
            curTableData.data_type
            ? curTableData.data_type
            : curTableData
          ).find(x => x.name === sub)
        })
        let outTable = {name: curName}
        Object.keys(curTableData).forEach(key => {
          if (
            key !== 'data_type'
            && key !== 'name'
            && curTableData[key].trim()
          ) {
            outTable[key] = formatString(curTableData[key])
          }
        })
        outTable['rows'] = curTableData.data_type?.map(row => {
          if (row.data_type.constructor === Array) {
            neededTables.push(curTable.concat([row.name]))
            return {
              ...row,
              // name: curName,
              data_type: "sub-table",
              description: formatString(row.description)
            }
          } else {
            return {
              ...row,
              // name: curName,
              description: formatString(row.description)
            }
          }
        })
        tables.push(outTable)
      }
      topicOut.tables = tables
      console.log('topicOut:', topicOut)
      setDescriptions(topicOut)
    }

    if (section !== undefined && topic !== undefined){
      getDescription(section, topic)
    } else {
      setDescriptions({})
    }
  }, [section, topic, push])

  // When topic is clicked navigate to correct URL
  const handleTopicSelect = (a, b) => {
    let [clickedSection, clickedTopic] = b['data-index'].split(':')
    push(`/dictionary/${clickedSection}${clickedTopic}`)
  }

  const handleSearchClick = (_, b) => {
    let clickedTopic = b['data-index']
    // Find section
    let section = dictionaryTree.find(x => {
      return x.topics.includes(clickedTopic)
    }).parent
    push(`/dictionary/${section}${clickedTopic}`)
  }

  return (
  <Grid padded='vertically' style={{paddingBottom: '70px'}}>
      <Grid.Column width={5} >
        <Container textAlign='left' >
          <div ref={accordionRef}>
            <div
            style={{
              position: 'fixed',
              overflowY: 'scroll',
              overflowX: 'hidden',
              width: `${accordionWidth}px`,
              bottom: '57px',
              top: '156px',
            }}
            >
              <Input
                icon={{ name: 'search', circular: true, link: true }}
                placeholder='Search...'
                name='searchTerm'
                value={searchTerm}
                onChange={(e, { value }) => setSearchTerm( value )}
                style={{width: `${accordionWidth-10}px`}}
              />
              { searchTerm.trim().length === 0 ? <Accordion
                styled
                style={{textDecoration: 'underline'
                }}
                panels={
                  dictionaryTree.map(parent => ({
                    key: parent.parent,
                    title: rootTopics[parent.parent],
                    content: {content: <List celled key={parent.parent}>
                      {
                        parent.topics.map((subTopic, i) => (
                          <List.Item
                            content={`${subTopic}`}
                            style={{
                              textDecoration: 'underline',
                              cursor: 'pointer',
                              fontWeight: subTopic === topic ? 'bold' : 'initial',
                              background: (i % 2) === 1 ? '#EEEEEE' : '#FFFFFF'
                            }}
                            key={`${parent.parent}/${subTopic}`}
                            data-index={`${parent.parent}:${subTopic}`}
                            onClick={handleTopicSelect}
                            active={subTopic === topic}
                          />
                        ))
                      }
                    </List>}
                  }))
                }
                />
                : <List celled>
                  {
                    searchList.map(name => {
                      return <List.Item
                        content={name}
                        key={name}
                        data-index={name}
                        onClick={handleSearchClick}
                      />
                    })
                  }
                </List>
              }
            </div>
          </div>
        </Container>
      </Grid.Column>
      <Grid.Column width={10}>
        <Definition descriptions={descriptions}/>
      </Grid.Column>
    <Grid.Column width={1} />
  </Grid>)
}

export default Dictionary
