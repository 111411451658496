import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import ReactGA from "react-ga4";


const usePageTracking = () => {
  const location = useLocation()
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (
      !window.location.href.includes("localhost")
      && !window.location.href.includes("127.0.0.1")
    ) {
      ReactGA.initialize("G-2N62E4V33Q")
      setInitialized(true)
    }
  }, [])

  useEffect(() => {
    if (initialized) {
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search});
    }
  }, [location, initialized])
}

export default usePageTracking
