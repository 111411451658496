import { useEffect, useState } from "react"
import { Form } from "semantic-ui-react"

import DriveSelector from "./DriveSelector"


const PassengerData = ({queryData, handleChange, phaseDrives}) => {
  const [currentSelection, setCurrentSelection] = useState(
    "passengers_by_gender"
  )

  useEffect(() => {
    if (queryData?.child_query_name !== currentSelection) {
      handleChange(
        null, {"name": "child_query_name", "value": currentSelection}
      )
    }

  }, [currentSelection, handleChange, queryData?.child_query_name])


  return (
    <Form>
      <Form.Group>
        <Form.Dropdown
          label="Select a common query"
          value={currentSelection}
          name={'child_query_name'}
          onChange={(e, {value}) => setCurrentSelection(value)}
          options={[
            {
              key: "passengers_by_gender",
              text: "Numbers by gender",
              value: "passengers_by_gender"
            }, {
              key: "passengers_by_age",
              text: "Number by age catagories",
              value: "passengers_by_age"
            }, {
              key: "passengers_by_education",
              text: "Number by education",
              value: "passengers_by_education"
            }, {
              key: "passengers_by_income",
              text: "Number by income",
              value: "passengers_by_income"
            }, {
              key: "passengers_by_mobility",
              text: "Number of passengers using mobility aid",
              value: "passengers_by_mobility"
            }, {
              key: "passengers_by_visuality",
              text: "Number of passengers with visual impairment",
              value: "passengers_by_visuality"
            }, {
              key: "passengers_gender_by_age",
              text: "Number by gender by age category",
              value: "passengers_gender_by_age"
            }, {
              key: "passengers_gender_by_education",
              text: "Number by gender by education",
              value: "passengers_gender_by_education"
            }, {
              key: "passengers_gender_by_income",
              text: "Number by gender by income",
              value: "passengers_gender_by_income"
            }, {
              key: "passengers_gender_by_mobility",
              text: "Number by gender by mobility impaired",
              value: "passengers_gender_by_mobility"
            }, {
              key: "passengers_gender_by_visuality",
              text: "Number by gender by visually impaired",
              value: "passengers_gender_by_visuality"
            }, {
              key: "passengers_age_by_education",
              text: "Number by age by education",
              value: "passengers_age_by_education"
            }, {
              key: "passengers_age_by_income",
              text: "Number by age by income",
              value: "passengers_age_by_income"
            }, {
              key: "passengers_age_by_mobility",
              text: "Number by age by mobility impaired",
              value: "passengers_age_by_mobility"
            }, {
              key: "passengers_age_by_visuality ",
              text: "Number by age by visually impaired",
              value: "passengers_age_by_visuality "
            },
          ]}
        />
      </Form.Group>
      <DriveSelector
        handleChange={handleChange}
        phaseDrives={phaseDrives}
        queryData={queryData}
        hideDrives={false}
      />
    </Form>
  )
}

export default PassengerData
