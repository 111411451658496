import { useEffect, useState } from "react"
// import ReactMarkdown from "react-markdown"
import {
  Card,
  Grid,
  Icon,
} from "semantic-ui-react"
import jwt_decode from 'jwt-decode'

import {nowTrunc} from '../../utilities/jwt_utils'
import {
  useHistory,
  // useLocation
} from "react-router-dom/cjs/react-router-dom.min"


const ExploreDrive = ({
  driveId,
}) => {
  const { push } = useHistory()
  // const { pathname } = useLocation()

  const [ isLoggedIn, setIsLoggedIn ] = useState(false)
  const [ userRole, setUserRole ] = useState("")
  // const [ driveName ]

  useEffect(() => {
    let refreshToken = localStorage.getItem("refresh_token")
    let role = localStorage.getItem("user_role")
    if (refreshToken) {
      let decoded = jwt_decode(refreshToken, nowTrunc())
      if ("exp" in decoded && decoded.exp > nowTrunc()){
        setIsLoggedIn(true)
        setUserRole(role)
      } else if (isLoggedIn) {
        setIsLoggedIn(false)
        setUserRole("")
      }
    } else if (isLoggedIn) {
      setIsLoggedIn(false)
      setUserRole("")
    }
  }, [isLoggedIn])

  return (
    <Grid.Column>
      { ['admin', 'superuser', 'registered'].includes(userRole) ?
        <Card
          fluid
          onClick={() => push(`/Explore/${driveId}`)}
          style={{background: "rgb(255, 205, 0)"}}
        >
          <Card.Content>
            <Card.Header>
              <Icon name="bus" style={{paddingRight: "10px"}}/>
              {`Explore drive ${driveId}`}
            </Card.Header>
          </Card.Content>
        </Card>
        : null
      }
    </Grid.Column>
  )
}

export default ExploreDrive
