import axios from 'axios'
import {
  Container,
  Divider,
  Form,
  Menu,
  Dropdown,
} from 'semantic-ui-react'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import jwt_decode from 'jwt-decode'
import {nowTrunc} from '../utilities/jwt_utils'

import { BASE_URL } from '../settings'
import DashboardAll from './RootRouteComponents/DashboardAll'
import DashboardPhase from './RootRouteComponents/DashboardPhase'
import DashboardDrive from './RootRouteComponents/DashboardDrive'
import WelcomeBanner from './RootRouteComponents/WelcomeBanner'
import getDrives from './api/getDrives'




const RootRoute = () => {
  const { pathname } = useLocation()
  const { push } = useHistory()

  const [ chartDataDisplayed, setChartDataDisplayed ] = useState({
    milesLabels: [],
    automatedMiles: [],
    manualMiles: [],
    accumulatedMiles: [],
    roadConditions: {},
    roadTypes: {},
    apolloObstacles: {},
    markers: 0,
    vru: {},
    totals: {}
  })

  const [ chartDataAll, setChartDataAll ] = useState({
    milesLabels: [],
    automatedMiles: [],
    manualMiles: [],
    accumulatedMiles: [],
    roadConditions: {},
    roadTypes: {},
    apolloObstacles: {},
    markers: 0,
    totals: {},
    vru: {},
    total_drives: {},
    individualDrives: {},
    individualPhases: {}
  })
  const [ chartFilters, setChartFilters ] = useState({})
  const [ units, setUnits ] = useState('standard')
  const [ drives, setDrives ] = useState({})
  const [ isLoggedIn, setIsLoggedIn ] = useState(false)

  useEffect(() => {
    getDrives({}).then(res => {
      setDrives(res)
    })
    const processStats = res => {
      let roadTypes = {}
      let roadConditions = {}
      let totals = {}
      let safetyCritical = 0
      let apolloObstacles = {}
      let vru = {}
      let v2x = 0
      let numTakeovers = 0
      let markers = 0
      let passengers = []
      let weathers = []
      let totalDrives = {}
      let individualDrives = {}
      let individualPhases = {
        1: {}, 2: {}, 3: {}, 4: {}, 5: {}, 6: {}
      }
      // Skeleton the individualPhase array
      Object.keys(individualPhases).forEach(phase => {
        individualPhases[phase] = {
          roadConditions: {},
          roadTypes: {},
          totals: {},
          markers: 0,
          passengers: [],
          safetyCritical: 0,
          apolloObstacles: {},
          vru: {},
          v2x: 0,
          numTakeovers: 0,
          weathers: [],
        }
      })
      res.forEach(driveDat => {
        let phaseNum = driveDat.phase_number
        // weather
        let weather = {};
        (driveDat.weathers || []).forEach(x => {
          weather[x.weather_label] = x.ratio
        })
        // distance totals
        let totalsDb = {
          Total: driveDat.distance_driven_total,
          Auto: driveDat.distance_driven_auto,
          Manual: driveDat.distance_driven_manual,
          Takeover: driveDat.distance_driven_takeover,
        }
        // road types
        let roadTypesDb = {};
        (driveDat.ffcs || []).forEach(x => {
          roadTypesDb[x.ffc_label] = {
            Manual: parseFloat(x.manual_km), Auto: parseFloat(x.auto_km)
          }
        })
        // road conditions
        let roadConditionsDb = {};
        (driveDat.road_conditions || []).forEach(x => {
          roadConditionsDb[x.road_condition_label] = {
            Manual: parseFloat(x.manual_km), Auto: parseFloat(x.auto_km)
          }
        })
        // air temp
        let temperatureAir = {
          average: driveDat.temperature_air_average,
          low: driveDat.temperature_air_low,
          high: driveDat.temperature_air_high
        }
        // road temp
        let temperatureRoad = {
          average: driveDat.temperature_road_average,
          low: driveDat.temperature_road_low,
          high: driveDat.temperature_road_high
        }
        // wind speed
        let windSpeed = {
          average: driveDat.wind_speed_average,
          low: driveDat.wind_speed_low,
          high: driveDat.wind_speed_high
        }
        // SCE
        let sceCount = 0
        driveDat.markers.forEach(x => {
          if (x.is_sce) {
            sceCount += 1
          }
        })
        // Obstacles
        let obstacles = {
          Total: driveDat.apollo_obstacles_total || 0,
          Unknown: driveDat.apollo_obstacles_unknown || 0,
          "Unknown unmovable": (
            driveDat.apollo_obstacles_unknown_unmovable || 0
          ),
          "Unknown movable": driveDat.apollo_obstacles_unknown_movable || 0,
          Pedestrian: driveDat.apollo_obstacles_pedestrian || 0,
          Bicycle: driveDat.apollo_obstacles_bicycle || 0,
          Vehicle: driveDat.apollo_obstacles_vehicle || 0
        }
        // v2x
        let v2xDb = 0
        v2xDb += driveDat.num_v2x
        // numTakeovers
        let numTakeoversDb = driveDat.num_takeovers
        // vru
        let vruDb = {auto: {}, manual: {}}
        driveDat.markers.forEach(x => {
          if (parseInt(x.marker_vru_type_id) > 0) {
            let description = x.marker_vru_type_description
            let is_type = x.is_auto === true ? "auto" : "manual"
            if (!(description in vruDb[is_type])) {
              vruDb[is_type][description] = 0
            }
            vruDb[is_type][description] += 1
          }
        })
        // markersDb
        let markersDb = {}
        driveDat.markers.forEach(x => {
          markersDb[x.marker_number] = x.dashboard_name
        })

        // passengers
        let passengersDb = [];
        (driveDat.passengers || []).forEach(x => {
          let impairments = []
          if (x.is_visually_impaired) impairments = impairments.concat("Visually")
          if (x.is_mobility_impaired) impairments = impairments.concat("Mobility")
          let newPass = {
            sex: x.sex,
            age: x.age,
            impairments: impairments
          }
          passengersDb = passengersDb.concat(newPass)
        })

        // individual drives
        individualDrives[driveDat.drive_name || "Unnamed"] = {
          date: driveDat["date"],
          route: driveDat["route"] || "no route available",
          driveName: driveDat["drive_name"] || "no name available",
          phase: driveDat["phase_number"] || 0,
          startLocation: driveDat["drive_start_location"] || "no location available",
          startDttm: driveDat["drive_start_time"] || "no time available",
          stopDttm: driveDat["drive_end_time"] || "no time available",
          driveDuration: driveDat["drive_duration"] || 0.0,
          driveNotes: driveDat["drive_notes"] || "",
          weather: weather,
          weathers: Object.keys(weather),
          totals: totalsDb,
          roadTypes: roadTypesDb,
          markers: (driveDat["markers"] || []).length,
          roadConditions: roadConditionsDb,
          temperature_air: temperatureAir,
          temperature_road: temperatureRoad,
          wind_speed: windSpeed,
          safetyCritical: sceCount,
          apolloObstacles: obstacles,
          v2x: v2xDb,
          vru: vruDb,
          numTakeovers: numTakeoversDb,
          passengers: passengersDb,
          markersFull: markersDb
        }

        // v2x
        v2x += v2xDb
        individualPhases[phaseNum].v2x += v2xDb

        //numTakeovers
        numTakeovers += numTakeoversDb
        individualPhases[phaseNum].numTakeovers += numTakeoversDb

        // safetyCritical
        safetyCritical += sceCount
        individualPhases[phaseNum].safetyCritical += sceCount

        // markers
        markers += (driveDat["markers"] || []).length
        individualPhases[phaseNum].markers += (driveDat["markers"] || []).length

        // passengers
        passengers = [...passengers, ...passengersDb]
        individualPhases[phaseNum].passengers = [
          ...individualPhases[phaseNum].passengers,
          ...passengersDb
        ]

        // apollo_obstacles
        Object.keys(obstacles).forEach(ob => {
          // all data
          if (!(ob in apolloObstacles)) {
            apolloObstacles[ob] = 0.0
          }
          apolloObstacles[ob] = (
            (parseFloat(apolloObstacles[ob]) || 0.0) + (
              parseFloat(obstacles[ob]) || 0.0
            )
          )
          // individualPhases
          if (!(ob in individualPhases[phaseNum].apolloObstacles)) {
            (
              individualPhases[phaseNum].apolloObstacles[ob]
              = {Manual: 0.0, Auto: 0.0, "Take Over": 0.0}
            )
          }
          individualPhases[phaseNum].apolloObstacles[ob] = (
            (parseFloat(individualPhases[phaseNum].apolloObstacles[ob]) || 0.0)
            + (parseFloat(obstacles[ob]) || 0.0)
          )
        })

        // vru
        Object.keys(vruDb).forEach(vruType => {
          // all data
          if (!(vruType in vru)) {
            vru[vruType] = {}
          }
          Object.keys(vruDb[vruType]).forEach(vruName => {
            if (!(vruName in vru[vruType])) {
              vru[vruType][vruName] = 0
            }
            vru[vruType][vruName] += vruDb[vruType][vruName]
          })

          // individualPhases
          if (!(vruType in individualPhases[phaseNum].vru)) {
            individualPhases[phaseNum].vru[vruType] = {}
          }
          Object.keys(vruDb[vruType]).forEach(vruName => {
            if (!(vruName in individualPhases[phaseNum].vru[vruType])) {
              individualPhases[phaseNum].vru[vruType][vruName] = 0
            }
            individualPhases[phaseNum].vru[vruType][vruName] += vruDb[vruType][vruName]
          })
        })

        // totalDrives
        if (phaseNum) {
          if (!(phaseNum in totalDrives)) {
            totalDrives[phaseNum] = 0
          }
          totalDrives[phaseNum] += 1
        }

        // weathers
        Object.keys(weather || {}).forEach(w => {
          if (!weathers.includes(w)) {
            weathers = [...weathers, w]
          }
          if (!individualPhases[phaseNum].weathers.includes(w)) {
            individualPhases[phaseNum].weathers = [
              ...individualPhases[phaseNum].weathers,
              w
            ]
          }
        })

        // distance_driven_total
        Object.keys(totalsDb).forEach(total => {
          // all data
          if (!(total in totals)) {
            totals[total] = 0.0
          }
          totals[total] = (
            (parseFloat(totals[total]) || 0.0) + (
              parseFloat(totalsDb[total]) || 0.0
            )
          )
          // individualPhases
          if (!(total in individualPhases[phaseNum].totals)) {
            individualPhases[phaseNum].totals[total] = {
              Manual: 0.0, Auto: 0.0, "Take Over": 0.0
            }
          }
          individualPhases[phaseNum].totals[total] = (
            (parseFloat(individualPhases[phaseNum].totals[total]) || 0.0)
            + (parseFloat(totalsDb[total]) || 0.0)
          )
        })

        // road_conditions
        Object.keys(roadConditionsDb || {}).forEach(roadCond => {
          if (!(roadCond in roadConditions)) {
            roadConditions[roadCond] = {Manual: 0.0, Auto: 0.0}
          }
          roadConditions[roadCond].Manual = (
            roadConditions[roadCond].Manual + roadConditionsDb[roadCond].Manual
          )
          roadConditions[roadCond].Auto = (
            roadConditions[roadCond].Auto + roadConditionsDb[roadCond].Auto
          )
          if (!(roadCond in individualPhases[phaseNum].roadConditions)) {
            individualPhases[phaseNum].roadConditions[roadCond] = {
              Manual: 0.0, Auto: 0.0
            }
          }
          individualPhases[phaseNum].roadConditions[roadCond].Manual = (
            individualPhases[phaseNum].roadConditions[roadCond].Manual
            + roadConditionsDb[roadCond].Manual
          )
          individualPhases[phaseNum].roadConditions[roadCond].Auto = (
            individualPhases[phaseNum].roadConditions[roadCond].Auto
            + roadConditionsDb[roadCond].Auto
          )
        })

        // distance_driven_ffc
        Object.keys(roadTypesDb).forEach(ffc => {
          if (!(ffc in roadTypes)) {
            roadTypes[ffc] = {Manual: 0.0, Auto: 0.0}
          }
          roadTypes[ffc].Manual = (
            roadTypes[ffc].Manual + roadTypesDb[ffc].Manual
          )
          roadTypes[ffc].Auto = (
            roadTypes[ffc].Auto + roadTypesDb[ffc].Auto || 0.0
          )
          if (!(ffc in individualPhases[phaseNum].roadTypes)) {
            individualPhases[phaseNum].roadTypes[ffc] = {
              Manual: 0.0, Auto: 0.0
            }
          }
          individualPhases[phaseNum].roadTypes[ffc].Manual = (
            individualPhases[phaseNum].roadTypes[ffc].Manual
            + roadTypesDb[ffc].Manual
          )
          individualPhases[phaseNum].roadTypes[ffc].Auto = (
            individualPhases[phaseNum].roadTypes[ffc].Auto +
            + roadTypesDb[ffc].Auto
          )
        })
      })
      return {
        individualDrives: individualDrives,
        individualPhases: individualPhases,
        roadConditions: roadConditions,
        roadTypes: roadTypes,
        totals: totals,
        markers: markers,
        safetyCritical: safetyCritical,
        apolloObstacles: apolloObstacles,
        vru: vru,
        v2x: v2x,
        numTakeovers: numTakeovers,
        passengers: passengers,
        weathers: weathers,
        totalDrives: totalDrives
      }
    }
    axios.get(BASE_URL + "/drive_statistics")
    .then(res => {
      return res?.data
    })
    .then(res => {
      if (res?.data) {
        setChartDataAll(processStats(res.data))
      }
    })
    .catch(err => {
      console.log("GET drive_statistics error: ", err, "response: ", err.response)
    })
  }, [])

  useEffect(() => {
    let refreshToken = localStorage.getItem("refresh_token")
    if (refreshToken) {
      let decoded = jwt_decode(refreshToken, nowTrunc())
      if ("exp" in decoded && decoded.exp > nowTrunc()){
        setIsLoggedIn(true)
      } else if (isLoggedIn) {
        setIsLoggedIn(false)
      }
    } else if (isLoggedIn) {
      setIsLoggedIn(false)
    }
  }, [pathname, isLoggedIn])

  useEffect(() => {
    const blankData = {
      roadConditions: {},
      roadTypes: {},
      totals: {},
      markers: 0,
      v2x: 0,
      numTakeovers: 0,
      apolloObstacles: {}
    }
    if (chartFilters.driveId) {
      let driveId = String(chartFilters.driveId).padStart(3, '0')
      let data = chartDataAll.individualDrives[driveId] || blankData
      setChartDataDisplayed(data)
    } else if (chartFilters.phaseId) {
      let data = chartDataAll.individualPhases[chartFilters.phaseId] || blankData
      setChartDataDisplayed(data)
    } else {
      let data = chartDataAll || blankData
      setChartDataDisplayed(data)
    }
  }, [chartFilters, chartDataAll])

  useEffect(() => {
    if (pathname.indexOf('dashboard') >= 0 || pathname === '/') {
      let drive_info = pathname.split('/').filter(x => x.length > 0).slice(1)
      if (drive_info.length === 0) {
        setChartFilters({})
      } else if (drive_info.length === 1) {
        let phaseId = parseInt(drive_info[0])
        setChartFilters({phaseId: phaseId})
      } else if (drive_info.length === 2) {
        let phaseId = parseInt(drive_info[0])
        let driveId = parseInt(drive_info[1])
        setChartFilters({phaseId: phaseId, driveId: driveId})
      }
    }
  }, [pathname])

  return <>
    {(pathname === '/' && !isLoggedIn) ? <WelcomeBanner push={push} /> : null}
    <Container>
      <Menu>
        <Menu.Item content={<Dropdown
          name='phase'
          label='Phase'
          width={4}
          clearable
          selection
          placeholder='Phase'
          options={
            Object.keys(drives)
            .sort((a, b) => parseInt(a) - parseInt(b))
            .map(x => ({key: x, text: `Phase ${x}`, value: parseInt(x)}))
          }
          value={chartFilters.phaseId || null}
          onChange={(d, {value}) => {
            if (parseInt(value)) {
              push(`/dashboard/${value}`)
            } else {
              push(`/dashboard`)
            }
          }}
        />}/>
        <Menu.Item content={ <Dropdown
          name='drive'
          label='Drive'
          width={4}
          clearable
          selection
          disabled={chartFilters.phaseId === undefined}
          placeholder='Drive'
          options={(drives[String(chartFilters.phaseId)] || [])
            .sort((a, b) => parseInt(a) - parseInt(b))
            .map(x => ({
            key:x,
            text: String(x).padStart(3, '0'),
            value: parseInt(x)
          }))}
          onChange={(d, {value}) => {
            if (parseInt(value)) {
              push(`/dashboard/${chartFilters.phaseId}/${value}`)
            } else {
              push(`/dashboard/${chartFilters.phaseId}`)
            }
          }}
          value={chartFilters.driveId || null}
        /> } />
        <Menu.Menu position='right'>
          <Menu.Item content={<>
            <Form.Radio
              label='standard'
              checked={units === 'standard'}
              onClick={() => setUnits('standard')}
              width={2}
            />
            <Form.Radio
              label='metric'
              checked={units === 'metric'}
              onClick={() => setUnits('metric')}
              width={2}
              style={{paddingLeft: '2em'}}
            />
          </>} />
        </Menu.Menu>
      </Menu>
      <Form>
        <Form.Group inline widths={'ten'}>

        </Form.Group>
      </Form>
    </Container>

    <Divider style={{paddingTop: '1em'}} />
    {
      (chartFilters.driveId !== undefined)
      ? <DashboardDrive
        chartDataDisplayed={chartDataDisplayed}
        setChartDataDisplayed={setChartDataDisplayed}
        setChartDataAll={setChartDataAll}
        units={units}
        driveId={chartFilters.driveId}
      /> : null
    }
    {
      (
        (chartFilters.driveId === undefined)
        && (chartFilters.phaseId !== undefined)
      )
      ? <DashboardPhase
        chartDataDisplayed={chartDataDisplayed}
        units={units}
        chartFilters={chartFilters}
      /> : null
    }
    {
      (
        (chartFilters.driveId === undefined)
        && (chartFilters.phaseId === undefined)
      )
      ? <DashboardAll
        chartDataDisplayed={chartDataDisplayed}
        push={push}
        units={units}
      /> : null
    }
  </>
}

export default RootRoute
