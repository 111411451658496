import {
  BrowserRouter,
  Switch,
  Route
} from "react-router-dom"

// import 'semantic-ui-css/semantic.min.css'
import 'semantic-ui-less/semantic.less'
// import ReactGA from 'react-ga'
// import { useEffect } from "react"

import MenuBarH from "./MenuBarH"
import Dictionary from "./pages/Dictionary"
import RootRoute from "./pages/RootRoute"
import Login from "./pages/Login"
import Register from "./pages/Register"
import UserAdmin from "./pages/UserAdmin"
import Profile from "./pages/Profile"
import Reset from "./pages/Reset"
import Confirm from "./pages/Confirm"
import Playground from "./pages/Playground"
import Footer from "./pages/Footer"
import usePageTracking from "./usePageTracking"
import Markers from "./pages/Markers"
import DriveList from "./pages/DriveList"
import Explore from "./pages/Explore"

const App = () => {
  usePageTracking()

  return (
    <BrowserRouter >
      <MenuBarH />
      <div
        style={{
          paddingTop: '1.5em',
          // paddingBottom: '70px',
        }}
      >
        <Switch>
          <Route path='/profile/:username'>
            <Profile />
          </Route>
          <Route path='/playground'>
            <Playground />
          </Route>
          <Route path='/markers/:drive_name'>
            <Markers />
          </Route>
          <Route path='/markers'>
            <Markers />
          </Route>
          <Route path='/drive_list/:filterType/:filterValue/:phase'>
            <DriveList />
          </Route>
          <Route path='/drive_list/:filterType/:filterValue'>
            <DriveList />
          </Route>
          <Route path='/useradmin'>
            <UserAdmin />
          </Route>
          <Route path='/dictionary/:topic'>
            <Dictionary />
          </Route>
          <Route path='/dictionary'>
            <Dictionary />
          </Route>
          <Route path='/login'>
            <Login />
          </Route>
          <Route path='/register'>
            <Register />
          </Route>
          <Route path='/reset/:token'>
            <Reset />
          </Route>
          <Route path='/confirm/:token'>
            <Confirm />
          </Route>
          <Route path='/Explore/:drive'>
            <Explore />
          </Route>
          <Route path='/dashboard/:drive'>
            <RootRoute />
          </Route>
          <Route path='/'>
            <RootRoute />
          </Route>
        </Switch>
      </div>
      <Footer />
    </BrowserRouter>
  )
}

export default App
