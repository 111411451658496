import { Image } from "semantic-ui-react"

const WelcomeBanner = () => {

return <div style={{backgroundColor: 'rgb(99,102,106)', paddingTop: '1.5em', marginTop: '-20px', marginBottom: '30px'}}><div className="ui center aligned container" style={{color: 'white', padding:'1.6em'}} >
    <p style={{fontSize: '1.3em,'}}>
      Welcome to the online data portal for the <a href="https://adsforruralamerica.uiowa.edu" style={{color:'white', textDecoration:'underline'}}>ADS for Rural America project</a>. Data collection for this project is organized into six phases focusing on different automation challenges, with each phase building upon the last.</p>
    <p style={{fontSize: '1.1em'}}>This page presents a data overview for all completed phases and drives, as shown below.</p>
    <div style={{width:'100%', height:'2px', background:'rgba(255,255,255,0.1)', marginBottom:'10px'}}></div>
    <div style={{display:'flex', alignItems:'flex-start', justifyContent:'space-between'}}>
        <div style={{display:'flex', alignItems:'flex-start', justifyContent:'flex-start', margin:'0.4em 0.4em 0.4em 0'}}>
            <Image src="https://www.nads-sc.uiowa.edu/ADSFRA/button_dictionary.png" />
            <p>The "Dictionary" provides information about how the data is organized.</p>
        </div>
        <div style={{display:'flex', alignItems:'flex-start', justifyContent:'flex-start', margin:'0.4em'}}>
            <Image src="https://www.nads-sc.uiowa.edu/ADSFRA/button_playground.png" />
            <p>The "Playground" (under development) will enable users to interact with data and use a query -based approach to working with data subsets. Registration will enable eventual access to the Playground.</p>
        </div>
        <p style={{margin:'0.4em'}}>The data is made available through a grant from the US Department of Transportation.</p>
        <div style={{minWidth:'35%', display:'flex', alignItems:'flex-start', justifyContent:'flex-start', background:'rgba(0,0,0,0.4)', border:'2px solid rgba(255,255,255,0.5)', padding:'0.3em', margin:'0.4em 0 0.4em 0.4em'}}>
            <p style={{margin:'0.4em'}}><b>Need an account?</b><br />If you do not have a login, click the <i>Register</i> button above.</p>
            <Image src="https://www.nads-sc.uiowa.edu/ADSFRA/button_register.png" />
        </div>
    </div>
</div></div>
}

export default WelcomeBanner
