const TableDefinitions = {
    "/apollo_ros_bridge/cyberrt_ros/best_pose": {
      table: "apollo_ros_bridge-cyberrt_ros-best_pose",
      columns: {
        header_frame_id: "string",
        nov_header_OEM7MSGTYPE: "int",
        nov_header_message_name: "string",
        nov_header_message_id: "int",
        nov_header_message_type: "int",
        nov_header_sequence_number: "int",
        nov_header_time_status: "int",
        nov_header_gps_week_number: "int",
        nov_header_gps_week_milliseconds: "int",
        sol_status_status: "int",
        pos_type_type: "int",
        lat: "float",
        lon: "float",
        hgt: "float",
        undulation: "float",
        datum_id: "int",
        lat_stdev: "float",
        lon_stdev: "float",
        hgt_stdev: "float",
        stn_id: "string",
        diff_age: "float",
        sol_age: "float",
        num_svs: "int",
        num_sol_svs: "int",
        num_sol_l1_svs: "int",
        num_sol_multi_svs: "int",
        reserved: "int",
        ext_sol_stat_status: "int",
        galileo_beidou_sig_mask: "int",
        gps_glonass_sig_mask: "int",
      }
    },
    "/apollo_ros_bridge/cyberrt_ros/chassis": {
      table : "apollo_ros_bridge-cyberrt_ros-chassis",
      columns: {
        'engine_on': "bool",
        'drive_mode': "int",
        'gear': "int",
        'throttle': "float",
        'brake': "float",
        'steering_rate': "float",
        'steering': "float",
        'steering_rad': "float",
        'speed': "float",
        'yaw_rate': "float",
        'turn_signal': "int",
        'error_code': "int",
      }
    },
    "/apollo_ros_bridge/cyberrt_ros/chassis_detail": {
      table: "apollo_ros_bridge-cyberrt_ros-chassis_detail",
      columns: {
        accel_report_enabled: "bool",
        accel_report_override_active: "bool",
        accel_report_command_output_fault: "bool",
        accel_report_input_output_fault: "bool",
        accel_report_output_reported_fault: "bool",
        accel_report_pacmod_fault: "bool",
        accel_report_vehicle_fault: "bool",
        accel_report_manual_input: "float",
        accel_report_commanded_value: "float",
        accel_report_output_value: "float",
        brake_report_enabled: "bool",
        brake_report_override_active: "bool",
        brake_report_command_output_fault: "bool",
        brake_report_input_output_fault: "bool",
        brake_report_output_reported_fault: "bool",
        brake_report_pacmod_fault: "bool",
        brake_report_vehicle_fault: "bool",
        brake_report_manual_input: "float",
        brake_report_commanded_value: "float",
        brake_report_output_value: "float",
        steering_report_enabled: "bool",
        steering_report_override_active: "bool",
        steering_report_command_output_fault: "bool",
        steering_report_input_output_fault: "bool",
        steering_report_output_reported_fault: "bool",
        steering_report_pacmod_fault: "bool",
        steering_report_vehicle_fault: "bool",
        steering_report_manual_input: "float",
        steering_report_commanded_value: "float",
        steering_report_output_value: "float",
        hazardlights_report_enabled: "bool",
        hazardlights_report_override_active: "bool",
        hazardlights_report_command_output_fault: "bool",
        hazardlights_report_input_output_fault: "bool",
        hazardlights_report_output_reported_fault: "bool",
        hazardlights_report_pacmod_fault: "bool",
        hazardlights_report_vehicle_fault: "bool",
        hazardlights_report_manual_input: "float",
        hazardlights_report_commanded_value: "float",
        hazardlights_report_output_value: "float",
        headlights_report_headlights_mode_valid: "bool",
        headlights_report_headlights_mode_headlights_mode: "int",
        headlights_report_fog_lights_on_valid: "bool",
        headlights_report_fog_lights_on: "bool",
        headlights_report_headlights_on_bright_valid: "bool",
        headlights_report_headlights_on_bright: "bool",
        headlights_report_headlights_on_valid: "bool",
        headlights_report_headlights_on: "bool",
        occupancy_report_driver_seat_occupied_valid: "bool",
        occupancy_report_driver_seat_occupied: "bool",
        occupancy_report_driver_seatbelt_valid: "bool",
        occupancy_report_driver_seatbelt: "bool",
        occupancy_report_pass_seat_occupied_valid: "bool",
        occupancy_report_pass_seat_occupied: "bool",
        occupancy_report_pass_seatbelt_valid: "bool",
        occupancy_report_pass_seatbelt: "bool",
        occupancy_report_rear_seat_occupied_valid: "bool",
        occupancy_report_rear_seat_occupied: "bool",
        occupancy_report_rear_seatbelt_valid: "bool",
        occupancy_report_rear_seatbelt: "bool",
      }
    },
    "/apollo_ros_bridge/cyberrt_ros/conti_radar/front": {
      table: "apollo_ros_bridge-cyberrt_ros-conti_radar-front",
      columns: {
        radar_state_max_distance: "int",
        radar_state_radar_power: "int",
        radar_state_output_type: "int",
        radar_state_rcs_threshold: "int",
        radar_state_send_quality: "bool",
        radar_state_send_ext_info: "bool",
        cluster_list_status_near: "int",
        cluster_list_status_far: "int",
        cluster_list_status_meas_counter: "int",
        cluster_list_status_interface_version: "int",
        object_list_status_nof_objects: "int",
        object_list_status_meas_counter: "int",
        object_list_status_interface_version: "int",
      }
    },
    "/apollo_ros_bridge/cyberrt_ros/conti_radar/rear": {
      table: "apollo_ros_bridge-cyberrt_ros-conti_radar-rear",
      columns: {
        radar_state_max_distance: "int",
        radar_state_radar_power: "int",
        radar_state_output_type: "int",
        radar_state_rcs_threshold: "int",
        radar_state_send_quality: "bool",
        radar_state_send_ext_info: "bool",
        cluster_list_status_near: "int",
        cluster_list_status_far: "int",
        cluster_list_status_meas_counter: "int",
        cluster_list_status_interface_version: "int",
        object_list_status_nof_objects: "int",
        object_list_status_meas_counter: "int",
        object_list_status_interface_version: "int",
      }
    },
    "/apollo_ros_bridge/cyberrt_ros/control_cmd": {
      table: "apollo_ros_bridge-cyberrt_ros-control_cmd",
      columns: {
        drive_mode_drive_mode: "int",
        gear_gear: "int",
        throttle: "float",
        brake: "float",
        steering_rate: "float",
        steering: "float",
        steering_rad: "float",
        speed: "float",
        acceleration: "float",
        latency_time: "float",
        turn_signal_turn_signal: "int",
        speed_reference: "float",
        speed_error: "float",
        lateral_error: "float",
        engage_advice_engage_advice: "int",
      }
    },
    "/apollo_ros_bridge/cyberrt_ros/corrected_imu": {
      table: "apollo_ros_bridge-cyberrt_ros-corrected_imu",
      columns: {
        orientation_x: "float",
        orientation_y: "float",
        orientation_z: "float",
        orientation_w: "float",
        // orientation_covariance: "matrix",
        angular_velocity_x: "float",
        angular_velocity_y: "float",
        angular_velocity_z: "float",
        // angular_velocity_covariance: "matrix",
        linear_acceleration_x: "float",
        linear_acceleration_y: "float",
        linear_acceleration_z: "float",
        // linear_acceleration_covariance: "matrix",
      }
    },
    "/apollo_ros_bridge/cyberrt_ros/odom": {
      table: "apollo_ros_bridge-cyberrt_ros-odom",
      columns: {
        child_frame_id: "string",
        pose_pose_position_x: "float",
        pose_pose_position_y: "float",
        pose_pose_position_z: "float",
        pose_pose_orientation_x: "float",
        pose_pose_orientation_y: "float",
        pose_pose_orientation_z: "float",
        pose_pose_orientation_w: "float",
        // pose_covariance: "matrix",
        twist_twist_linear_x: "float",
        twist_twist_linear_y: "float",
        twist_twist_linear_z: "float",
        twist_twist_angular_x: "float",
        twist_twist_angular_y: "float",
        twist_twist_angular_z: "float",
        // twist_covariance: "matrix",
      }
    },
    // // Nothing interesting here until we include children
    // "/apollo_ros_bridge/cyberrt_ros/path": {
    //   table: "apollo_ros_bridge-cyberrt_ros-path",
    //   columns: {

    //   }
    // },
    // // Nothing interesting here until we include children
    // "/apollo_ros_bridge/cyberrt_ros/perception_obstacles": {
    //   table: "apollo_ros_bridge-cyberrt_ros-perception_obstacles",
    //   columns: {

    //   }
    // },
    // // Nothing interesting here until we include children
    // "/apollo_ros_bridge/cyberrt_ros/tf": {
    //   table: "apollo_ros_bridge-cyberrt_ros-tf",
    //   columns: {

    //   }
    // },
    "/apollo_ros_bridge/cyberrt_ros/velodyne/fusion/points": {
      table: "apollo_ros_bridge-cyberrt_ros-velodyne-fusion-points",
      columns: {
        height: "int",
        width: "int",
        is_bigendian: "bool",
        point_step: "int",
        row_step: "int",
        // data: "data",
        is_dense: "int",
      },
      subs: {
        fields: {  // Name of relationship in ORM
          name: "string",
          offset: "int",
          datatype: "int",
          count: "int",
        }
      }
    },
    "/chassis_ext": {
      table: "chassis_ext",
      columns: {
        odo_reading: "float",
        odo_reading_e: "float",
      }
    },
    "/engagement": {
      table: "engagement",
      columns: {

      }
    },
    "/md30": {
      table: "md30",
      columns: {
        unit_id: "int",
        client_id: "int",
        id: "string",
        icd_version: "string",
        error: "int",
        water_layer_thickness: "float",
        ice_layer_thickness: "float",
        snow_layer_thickness: "float",
        grip: "float",
        road_state: "int",
        en15518_state: "int",
        air_temp: "float",
        relative_air_humidity: "float",
        dew_point_temp: "float",
        frost_point_temp: "float",
        surface_temp: "float",
        analyze_count: "int",
        data_status_warning: "string",
        data_status_error: "string",
        data_status_info: "string",
        unit_status_info: "string",
        unit_error_info: "string",
      }
    },
    "/mobileye/as_tx/lane_markers": {
      table: "mobileye-as_tx-lane_markers",
      columns: {
        ns: "string",
        id: "int",
        type: "int",
        action: "int",
        pose_position_x: "float",
        pose_position_y: "float",
        pose_position_z: "float",
        pose_orientation_x: "float",
        pose_orientation_y: "float",
        pose_orientation_z: "float",
        pose_orientation_w: "float",
        scale_x: "float",
        scale_y: "float",
        scale_z: "float",
        color_r: "float",
        color_g: "float",
        color_b: "float",
        color_a: "float",
        lifetime: "float",
        frame_locked: "bool",
        text: "string",
        mesh_resource: "string",
        mesh_use_embedded_materials: "bool",
      }
    },
    "/mobileye/as_tx/lane_models": {
      table: "mobileye-as_tx-lane_models",
      columns: {
        left_lane_quality: "int",
        left_lane_marker_kind: "int",
        left_lane_curve_model_kind: "int",
        left_lane_marker_offset: "float",
        left_lane_heading_angle: "float",
        left_lane_curvature: "float",
        left_lane_curvature_derivative: "float",
        left_lane_marker_width: "float",
        left_lane_view_range: "float",
        right_lane_quality: "int",
        right_lane_marker_kind: "int",
        right_lane_curve_model_kind: "int",
        right_lane_marker_offset: "float",
        right_lane_heading_angle: "float",
        right_lane_curvature: "float",
        right_lane_curvature_derivative: "float",
        right_lane_marker_width: "float",
        right_lane_view_range: "float",
      }
    },
    "/mobileye/as_tx/object_markers": {
      table: "mobileye-as_tx-object_markers",
      columns: {
        ns: "string",
        id: "int",
        type: "int",
        action: "int",
        pose_position_x: "float",
        pose_position_y: "float",
        pose_position_z: "float",
        pose_orientation_x: "float",
        pose_orientation_y: "float",
        pose_orientation_z: "float",
        pose_orientation_w: "float",
        scale_x: "float",
        scale_y: "float",
        scale_z: "float",
        color_r: "float",
        color_g: "float",
        color_b: "float",
        color_a: "float",
        lifetime: "float",
        frame_locked: "bool",
        text: "string",
        mesh_resource: "string",
        mesh_use_embedded_materials: "bool",
      }
    },
    "/mobileye/as_tx/objects": {
      table: "mobileye-as_tx-objects",
      columns: {
        id: "int",
        detection_level: "int",
        object_classified: "bool",
        pose_pose_position_x: "float",
        pose_pose_position_y: "float",
        pose_pose_position_z: "float",
        pose_pose_orientation_x: "float",
        pose_pose_orientation_y: "float",
        pose_pose_orientation_z: "float",
        pose_pose_orientation_w: "float",
        // pose_covariance: "matrix",
        twist_twist_linear_x: "float",
        twist_twist_linear_y: "float",
        twist_twist_linear_z: "float",
        twist_twist_angular_x: "float",
        twist_twist_angular_y: "float",
        twist_twist_angular_z: "float",
        // twist_covariance: "matrix",
        accel_accel__linear_x: "float",
        accel_accel__linear_y: "float",
        accel_accel__linear_z: "float",
        accel_accel__angular_x: "float",
        accel_accel__angular_y: "float",
        accel_accel__angular_z: "float",
        // accel_covariance: "matrix",
        shape_type: "int",
        // shape_dimensions: "matrix",
        // shape_covariance: "matrix",
        classification: "int",
        classification_certainty: "int",
        classification_age: "int",
      }
    },
    "/mobileye/can_tx": {
      table: "mobileye-can_tx",
      columns: {
        id: "int",
        is_rtr: "bool",
        is_extended: "bool",
        is_error: "bool",
        dlc: "int",
        // data: "data",
      }
    },
    "/mobileye/parsed_tx/aftermarket_lane": {
      table: "mobileye-parsed_tx-aftermarket_lane",
      columns: {
        lane_confidence_left: "int",
        ldw_available_left: "bool",
        lane_type_left: "int",
        distance_to_left_lane: "float",
        lane_confidence_right: "int",
        ldw_available_right: "bool",
        lane_type_right: "int",
        distance_to_right_lane: "float",
      }
    },
    "/mobileye/parsed_tx/aws_display": {
      table: "mobileye-parsed_tx-aws_display",
      columns: {
        suppress_sound: "bool",
        night_time: "bool",
        dusk_time: "bool",
        sound_type: "int",
        headway_valid: "bool",
        headway_measurement: "float",
        lanes_on: "bool",
        left_ldw_on: "bool",
        right_ldw_on: "bool",
        fcw_on: "bool",
        left_crossing: "bool",
        right_crossing: "bool",
        maintenance: "bool",
        failsafe: "bool",
        ped_fcw: "bool",
        ped_in_dz: "bool",
        headway_warning_level: "int",
      }
    },
    "/mobileye/parsed_tx/fixed_foe": {
      table: "mobileye-parsed_tx-fixed_foe",
      columns: {
        fixed_yaw: "float",
        fixed_horizon: "float",
      }
    },
    "/mobileye/parsed_tx/lane": {
      table: "mobileye-parsed_tx-lane",
      columns: {
        lane_curvature: "float",
        lane_heading: "float",
        construction_area: "bool",
        pitch_angle: "float",
        yaw_angle: "float",
        right_ldw_availability: "bool",
        left_ldw_availability: "bool",
      }
    },
    "/mobileye/parsed_tx/left_lka_lane": {
      table: "mobileye-parsed_tx-left_lka_lane",
      columns: {
        lane_type: "int",
        quality: "int",
        model_degree: "int",
        position_parameter_c0: "float",
        curvature_parameter_c2: "float",
        curvature_derivative_parameter_c3: "float",
        marking_width: "float",
        heading_angle_parameter_c1: "float",
        view_range: "float",
        view_range_availability: "bool",
      }
    },
    "/mobileye/parsed_tx/lka_num_of_next_lane_markers_reported": {
      table: "mobileye-parsed_tx-lka_num_of_next_lane_markers_reported",
      columns: {
        num_of_next_lane_markers_reported: "int"
      }
    },
    "/mobileye/parsed_tx/lka_reference_points": {
      table: "mobileye-parsed_tx-lka_reference_points",
      columns: {
        ref_point_1_position: "float",
        ref_point_1_distance: "float",
        ref_point_1_validity: "bool",
        ref_point_2_position: "float",
        ref_point_2_distance: "float",
        ref_point_2_validity: "bool",
      }
    },
    "/mobileye/parsed_tx/next_lka_lanes": {
      table: "mobileye-parsed_tx-next_lka_lanes",
      columns: {
        lane_type: "int",
        quality: "int",
        model_degree: "int",
        position_parameter_c0: "float",
        curvature_parameter_c2: "float",
        curvature_derivative_parameter_c3: "float",
        marking_width: "float",
        heading_angle_parameter_c1: "float",
        view_range: "float",
        view_range_availability: "bool",
      }
    },
    "/mobileye/parsed_tx/obstacle_data": {
      table: "mobileye-parsed_tx-obstacle_data",
      columns: {
        obstacle_id: "int",
        obstacle_pos_x: "float",
        obstacle_pos_y: "float",
        blinker_info: "int",
        cut_in_and_out: "int",
        obstacle_rel_vel_x: "float",
        obstacle_type: "int",
        obstacle_status: "int",
        obstacle_brake_lights: "bool",
        obstacle_valid: "int",
        obstacle_length: "float",
        obstacle_width: "float",
        obstacle_age: "int",
        obstacle_lane: "int",
        cipv_flag: "bool",
        radar_pos_x: "float",
        radar_vel_x: "float",
        radar_match_confidence: "int",
        matched_radar_id: "int",
        obstacle_angle_rate: "float",
        obstacle_scale_change: "float",
        object_accel_x: "float",
        obstacle_replaced: "bool",
        obstacle_angle: "float",
      }
    },
    "/mobileye/parsed_tx/obstacle_status": {
      table: "mobileye-parsed_tx-obstacle_status",
      columns: {
        num_obstacles: "int",
        timestamp: "int",
        application_version: "int",
        active_version_number_section: "int",
        left_close_range_cut_in: "bool",
        right_close_range_cut_in: "bool",
        stop_go: "int",
        protocol_version: "int",
        close_car: "bool",
        failsafe: "int",
      }
    },
    "/mobileye/parsed_tx/right_lka_lane": {
      table: "mobileye-parsed_tx-right_lka_lane",
      columns: {
        lane_type: "int",
        quality: "int",
        model_degree: "int",
        position_parameter_c0: "float",
        curvature_parameter_c2: "float",
        curvature_derivative_parameter_c3: "float",
        marking_width: "float",
        heading_angle_parameter_c1: "float",
        view_range: "float",
        view_range_availability: "bool",
      }
    },
    "/mobileye/parsed_tx/tsr": {
      table: "mobileye-parsed_tx-tsr",
      columns: {
        vision_only_sign_type: "int",
        vision_only_supplementary_sign_type: "int",
        sign_position_x: "float",
        sign_position_y: "float",
        sign_position_z: "float",
        filter_type: "int",
      }
    },
    "/mobileye/parsed_tx/tsr_vision_only": {
      table: "mobileye-parsed_tx-tsr_vision_only",
      columns: {
        vision_only_sign_type_display1: "int",
        vision_only_supplementary_sign_type_display1: "int",
        vision_only_sign_type_display2: "int",
        vision_only_supplementary_sign_type_display2: "int",
        vision_only_sign_type_display3: "int",
        vision_only_supplementary_sign_type_display3: "int",
        vision_only_sign_type_display4: "int",
        vision_only_supplementary_sign_type_display4: "int",
      }
    },
    "/usb_cam/front/image": {
      table: "usb_cam-front-image",
      columns: {
        header_stamp_datetime: "dttm",
        format: "string",
        // data: "data"
      }
    },
    "/usb_cam/rear/image": {
      table: "usb_cam-rear-image",
      columns: {
        header_stamp_datetime: "dttm",
        format: "string",
        // data: "data",
      }
    },
    // // Nothing interesting here until we include children
    // "v2v_prism": {
    //   table: "v2v_prism",
    //   columns: {

    //   }
    // },
    "/weather": {
      table: "weather",
      columns: {
        coord_lon: "float",
        coord_lat: "float",
        base: "string",
        main_temp: "float",
        main_feels_like: "float",
        main_temp_min: "float",
        main_temp_max: "float",
        main_pressure: "int",
        main_humidity: "int",
        visibility: "int",
        wind_speed: "int",
        wind_deg: "int",
        wind_gust: "int",
        clouds_all: "int",
        rain_1h: "int",
        rain_3h: "int",
        snow_1h: "int",
        snow_3h: "int",
        dt: "int",
        sys_Type: "int",
        sys_Id: "int",
        sys_country: "string",
        sys_sunrise: "int",
        sys_Sunset: "int",
        timezone: "int",
        id: "int",
        name: "string",
        cod: "int",
      }
    },
  }

export default TableDefinitions
