import { useEffect, useState, Fragment } from "react"
import {
  Form,
  Grid,
  Table,
  Message,
  Dimmer,
  Loader,
  List,
  Pagination
} from "semantic-ui-react"
import * as dayjs from 'dayjs'

import CustomForm from './PlaygroundComponents/CustomForm'
import CommonForm from "./PlaygroundComponents/CommonForm"
import axios from "axios"
import { BASE_URL } from "../settings"
import useWindowDimensions from "../utilities/useWindowDimensions"


const formatString = (value, type, digits=4) => {
  if (value === null || value === undefined) {
    return value
  } else if (type === "dttm") {
    return dayjs(value).format("YY-MM-DD HH:mm:ss.SSS")
  } else if (type === "float") {
    return value.toFixed(digits)
  } else if (type === "int") {
    return value.toString()
  } else if (type === "string") {
    return value
  }
}


const Playground = () => {
  const [ tableData, setTableData ] = useState({
    data: [],
    columns: [],
    data_types: []
  })

  const [ infoMessages, setInfoMessages ] = useState([])
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isCustomQuery, setIsCustomQuery ] = useState(false)
  const [ phaseDrives, setPhaseDrives ] = useState({})
  const [ drives, setDrives ] = useState([])
  const [ activePage, setActivePage ] = useState(1)
  const [ numPages, setNumPages ] = useState(1)

  const handleMessageDismiss = (_, data) => {
    let message_id = data["data-key"]
    setInfoMessages(infoMessages.filter(
      (_, idx) => parseInt(idx) !== parseInt(message_id)
    ))
  }

  const { height } = useWindowDimensions()

  useEffect(() => {
    getPhaseDrives()
  }, [])

  useEffect(() => {
    setTableData({data: [], columns: [], data_types: []})
  }, [isCustomQuery])

  useEffect(() => {
    setDrives(() => {
      let dri = []
      Object.keys(phaseDrives).forEach(phase => {
        dri = dri.concat(phaseDrives[phase])
      })
      return dri
    })
  }, [phaseDrives])


  const getPhaseDrives = () => {
    axios.get(BASE_URL + '/phase_drives')
    .then(res => {
      if ("messages" in res.data) {
        setInfoMessages(res.data.messages)
      }
      return res.data
    })
    .then(res => {
      if (res.data) {
        setPhaseDrives(res.data)
      }
    })
    .catch(err => {
      setIsLoading(false)
      setInfoMessages(err?.response?.data?.messages || [
        {
            "header": "Unspecified error",
            "message": 'Something went wrong, but was not specified.',
            "icon": "database",
            "level": "negative"
        }
    ])
    })
  }

  const handlePageChange = (_, pageInfo) => {
    setActivePage(pageInfo.activePage)
  }

  return (<>
    <Dimmer
      active={isLoading}
      style={{height: "100%", position: "fixed"}}
    >
      <Loader content={'Loading data'}/>
    </Dimmer>
    <Grid >
      <Grid.Column width={2} />
      <Grid.Column width={12} >
        <Form>
          <Form.Group>
            <Form.Radio
              label={"Common"}
              name="Common"
              checked={!isCustomQuery}
              onChange={() => setIsCustomQuery(x => !x)}
            />
            <Form.Radio
              label={"Custom"}
              name="Custom"
              checked={isCustomQuery}
              onChange={() => setIsCustomQuery(x => !x)}
            />
          </Form.Group>
        </Form>
        {
          isCustomQuery ? <CustomForm
            drives={drives}
            setTableData={setTableData}
            setIsLoading={setIsLoading}
            setInfoMessages={setInfoMessages}
            activePage={activePage}
            setNumPages={setNumPages}
          /> : <CommonForm
            phaseDrives={phaseDrives}
            setTableData={setTableData}
            setIsLoading={setIsLoading}
            setInfoMessages={setInfoMessages}
          />
        }
        <List>
          {
            Object.keys(tableData?.scalers || {}).map(key => {
              return <List.Item key={key} >
                <List.Content>
                  <List.Header content={key}/>
                  <List.Description content={tableData.scalers[key]} />
                </List.Content>
              </List.Item>
            })
          }
        </List>
        {isCustomQuery
        ? <div style={{overflow: "auto", paddingBottom: "70px"}}>
        <Table celled>
          <Table.Header>

            {  // If the first column is an object then assume it is a
               // multi-layer header
              (typeof((tableData?.columns || [])[0]) !== 'object') ? <Table.Row>
                {
                (tableData?.columns || []).map((col, i) =>{
                  return <Table.HeaderCell  key={i} content={col || ''}/>
                })
                }
              </Table.Row>
              : <>
                <Table.Row>{
                  tableData.columns.map((col, i) => {
                    return <Table.HeaderCell
                      colSpan={col[2].length}
                      key={`${i}${col[1]}`}
                      content={col[1] || ''}
                    />
                  })
                }</Table.Row>
                <Table.Row>{
                  tableData.columns.map((col, i) => {
                    return <Fragment key={i}>{
                      col[2].map((col2, j) => {
                        return <Table.HeaderCell
                        key={`${i}${j}${col2}`}
                        content={col2 || ''}
                      />
                      })
                    }</Fragment>

                  })
                }</Table.Row>
              </>
            }
          </Table.Header>
          <Table.Body>
            {
              (tableData?.data || []).map((row, i) => {
                return <Table.Row key={i}>
                  {
                    row.map((val, j) => {
                      return <Table.Cell content={
                        formatString(val, tableData.data_types[j])} key={j}
                      />
                    })
                  }
                </Table.Row>
              })
            }
          </Table.Body>
        </Table>
        {numPages > 1 ?
          <Pagination
            activePage={activePage}
            totalPages={numPages}
            onPageChange={handlePageChange}
          />
          : null
        }
        </div> :
        <div style={{overflow:"auto", maxHeight: `${height-563}px`}}>
        <Table celled>
          <Table.Header>

            {  // If the first column is an object then assume it is a
               // multi-layer header
              (typeof((tableData?.columns || [])[0]) !== 'object') ? <Table.Row>
                {
                (tableData?.columns || []).map((col, i) =>{
                  return <Table.HeaderCell  key={i} content={col || ''}/>
                })
                }
              </Table.Row>
              : <>
                <Table.Row>{
                  tableData.columns.map((col, i) => {
                    return <Table.HeaderCell
                      colSpan={col[2].length}
                      key={`${i}${col[1]}`}
                      content={col[1] || ''}
                    />
                  })
                }</Table.Row>
                <Table.Row>{
                  tableData.columns.map((col, i) => {
                    return <Fragment key={i}>{
                      col[2].map((col2, j) => {
                        return <Table.HeaderCell
                        key={`${i}${j}${col2}`}
                        content={col2 || ''}
                      />
                      })
                    }</Fragment>

                  })
                }</Table.Row>
              </>
            }
          </Table.Header>
          <Table.Body>
            {
              (tableData?.data || []).map((row, i) => {
                return <Table.Row key={i}>
                  {
                    row.map((val, j) => {
                      return <Table.Cell content={
                        formatString(val, tableData.data_types[j], 2)} key={j}
                      />
                    })
                  }
                </Table.Row>
              })
            }
          </Table.Body>
        </Table>
        </div>}

      </Grid.Column>

      {infoMessages.map((msg, index) =>
          <Message
            key={index}
            data-key={index}
            attached='bottom'
            icon={msg.icon}
            header={msg.header}
            content={msg.message}
            warning={!Boolean(msg.level) || msg.level === "warning"}
            negative={msg.level === "negative"}
            positive={msg.level === "positive"}
            onDismiss={handleMessageDismiss}
          />
        )
      }
      <Grid.Column width={2} />
    </Grid>
  </>)
}

export default Playground
