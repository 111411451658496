import { Card, GridColumn, Table } from "semantic-ui-react"


const PassengerInformationIndividual = ({ data }) => {
  return <GridColumn>
    <Card fluid>
      <Card.Content header='Passenger information' />
      <Card.Content>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell content='Sex' />
              <Table.HeaderCell content='Age range' />
              <Table.HeaderCell content='Impairments' />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              (data?.passengers || []).map((passenger, i) => <Table.Row key={i}>
                <Table.Cell content={passenger.sex || "unknown"} />
                <Table.Cell content={passenger.age || "unknown"} />
                <Table.Cell content={
                  (passenger.impairments || []).join(', ')
                  || ""} />
              </Table.Row>)
            }
          </Table.Body>
        </Table>
      </Card.Content>
    </Card>
  </GridColumn>
}

export default PassengerInformationIndividual
