import { Card, GridColumn } from "semantic-ui-react"

import formatString from './formatString'

const SafetyCritical = ({ data }) => {

  return <GridColumn>
    <Card fluid>
      <Card.Content header='Safety critical' />
      <Card.Content style={{fontSize: "1.5em"}}>
        {formatString(`**Events:** ${data.safetyCritical || 0}`)}
      </Card.Content>
    </Card>
  </GridColumn>
}

export default SafetyCritical
