import { Table, Image, Header } from "semantic-ui-react"

const yellowCell = {
  backgroundColor: 'rgb(255, 205, 0)',
  fontWeight: '1000',
  textAlign: 'center'
}

const grayCell = {
  backgroundColor: 'rgb(99,102,106)',
  fontWeight: '800',
  color: 'white',
  textDecoration: 'underline'
}

const PhaseTable = ({push, data}) => {
  return <Table celled style={{cursor: 'pointer'}}>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell colSpan={3} >
          <Header as='h2' content='Browse by phase:' />
        </Table.HeaderCell>
        <Table.HeaderCell content='Drives' />
      </Table.Row>
    </Table.Header>
    <Table.Body>
      <Table.Row onClick={() => push("/dashboard/1")}>
        <Table.Cell width={2} style={yellowCell} content={<Image src='phase_1.png' size='mini'/>}/>
        <Table.Cell width={2} style={yellowCell} content='1'/>
        <Table.Cell width={12} style={grayCell} content={'Interstate (Divided Highway)'} />
        <Table.Cell width={2} style={yellowCell} content={(data?.totalDrives || {})[1] || 0} />
      </Table.Row>
      <Table.Row onClick={() => push("/dashboard/2")}>
        <Table.Cell style={yellowCell} content={<Image src='phase_2.png' size='mini'/>}/>
        <Table.Cell style={yellowCell} content='2'/>
        <Table.Cell style={grayCell} content='Two Lane Undivided Highway / On and Off Ramps' />
        <Table.Cell width={2} style={yellowCell} content={(data?.totalDrives || {})[2] || 0} />
      </Table.Row>
      <Table.Row onClick={() => push("/dashboard/3")}>
        <Table.Cell style={yellowCell} content={<Image src='phase_3.png' size='mini'/>}/>
        <Table.Cell style={yellowCell} content='3'/>
        <Table.Cell style={grayCell} content='Urban Driving' />
        <Table.Cell width={2} style={yellowCell} content={(data?.totalDrives || {})[3] || 0} />
      </Table.Row>
      <Table.Row onClick={() => push("/dashboard/4")}>
        <Table.Cell style={yellowCell} content={<Image src='phase_4.png' size='mini'/>}/>
        <Table.Cell style={yellowCell} content='4'/>
        <Table.Cell style={grayCell} content='Unmarked Roads' />
        <Table.Cell width={2} style={yellowCell} content={(data?.totalDrives || {})[4] || 0} />
      </Table.Row>
      <Table.Row onClick={() => push("/dashboard/5")}>
        <Table.Cell style={yellowCell} content={<Image src='phase_5.png' size='mini'/>}/>
        <Table.Cell style={yellowCell} content='5'/>
        <Table.Cell style={grayCell} content='V2X' />
        <Table.Cell width={2} style={yellowCell} content={(data?.totalDrives || {})[5] || 0} />
      </Table.Row>
      <Table.Row onClick={() => push("/dashboard/6")}>
        <Table.Cell style={yellowCell} content={<Image src='phase_6.png' size='mini'/>}/>
        <Table.Cell style={yellowCell} content='6'/>
        <Table.Cell style={grayCell} content='Parking Areas / Entire Route' />
        <Table.Cell width={2} style={yellowCell} content={(data?.totalDrives || {})[6] || 0} />
      </Table.Row>
    </Table.Body>
  </Table>
}

export default PhaseTable
