import axios from 'axios'
import { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Header, Container, Message } from 'semantic-ui-react'
import { BASE_URL } from '../settings'

const Confirm = () => {
  const { token } = useParams()
  const { push } = useHistory()
  const [ email, setEmail ] = useState(null)
  const [ role, setRole ] = useState(null)
  const [ infoMessages, setInfoMessages ] = useState([])


  console.log("Confirm called:", token)
  

  useEffect(() => {
    if (token) {
      axios.get(BASE_URL + `/auth/confirm/${token}`)
      .then(res => {
        setEmail(res?.data?.data?.email)
        setRole(res?.data?.data?.role)
        setInfoMessages(res?.data?.messages || [])
        setTimeout(() => push('/'), 2000)
      })
      .catch(err => {
        console.log("GET confirm error: ", err, "response: ", err.response)
        if (err.response?.data?.messages !== undefined) {
          setInfoMessages(err.response.data.messages)
        }
      })
    }
  }, [token, push])

  const handleMessageDismiss = (_, data) => {
    let message_id = data["data-key"]
    setInfoMessages(infoMessages.filter(
      (_, idx) => parseInt(idx) !== parseInt(message_id)
    ))
  }

  return (
    <>
      <Container>
        <Header
            as='h1'
            style={{
            fontSize: '3em',
            fontWeight: 'normal',
            marginBottom: 0,
            marginTop: '1.5em',
            }}
        >
            {
              email ? 
              `Email ${email} confirmed. User's status is ${role}.` 
              : "Waiting for confirmation"
            }
        </Header>
      </Container>
      {infoMessages.map((msg, index) =>
          <Message
            key={index}
            data-key={index}
            attached='bottom'
            icon={msg.icon}
            header={msg.header}
            content={msg.message}
            warning={!Boolean(msg.level) || msg.level === "warning"}
            negative={msg.level === "negative"}
            positive={msg.level === "positive"}
            onDismiss={handleMessageDismiss}
          />
        )
      }
    </>
  )
}

export default Confirm