import { Card, Grid } from "semantic-ui-react"

const convertTemp = (temp, units) => {
  if (units === 'standard') {
    return (temp * 9.0 / 5.0 + 32.0).toFixed(0)
  }
  return temp.toFixed(0)
}

const AirTemperature = ({data, units}) => {
  // Set up some default values
  let temp
  if (data?.temperature_air === undefined) {
    temp = {average: 11.1, high: 11.1, low: 11.1}
  } else {
    temp = data.temperature_air
  }

  return <Grid.Column>
    <Card centered fluid>
      <Card.Content header={`Air temperature (${units === 'standard' ? "F" : "C"})`} />
      <Card.Content>
        <div style={{
          display:"flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}>
          <div style={{marginRight:"20px"}}>
            <div >Avg</div>
            <div style={{fontSize: "200%"}}> {convertTemp(temp.average, units)} </div>
          </div>
          <div >
            <div >
              <div style={{color: "red"}}>High</div>
              <div> {convertTemp(temp.high, units)} </div>
            </div>
            <div style={{
              width: "120%",
              height: "1px",
              background:"grey",
              margin:"8px 0px"
            }}></div>
            <div style={{
              display:"flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}>
              <div style={{color: "blue"}}> Low </div>
              <div> {convertTemp(temp.low, units)} </div>
            </div>
          </div>
        </div>
      </Card.Content>
    </Card>
  </Grid.Column>

}
export default AirTemperature
