import { useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import {
  Container,
  Header,
  Form,
  Message,
  Button,
  Grid
} from 'semantic-ui-react'

import {updateAccessToken} from '../utilities/jwt_utils'
import { BASE_URL } from '../settings'

const Login = () => {
  const [ infoMessages, setInfoMessages ] = useState([])
  const [ loginInfo, setLoginInfo ] = useState(
    {email: '', password: ''}
  )
  const [ isUnConfirmed, setIsUnconfirmed ] = useState(false)
  const [ isBadPassword, setIsBadPassword ] = useState(false)

  const {goBack} = useHistory()

  const resendEmailVerification = () => {
    return axios.post(BASE_URL + '/auth/resend', {email: loginInfo.email})
    .then(res => {
      setInfoMessages(res?.data?.messages || [])
    })
    .catch(err => {
      console.log("resendEmailVerification err: ", err?.response)
      setInfoMessages(err?.response?.data?.messages || [])
    })
  }

  const sendPasswordReset = () => {
    return axios.post(BASE_URL + '/auth/reset', {email: loginInfo.email})
    .then(res => {
      setInfoMessages(res?.data?.messages || [])
    })
    .catch(err => {
      console.log("sendPasswordReset err: ", err?.response)
      setInfoMessages(err?.response?.data?.messages || [])
    })
  }

  const handleLogin = () => {
    return axios.post(BASE_URL + '/auth/login', loginInfo)
    .then(res => {
      if ("messages" in res.data) {
        setInfoMessages(res.data.messages)
      }
      if ("access_token" in res.data && "refresh_token" in res.data) {
        updateAccessToken(res.data.access_token)
        localStorage.setItem("refresh_token", res.data.refresh_token)
        setTimeout(goBack, 2000)
      }
    })
    .catch(err => {
      let messages = err?.response?.data?.messages || []
      let canReset = Boolean(messages.find(x => x.message === "Email does not match password"))
      let canResend = Boolean(messages.find(x => x.message === "Email has not been confirmed"))
      setIsUnconfirmed(canResend)
      setIsBadPassword(canReset)
      console.log("handleLogin err: ", err.response, messages)
      setInfoMessages(messages)
    })
  }

  const handleMessageDismiss = (_, data) => {
    let message_id = data["data-key"]
    setInfoMessages(infoMessages.filter(
      (_, idx) => parseInt(idx) !== parseInt(message_id)
    ))
  }

  const handleLoginInfoChange = (_, data) => {
    const name = data.name
    const value = data.value
    setLoginInfo(x => ({ ...x, [name]: value }))
  }

  const handleFocus = e => e.target.select()

  return <>
    <Container>
      <Header
        as='h1'
        style={{
          fontSize: '3em',
          fontWeight: 'normal',
          marginBottom: 0,
          marginTop: '1.5em',
        }}
        content='Login'
      />
      <p>
        Log in using the email you registered with.
      </p>
      <p>
        University of Iowa users should use their HawkID
        password if you registered using a HawkID, or
        the password you used during registration.
      </p>
    </Container>
    <Container textAlign='left' style={{marginBottom: '1.5em'}}>
      <Grid>
        <Grid.Column width='9'>
        <Form error={Boolean(loginInfo.password && loginInfo.email)}>
          <Form.Input
            label='Email address'
            placeholder='email'
            name="email"
            value={loginInfo.email || ""}
            onChange={handleLoginInfoChange}
            autoComplete="off"
            onFocus={handleFocus}
            required
          />
          <Form.Input
            label='Password'
            type='password'
            name="password"
            value={loginInfo.password || ""}
            onChange={handleLoginInfoChange}
            autoComplete="off"
            onFocus={handleFocus}
            required
          />
          <Button
            content={'Submit'}
            disabled={!Boolean(loginInfo.password && loginInfo.email)}
            onClick={handleLogin}
          />
          {isUnConfirmed && <Button
            content={'Resend confirmation'}
            disabled={!Boolean(loginInfo.email)}
            onClick={resendEmailVerification}
          />}
          {isBadPassword && <Button
            content={'Reset password'}
            disabled={!Boolean(loginInfo.email)}
            onClick={sendPasswordReset}
          />}
        </Form>
        </Grid.Column>
      </Grid>
    </Container>
    {infoMessages.map((msg, index) =>
        <Message
          key={index}
          data-key={index}
          attached='bottom'
          icon={msg.icon}
          header={msg.header}
          content={msg.message}
          warning={!Boolean(msg.level) || msg.level === "warning"}
          negative={msg.level === "negative"}
          positive={msg.level === "positive"}
          onDismiss={handleMessageDismiss}
        />
      )
    }
  </>
}

export default Login
