import { Button, Card, GridColumn, Icon, List } from "semantic-ui-react"
import ReactMarkdown from "react-markdown"
import { useHistory } from "react-router-dom"
import { useEffect, useState } from "react"
import jwt_decode from 'jwt-decode'

import {nowTrunc} from '../../utilities/jwt_utils'

const formatString = x => {
  if (x.constructor !== String) {
    return x
  }
  // Replace <br> with the MD equivalent and trim lines
  x = x.split('<br>').map(x => x.trim()).join('  \n')
  return <ReactMarkdown children={x}/>
}

const Markers = ({data}) => {
  const { push } = useHistory()
  const [ isLoggedIn, setIsLoggedIn ] = useState(false)
  const [ userRole, setUserRole ] = useState("")

  useEffect(() => {
    let refreshToken = localStorage.getItem("refresh_token")
    let role = localStorage.getItem("user_role")
    if (refreshToken) {
      let decoded = jwt_decode(refreshToken, nowTrunc())
      if ("exp" in decoded && decoded.exp > nowTrunc()){
        setIsLoggedIn(true)
        setUserRole(role)
      } else if (isLoggedIn) {
        setIsLoggedIn(false)
        setUserRole("")
      }
    } else if (isLoggedIn) {
      setIsLoggedIn(false)
      setUserRole("")
    }
  }, [isLoggedIn])

  return <GridColumn>
    <Card fluid>
      <Card.Content >
        <Card.Header>
          {`Co-pilot markers (${data.markers || 0})`}
          { ['admin', 'superuser'].includes(userRole) ?

            <Button
              icon
              style={{float: 'right'}}
              onClick={() => push(`/markers/${data.driveName}`)}
            >
              <Icon name='edit' />
            </Button>
            : null
          }
        </Card.Header>

      </Card.Content>

      <Card.Content style={{
        maxHeight: '300px',
        overflowY: 'scroll',
        marginLeft: '0',
        marginRight: '0',
        padding: '0'
      }} >
        <List celled>
          {
            Object.keys(data?.markersFull || {}).map((key, i) => {
              let desc = data.markersFull[key]
              return <List.Item
                key={key}
                style={{
                  background: (i%2 === 0) ? "#EEEEEE" : "#FFFFFF",
                  ...((['admin', 'superuser'].includes(userRole)) ? {
                    cursor: 'pointer',
                    textDecoration: 'underline'
                  }: {})
                }}
                content={
                  formatString(`**${key}:** ${desc}`)
                }
                onClick={() => {
                  if (['admin', 'superuser'].includes(userRole)) {
                    push(`/markers/${data.driveName}/${key}`)
                  }
                }}
              />
            })
          }
        </List>

      </Card.Content>
    </Card>
  </GridColumn>
}

export default Markers
