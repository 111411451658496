import { useHistory } from "react-router-dom"
import { Bar } from "react-chartjs-2"
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Card, GridColumn } from "semantic-ui-react"

const miInKm = 0.621371

const RoadConditions = ({data, colors, units, phase_number}) => {
  const { push } = useHistory()
  const handleRoadConditionClick = rc => {
    let roadConditionIdx = rc.length ? rc[0].index : null
    if (roadConditionIdx !== null) {
      let roadCondition = Object.keys(data.roadConditions)[roadConditionIdx]
      push(
        `/drive_list/road_condition/${roadCondition}`
        + `${phase_number ? `/${phase_number}` : ''}`
      )
    }

  }
  return <GridColumn>
    <Card centered fluid>
      <Card.Content>
        <div>Distance by</div>
        <Card.Header content={`Road conditions (${units === 'standard' ? 'mi' : 'km'})`} />
      </Card.Content>
      <Card.Content style={{padding: "0"}} >
        <Bar
          data={{
            labels: Object.keys(data.roadConditions),
            datasets: [
              {
                type: 'bar',
                label: 'Manual',
                backgroundColor: colors.manualBackground,
                data: Object.keys(data.roadConditions).map(x =>
                  data.roadConditions[x].Manual * (units === 'standard' ? miInKm : 1)
                ),
                borderColor: colors.manualBorder,
                borderWidth: 2,
                datalabels: {
                  align: 'left',
                }
              },
              {
                type: 'bar',
                label: 'Automated',
                backgroundColor: colors.automatedBackground,
                borderColor: colors.automatedBorder,
                borderWidth: 2,
                data: Object.keys(data.roadConditions).map(x =>
                  data.roadConditions[x].Auto * (units === 'standard' ? miInKm : 1)
                ),
                datalabels: {
                  align: 'left',
                }
              },
            ],
          }}
          plugins={[ChartDataLabels]}
          height='300px'
          // onClick={(e) => console.log(e)}
          getElementAtEvent = {handleRoadConditionClick}
          options={{
            plugins: {
              datalabels: {
                formatter: x => `${(x || 0).toFixed(0) || ''}`,
                anchor: 'end',
                borderWidth: 1,
                borderRadius: 3,
                padding: 3,
                align: 'right',
                backgroundColor: 'white',
                borderColor: 'grey'
              }
            },
            scales: {
              x: {
                stacked: true,
              },
              y: {
                stacked: true
              }
            },
            maintainAspectRatio: false,
          }}
        />
      </Card.Content>
    </Card>
  </GridColumn>
}

export default RoadConditions
