import axios from "axios"
import { useState, useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import {
  Container,
  Header,
  Table
  } from "semantic-ui-react"
import { BASE_URL } from "../settings"

const MyTableCell = ({col, val, phase, push}) => {
  let cell = val
  let j = col
  if (j===0) {  // Drive
    return <Table.Cell
      key={j}
      content={cell}
      onClick={e => {
        e.stopPropagation()
        push(`/dashboard/${parseInt(phase)}/${parseInt(cell)}`)
      }}
      style={{
        textDecoration: 'underline',
        cursor: 'pointer'
      }}
    />
  } else if (j===1) {  // Phase
    return <Table.Cell
      key={j}
      content={cell}
      onClick={e => {
        e.stopPropagation()
        push(`/dashboard/${parseInt(cell)}`)
      }}
      style={{
        textDecoration: 'underline',
        cursor: 'pointer'
      }}
    />
  } else {
    return <Table.Cell key={j} content={cell} />
  }

}

const getDataWeather = ({setDriveData, phase, weather_label}) => {
  axios.get(
    BASE_URL + "/drive_weather",
    {params: {phase: phase, weather_label: weather_label}}
  )
  .then(res => {
    let data = res?.data?.data
    if (!data) {
      throw ReferenceError("Response did not have data")
    } else {
      return data
    }
  })
  .then(res => {
    let labels = []
    res.forEach(drive => {
      drive.weathers.forEach(column => {
        if (!labels.includes(column.weather_label)) {
          labels = labels.concat(column.weather_label)
        }
      })
    })
    labels.sort((a, b) => {
      if (a === weather_label) {
        return -1
      } else if (b === weather_label) {
        return 1
      } else {
        return 0
      }
    })
    let outData = res?.map(drive => {
      let dat = [drive.drive_name, drive.phase_number]
      labels.forEach(label => {
        let val = (
          drive.weathers.find(x => x.weather_label === label)?.ratio || 0.0
        )
        dat = dat.concat(
          parseFloat(val) !== 0.0
          ? `${(parseFloat(val) * 100).toFixed(0)}%`
          : "-"
        )
      })
      return dat
    })
    setDriveData({
      headers: ["Drive", "Phase", ...labels],
      data: outData,
      subColumns: []
    })
  })
  .catch(err => {
    console.log(
      "GET drive_statistics error: ", err, "response: ", err.response
    )
  })
}

const getDataRoadCondition = ({
  setDriveData, phase, road_condition_label
}) => {
  axios.get(
    BASE_URL + "/drive_road_condition",
    {params: {phase: phase, road_condition_label: road_condition_label}}
  )
  .then(res => {
    let data = res?.data?.data
    if (!data) {
      throw ReferenceError("Response did not have data")
    } else {
      return data
    }
  })
  .then(res => {
    let labels = []
    res.forEach(drive => {
      drive.road_conditions.forEach(column => {
        if (!labels.includes(column.road_condition_label)) {
          labels = labels.concat(column.road_condition_label)
        }
      })
    })
    labels.sort((a, b) => {
      console.log(a, b)
      if (a === road_condition_label) {
        return -1
      } else if (b === road_condition_label) {
        return 1
      } else {
        return 0
      }
    })
    let outData = res?.map(drive => {
      let dat = [drive.drive_name, drive.phase_number]
      labels.forEach(label => {
        let valA = (
          drive.road_conditions.find(x => x.road_condition_label === label)?.auto_km || 0.0
        )
        let valM = (
          drive.road_conditions.find(x => x.road_condition_label === label)?.manual_km || 0.0
        )
        let outVal
        valA = parseFloat(valA)
        valM = parseFloat(valM)
        valA = valA !== 0.0 ? `Auto: ${valA.toFixed(1)}km`: null
        valM = valM !== 0.0 ? `Man: ${valM.toFixed(1)}km`: null
        if (valA && valM) {
          outVal = `${valA}, ${valM}`
        } else if (valA) {
          outVal = valA
        } else if (valM) {
          outVal = valM
        } else {
          outVal = '-'
        }
        dat = dat.concat(outVal)
      })
      return dat
    })
    setDriveData({
      headers: ["Drive", "Phase", ...labels],
      data: outData,
      subColumns: []
    })
  })
  .catch(err => {
    console.log(
      "GET drive_statistics error: ", err, "response: ", err.response
    )
  })
}

const DriveList = () => {
  const {phase, filterType, filterValue} = useParams()
  const {push} = useHistory()
  const [driveData, setDriveData] = useState({})
  const [sortBy, setSortBy] = useState({column: '', direction: 'ascending'})

  useEffect(() => {
    if (filterType === 'weather') {
      getDataWeather({
        setDriveData: setDriveData,
        phase: phase,
        weather_label: filterValue
      })
    } else if (filterType === 'road_condition') {
      getDataRoadCondition({
        setDriveData: setDriveData,
        phase: phase,
        road_condition_label: filterValue
      })
    }
  }, [phase, filterType, filterValue])

  useEffect(() => {
    setDriveData(curData => {
      let index = (curData.headers || []).findIndex(x => x === sortBy.column)
      if (index === -1 || !Boolean(sortBy.direction)) {return curData}
      let data = curData.data
      data.sort((a, b) => {
        if (sortBy.direction === "ascending") {
          if (!parseInt(b[index])) {
            return 1
          } else if (!parseInt(a[index])) {
            return -1
          } else {
            return parseInt(a[index]) - parseInt(b[index])
          }
        } else {
          if (!parseInt(b[index])) {
            return -1
          } else if (!parseInt(a[index])) {
            return 1
          } else {
            return parseInt(b[index]) - parseInt(a[index])
          }
        }
      })
      return {...curData, data: data}
    })
  }, [sortBy])

  const handleSort = columnName => {
    setSortBy(curSort => {
      let direction
      if (columnName === curSort.column) {
        direction = (
          curSort.direction === "descending" ? "ascending" : "descending"
        )
      } else {
        direction = "descending"
      }
      return {column: columnName, direction: direction}
    })
  }

  return <Container>
    <Header as={'h1'} content={
      `Search for drives ${phase ? `in phase ${phase} ` : ''}by ${
        filterType === "road_condition"? "road condition": "weather"
      } including: ${filterValue}`
    } />
    <Header as={'h4'}
      content={`${driveData?.data?.length} results found. `}
      style={{marginTop: "-8px"}}
    />

    <Table sortable>
      <Table.Header>
        <Table.Row>
          {
            (driveData?.headers || []).map(headerName => <Table.HeaderCell
              key={headerName}
              content={headerName}
              style={{position: "sticky", top: "118px"}}
              onClick={() => handleSort(headerName)}
              sorted={sortBy.column === headerName ? sortBy.direction : null}
            />)
          }
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {
          driveData?.data?.map((row, i) => <Table.Row
            key={i}
            data-key={`${parseInt(row[1])}/${parseInt(row[0])}`}
          >
            {
              row?.map((cell, j) => <MyTableCell
                col={j}
                phase={parseInt(row[1])}
                val={cell}
                key={j}
                push={push}
              />)
            }
          </Table.Row>
          )
        }
      </Table.Body>
    </Table>
    {
      (driveData?.data || []).length === 0
      ? <Header as={'h2'} content={"No drives found with these criteria"} />
      : null
    }
  </Container>
}

export default DriveList
