import axios from 'axios'
import jwt_decode from 'jwt-decode'

import { BASE_URL } from '../settings'


const nowTrunc = () => Math.trunc(Date.now()/1000)

const updateAccessToken = async newToken => {
  localStorage.setItem("access_token", newToken)
  let decoded = jwt_decode(newToken)
  localStorage.setItem("identity", decoded.sub)
  localStorage.setItem("user_role", decoded.user_role)
}

const refreshToken = async refresh_token => {
  return (axios.post(BASE_URL + '/auth/refresh', {}, {
    headers: {'Authorization': "Bearer ".concat(refresh_token)}
  }))
  .then(res => {
    updateAccessToken(res.data.access_token)
    return res.data.access_token
  })
}

const getJwt = async () => {
  /*
    false if needs new login
    token if everything went fine
    Error(message) if there was an error
  */
  let access_token = localStorage.getItem("access_token")
  let refresh_token = localStorage.getItem("refresh_token")
  let decoded
  let decodedRefresh
  try {
    decoded = jwt_decode(access_token)
  } catch {
    decoded = null
  }
  try {
    decodedRefresh = jwt_decode(refresh_token)
  } catch {
    decodedRefresh = null
  }
  // If we are missing a token or both are expired
  if (
    !decodedRefresh ||
    decodedRefresh.exp < nowTrunc()
  ) {
    return false
  }
  // If refresh needed and refresh token is valid
  else if (
    (!decoded || decoded.exp < nowTrunc())
    && decodedRefresh.exp > nowTrunc()
  ) {
    access_token = await refreshToken(refresh_token)
    if (access_token) {
      return access_token
    } else {
      throw new Error("Token failed to refresh")
    }
  }
  // Everything is fine
  else {
    return access_token
  }
}

const revokeAccessToken = async () => {
  let token = localStorage.getItem("access_token")
  let decoded
  try {
    decoded = jwt_decode(token)
  } catch {
    decoded = {}
  }
  // Token is still valid, revoke it
  if ((decoded.exp || 0) > nowTrunc()) {
    return (
      axios.delete(BASE_URL + '/auth/logout', {
        credentials: 'same-origin',
        headers: {
          'Authorization': "Bearer ".concat(token)
        }
      })
      .then(res => {
        if ("messages" in res.data) {
          return res.data.messages
        }
      })
      .catch(res => {
        console.log(res)
        // if ("messages" in res.response.data) {
        //   return res.response.data.messages
        // }
      })
    )
  } else {
    // The token is already expired
    return "Access token expired"
  }
}

const revokeRefreshToken = async () => {
  let token = localStorage.getItem("refresh_token")
  let decoded
  try {
    decoded = jwt_decode(token)
  } catch {
    decoded = {}
  }
  // Token is still valid, revoke it
  if ((decoded.exp || 0) > nowTrunc()) {
    return (
      axios.delete(BASE_URL + '/auth/logout2', {
        headers: {
          'Authorization': "Bearer ".concat(token)
        }
      })
      .then(() => {
        let message = "Refresh token revoked"
        console.log(message)
        if ("refresh_token" in localStorage) {
          localStorage.removeItem("refresh_token")
        }
      })
    )
  } else {
    // The token is already expired
    let message = "Refresh token expired"
    console.log(message)
    localStorage.removeItem("refresh_token")
  }
}

const logOut = () => {
  let accessRemovePromise = revokeAccessToken()
  let refreshRemovePromise = revokeRefreshToken()
  Promise.all([accessRemovePromise, refreshRemovePromise])
  .then(() => {
    const toRemove = [
      "identity", "refresh_token", "access_token", "user_role"
    ]
    toRemove.forEach(item => {
      if (item in localStorage) {
        localStorage.removeItem(item)
      }
    })
  })
  .then(() => {
    window.location.reload()
  })
}

export {
  updateAccessToken,
  revokeAccessToken,
  getJwt,
  nowTrunc,
  logOut
}
