import { Button, Container, Dimmer, Form, Icon, Loader, Modal, Table } from "semantic-ui-react"
import { useEffect, useState } from 'react'
import axios from "axios"
import { BASE_URL } from "../settings"
import { getJwt } from "../utilities/jwt_utils"
import { useHistory, useLocation } from "react-router-dom"
import getDrives from "./api/getDrives"
import getMarkerDisengagementTypes from "./api/getMarkerDisengagementTypes"


const boolToSymbol = boolIn => {
  if (boolIn === true) {
    return <Icon name='check' />
  } else {
    return <Icon name='cancel' />
  }
}

const boolToText = boolIn => {
  if (boolIn === true) {
    return "True"
  } else {
    return "False"
  }
}
const deleteMarker = ({
  setIsLoading, marker_id, setInfoMessages, setMarkers
}) => {
  setIsLoading(x => x + 1)

  getJwt()
  .then(jwt => axios.delete(BASE_URL + `/marker/${marker_id}`,
  {
    headers: {
      'Authorization': "Bearer ".concat(jwt)
    }
  }))
  .then(res => res.data)
  .then(res => {
    setInfoMessages(res?.messages || [])
    return res.data
  })
  .then(res => setMarkers(res?.map(x => ({...x})) || []))
  .then(() => setIsLoading(x => x - 1))
  .catch(err => {
    setIsLoading(x => x - 1)
    setInfoMessages(err?.response?.data?.messages || [
      {
          "header": "Unspecified error",
          "message": 'Something went wrong, but was not specified.',
          "icon": "database",
          "level": "negative"
      }
  ])
  })
}

const EditModal = ({
  editOpen, setEditOpen, setMarkers, setIsLoading, setInfoMessages,
  curDrive, markerDisengagementTypes, markerVruTypes, formData, setFormData
}) => {

  const handleFormSubmit = () => {
    submitMarker({
      formData: formData,
      setFormData: setFormData,
      setMarkers: setMarkers,
      setIsLoading: setIsLoading,
      setInfoMessages: setInfoMessages
    })
    setEditOpen(false)
  }

  const handleToggleBool = e => {
    let name = e.target.name
    setFormData(cur => {
      let value = cur[name]
      if (value === undefined || value === null || value === false) {
        value = true
      } else {
        value = false
      }
      return {...cur, [name]: value, drive_name: curDrive}
    })
  }

  const handleToggleVoluntary = () => {
    setFormData(cur => {
      let is_voluntary = cur.is_voluntary
      let is_forced
      if (is_voluntary === undefined || is_voluntary === null) {
        is_voluntary = true
        is_forced = false
      } else if (is_voluntary === true) {
        is_voluntary = false
        is_forced = true
      } else {
        is_voluntary = null
        is_forced = null
      }
      return {
        ...cur,
        is_voluntary: is_voluntary,
        is_forced: is_forced,
        drive_name: curDrive
      }
    })
  }

  const handleToggleAutomated = () => {
    setFormData(cur => {
      let is_auto = cur.is_auto
      let is_manual
      if (is_auto === undefined || is_auto === null) {
        is_auto = true
        is_manual = false
      } else if (is_auto === true) {
        is_auto = false
        is_manual = true
      } else {
        is_auto = null
        is_manual = null
      }
      return {
        ...cur,
        is_auto: is_auto,
        is_manual: is_manual,
        drive_name: curDrive
      }
    })
  }

  const handleFormChange = (_, {name, value}) => {
    setFormData(cur => ({...cur, [name]: value, drive_name: curDrive}))
  }


  const handleDisengagementChange = (_, {value}) => {
    setFormData(cur => ({
      ...cur, marker_disengagement_type_id: (parseInt(value) || null)
    }))
  }

  const handleVruChange = (_, {value}) => {
    setFormData(cur => ({
      ...cur, marker_vru_type_id: (parseInt(value) || null)
    }))
  }

  return <Modal
    onClose={() => {
      setFormData({})
      setEditOpen(false)
    }}
    onOpen={() => {
      setEditOpen(true)
      setFormData({})
    }}
    open={editOpen}
  >
    <Modal.Header>Edit a marker</Modal.Header>
    <Modal.Content>

    <Form>
        <Form.Group>
          <Form.Input
            label='Number'
            name='marker_number'
            value={formData?.marker_number || ""}
            width='2'
            onChange={handleFormChange}
          />
          <Form.Input
            label='Description'
            name='marker_description'
            value={formData?.marker_description || ''}
            width='14'
            onChange={handleFormChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Dropdown
            label="Disengagement"
            selection
            clearable
            placeholder="Select disengagement"
            onChange={handleDisengagementChange}
            options={markerDisengagementTypes.map(i => ({
              key: i.marker_disengagement_type_id,
              text: i.marker_disengagement_type_description,
              value: i.marker_disengagement_type_id
            }))}
            width='8'
            value={formData.marker_disengagement_type_id || ''}
          />
          <Form.Dropdown
            label="VRU"
            selection
            clearable
            placeholder="Select VRU"
            onChange={handleVruChange}
            options={markerVruTypes.map(i => ({
              key: i.marker_vru_type_id,
              text: i.marker_vru_type_description,
              value: i.marker_vru_type_id
            }))}
            width='8'
            value={formData.marker_vru_type_id || ''}
          />
        </Form.Group>
        <Form.Group>
          <ToggleableVoluntary
            value={formData?.is_voluntary}
            handleToggleVoluntary={handleToggleVoluntary}
          />
          <ToggleableAutomated
            value={formData?.is_auto}
            handleToggleAutomated={handleToggleAutomated}
          />
          <BoolInput
            niceName={'SCE'}
            dbName={'is_sce'}
            value={formData?.is_sce}
            onClick={handleToggleBool}
          />
        </Form.Group>
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button
        type="submit"
        content="Submit"
        width='1'
        onClick={handleFormSubmit}
        positive
      />
      <Button
        type="button"
        content="Clear"
        width='1'
        onClick={() => setFormData({})}
      />
      <Button
        type="button"
        content="Cancel"
        width='1'
        onClick={() => {
          setFormData({})
          setEditOpen(false)
        }}
        negative
      />
    </Modal.Actions>
  </Modal>
}


const getMarkers = ({curDrive, setIsLoading, setInfoMessages, setMarkers}) => {
  setIsLoading(x => x + 1)

  getJwt()
  .then(jwt => axios.get(BASE_URL + `/markers/${curDrive}`,
  {
    headers: {
      'Authorization': "Bearer ".concat(jwt)
    }
  }))
  .then(res => {
    setInfoMessages(res?.data?.messages || [])
    return res.data
  })
  .then(res => res.data)
  .then(res => res?.map(x => ({...x})) || [])
  .then(res => setMarkers(res))
  .then(() => setIsLoading(x => x - 1))
  .catch(err => {
    setIsLoading(x => x - 1)
    setInfoMessages(err?.response?.data?.messages || [
      {
          "header": "Unspecified error",
          "message": 'Something went wrong, but was not specified.',
          "icon": "database",
          "level": "negative"
      }
  ])
  })
}

const getMarkerVruTypes = ({
  setIsLoading,
  setInfoMessages,
  setMarkerVruTypes
}) => {
  setIsLoading(x => x + 1)
  axios.get(BASE_URL + `/marker_vru_types`)
  .then(res => res?.data)
  .then(res => {
    setInfoMessages(res?.messages || [])
    setMarkerVruTypes(res.data)
  })
  .then(() => setIsLoading(x => x - 1))
  .catch(err => {
    setIsLoading(x => x - 1)
    setInfoMessages(err?.response?.data?.messages || [
      {
          "header": "Unspecified error",
          "message": 'Something went wrong, but was not specified.',
          "icon": "database",
          "level": "negative"
      }
    ])
  })
}


const submitMarker = ({
  formData, setFormData, setMarkers, setIsLoading, setInfoMessages
}) => {
  const request = async (formData) => {
    if (!formData.marker_id) {  // POST
      return (
        getJwt()
        .then(jwt => {
          setIsLoading(x => x + 1)
          return axios.post(
            BASE_URL + `/markers/${formData.drive_name}`,
            formData,
            {
              headers: {
                'Authorization': "Bearer ".concat(jwt),
                'Content-type': 'application/json'
              }
            }
          )
          })
      )
    } else {  // PUT
      return (
        getJwt()
        .then(jwt => {
          setIsLoading(x => x + 1)
          return axios.put(
          BASE_URL + `/marker/${formData.marker_id}`,
          formData,
          {
            headers: {
              'Authorization': "Bearer ".concat(jwt),
              'Content-type': 'application/json'
            }
          }
        )})
      )
    }
  }
  request(formData)
  .then(res => res.data)
  .then(res => {
    setInfoMessages(res.messages || [])
    return res
  })
  .then(res => {
    setMarkers([...res.data])
  })
  .then(() => {
    setFormData({})
    setIsLoading(x => x - 1)
  })
  .catch(err => {
    setIsLoading(x => x - 1)
    setInfoMessages(err?.response?.data?.messages || [
      {
          "header": "Unspecified error",
          "message": 'Something went wrong, but was not specified.',
          "icon": "database",
          "level": "negative"
      }
    ])
  })
}


const BoolInput = ({niceName, dbName, value, onClick}) => {
  return <Form.Input
    label={niceName}
    icon={boolToSymbol(value)}
    iconPosition={'left'}
    value={boolToText(value)}
    name={dbName}
    width='3'
    fluid
    onClick={onClick}
  />
}

const ToggleableVoluntary = ({value, handleToggleVoluntary}) => {
  return <Form.Button
    label={"Voluntary/Forced"}
    onClick={handleToggleVoluntary}
    width={'4'}
    fluid
    value={value}
    content={
      value===true
      ? "Voluntary"
      : (value===false ? "Forced" : "Neither")
    }
  />
}

const ToggleableAutomated = ({value, handleToggleAutomated}) => {
  return <Form.Button
    label={"Automated/Manual"}
    onClick={handleToggleAutomated}
    width={'4'}
    fluid
    value={value}
    content={
      value===true
      ? "Automated"
      : (value===false ? "Manual" : "Neither")
    }
  />
}


const Markers = () => {
  const { push } = useHistory()

  const [driveNumbers, setDriveNumbers] = useState([])
  const [markerDisengagementTypes, setMarkerDisengagementTypes] = useState([])
  const [markerVruTypes, setMarkerVruTypes] = useState([])
  const [curDrive, setCurDrive] = useState()
  const [isLoading, setIsLoading] = useState(0)
  const [, setInfoMessages] = useState([])
  const [formData, setFormData] = useState({})
  const [markers, setMarkers] = useState([])
  const [editOpen, setEditOpen] = useState(false)

  const { pathname } = useLocation()

  useEffect(()=> {
    getDrives({
      setIsLoading: setIsLoading,
      setInfoMessages: setInfoMessages
    })
    .then(res => {
      let drives = []
      Object.keys(res).forEach(key => {
        drives = drives.concat(res[key])
      })
      drives.sort((a, b) => parseInt(a) - parseInt(b))
      setDriveNumbers(drives)
    })
    getMarkerDisengagementTypes({
      setIsLoading: setIsLoading,
      setInfoMessages: setInfoMessages,
      setMarkerDisengagementTypes: setMarkerDisengagementTypes
    })
    getMarkerVruTypes({
      setIsLoading: setIsLoading,
      setInfoMessages: setInfoMessages,
      setMarkerVruTypes: setMarkerVruTypes
    })
  }, [])

  useEffect(() => {
    if (driveNumbers.includes(curDrive)) {
      getMarkers({
        curDrive: curDrive,
        setIsLoading: setIsLoading,
        setInfoMessages: setInfoMessages,
        setMarkers: setMarkers
      })
    }
  }, [curDrive, driveNumbers])

  // When path changes update section and topic
  useEffect(() => {
    let [ , , driveName, markerNumber] = pathname.split('/')
    if (driveNumbers.includes(driveName)) {
      setCurDrive(driveName)
      let selectedMarker = markers.find(
        x => x.marker_number === parseInt(markerNumber)
      )
      if (selectedMarker) {
        setFormData(selectedMarker)
        setEditOpen(true)
      }
    }
  }, [pathname, driveNumbers, markers])



  const handleDriveChange = (_, {value}) => {
    push(`/markers/${value}`)
  }

  const handleMarkerClick = (e) => {
    let marker_id = e.currentTarget.getAttribute("data-key")
    if (!marker_id) {
      console.log("No id found for this row")
    } else {
      let row = markers.find(x => parseInt(x.marker_id) === parseInt(marker_id))
      if (!row) {
        console.loq(`No row found for id: ${marker_id}`)
      } else {
        setFormData({...row, drive_name: curDrive})
        setEditOpen(true)
      }
    }
  }

  const handleMarkerDelete = (e, d) => {
    e.stopPropagation()
    let dataKey = parseInt(
      e.currentTarget.parentElement.getAttribute(
        "data-key"
      )
    )
    deleteMarker({
      setIsLoading: setIsLoading,
      marker_id: dataKey,
      setInfoMessages: setInfoMessages,
      setMarkers: setMarkers
    })
  }

  const resolveVoluntary = (is_voluntary, is_forced) => {
    if (is_voluntary === true && is_forced === false) {
      return "Voluntary"
    } else if (is_voluntary === false && is_forced === true) {
      return "Forced"
    } else if (
      (is_voluntary === null || is_voluntary === undefined)
      && (is_forced === null || is_forced === undefined)
    ) {
      return "Neither"
    } else {
      return "Bad data"
    }
  }

  const resolveAutomated = (is_auto, is_manual) => {
    if (is_auto === true && is_manual === false) {
      return "Automated"
    } else if (is_auto === false && is_manual === true) {
      return "Manual"
    } else if (
      (is_auto === null || is_auto === undefined)
      && (is_manual === null || is_manual === undefined)
    ) {
      return "Neither"
    } else {
      return "Bad data"
    }
  }

  return <>
    <Dimmer
      active={Boolean(isLoading !== 0)}
      style={{height: "100%", position: "fixed"}}
    >
      <Loader content={'Loading data'}/>
    </Dimmer>
    <Container>
      <Form>
        <Form.Group>
          <Form.Dropdown
            selection
            placeholder="Select drive"
            onChange={handleDriveChange}
            options={driveNumbers.map(i => ({key: i, text: i, value: i}))}
            width='3'
            value={curDrive}
          />
          <Form.Button content={'New'} onClick={() => setEditOpen(true)}/>
        </Form.Group>
      </Form>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              width={1}
              content={'Number'}
              style={{position: "sticky", top: "118px"}}
            />
            <Table.HeaderCell
              width={4}
              content={'Description'}
              style={{position: "sticky", top: "118px"}}
            />
            <Table.HeaderCell
              width={3}
              content={'VRU'}
              style={{position: "sticky", top: "118px"}}
            />
            <Table.HeaderCell
              width={3}
              content={'Disengagement'}
              style={{position: "sticky", top: "118px"}}
            />
            <Table.HeaderCell
              width={2}
              content={'Voluntary/Forced'}
              style={{position: "sticky", top: "118px"}}
            />
            <Table.HeaderCell
              width={2}
              content={'Auto/Manual'}
              style={{position: "sticky", top: "118px"}}
            />
            <Table.HeaderCell
              width={1}
              content={'SCE'}
              style={{position: "sticky", top: "118px"}}
            />
            <Table.HeaderCell
              width={1}
              content={'Delete'}
              style={{position: "sticky", top: "118px"}}
            />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            (markers || []).map(row => <Table.Row
              key={row.marker_id}
              data-key={row.marker_id}
              onClick={handleMarkerClick}
            >
              <Table.Cell content={row.marker_number} />
              <Table.Cell content={row.marker_description} />
              <Table.Cell
                content={markerVruTypes.find(
                  x => x.marker_vru_type_id === row.marker_vru_type_id
                )?.marker_vru_type_description || ''}
              />
              <Table.Cell
                content={markerDisengagementTypes.find(
                  x => (
                    x.marker_disengagement_type_id
                    === row.marker_disengagement_type_id
                  )
                )?.marker_disengagement_type_description || ''}
              />
              <Table.Cell
                content={resolveVoluntary(row.is_voluntary, row.is_forced)}
              />
              <Table.Cell
                content={resolveAutomated(row.is_auto, row.is_manual)}
              />
              <Table.Cell content={boolToSymbol(row.is_sce)} />
              <Table.Cell
                content={<Icon name="delete" color="red"/>}
                onClick={handleMarkerDelete}
              />
            </Table.Row>)
          }
        </Table.Body>
      </Table>
    </Container>
    <EditModal
      editOpen={editOpen}
      setEditOpen={setEditOpen}
      setMarkers={setMarkers}
      setIsLoading={setIsLoading}
      setInfoMessages={setInfoMessages}
      curDrive={curDrive}
      markerDisengagementTypes={markerDisengagementTypes}
      markerVruTypes={markerVruTypes}
      formData={formData}
      setFormData={setFormData}
    />
  </>
}

export default Markers
