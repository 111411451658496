import { useEffect, useState } from "react"
import { Form } from "semantic-ui-react"

import DriveSelector from "./DriveSelector"


const RatingsOfAnxiety = ({queryData, handleChange, phaseDrives}) => {
  const [currentSelection, setCurrentSelection] = useState(
    "ratings_in_trip"
  )

  useEffect(() => {
    if (queryData?.child_query_name !== currentSelection) {
      handleChange(
        null, {"name": "child_query_name", "value": currentSelection}
      )
    }

  }, [currentSelection, handleChange, queryData?.child_query_name])


  return (
    <Form>
      <Form.Group>
        <Form.Dropdown
          label="Select a common query"
          value={currentSelection}
          name={'child_query_name'}
          onChange={(e, {value}) => setCurrentSelection(value)}
          options={[
            {
              key: "ratings_in_trip",
              text: "Average Anxiety Rating by Time in Trip",
              value: "ratings_in_trip"
            }, {
              key: "ratings_in_trip_mode",
              text: "Average Anxiety Rating by Time in Trip and Driving Mode",
              value: "ratings_in_trip_mode"
            }, {
              key: "ratings_in_trip_location",
              text: "Average Anxiety Rating by Time in Trip and Location",
              value: "ratings_in_trip_location"
            }, {
              key: "ratings_in_trip_env",
              text: "Average Anxiety Rating by Time in Trip and Environmental Condition",
              value: "ratings_in_trip_env"
            }, {
              key: "ratings_in_mode",
              text: "Average Anxiety Rating by Driving Mode",
              value: "ratings_in_mode"
            }, {
              key: "ratings_in_mode_location",
              text: "Average Anxiety Rating by Driving Mode and Location",
              value: "ratings_in_mode_location"
            }, {
              key: "ratings_in_mode_env",
              text: "Average Anxiety Rating by Driving Mode and Environmental Condition",
              value: "ratings_in_mode_env"
            }, {
              key: "ratings_in_location",
              text: "Average Anxiety Rating by Location",
              value: "ratings_in_location"
            }, {
              key: "ratings_in_location_env",
              text: "Average Anxiety Rating by Location and Environmental Condition",
              value: "ratings_in_location_env"
            }, {
              key: "ratings_in_env",
              text: "Average Anxiety Rating by Environmental Condition",
              value: "ratings_in_env"
            },
          ]}
        />
      </Form.Group>
      <DriveSelector
        handleChange={handleChange}
        phaseDrives={phaseDrives}
        queryData={queryData}
        hideDrives={false}
      />
    </Form>
  )
}

export default RatingsOfAnxiety
