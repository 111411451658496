const criteria = [
  {
    name: 'Time from start',
    niceName: 'time_from_start',
    parameters: [
      "Start time (seconds)",
      "Duration (seconds)"
    ],
    niceParameters: [
      "start_time",
      "duration"
    ]
  }
]

export default criteria
