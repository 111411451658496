import { useEffect, useState } from "react"
import { Button, Divider, Form, Grid, Popup, Table } from "semantic-ui-react"
import TableDefinitions from "../PlaygroundAssets/TableDefinitions"
import customFormSubmitRequest from "./CustomFormFunctions/customFormSubmitRequest"
import criteria from "./CustomFormFunctions/criteria"


const operators = {
  "bool": [
    "=",
  ],
  "float": [
    "=",
    "<",
    ">"
  ],
  "int": [
    "=",
    "<",
    ">"
  ],
  "dttm": [
    "=",
    "<",
    ">"
  ],
}

const CustomForm = ({
  drives,
  setTableData,
  setIsLoading,
  setInfoMessages,
  activePage,
  setNumPages,
}) => {

  const [ dataNeeded, setDataNeeded ] = useState([])
  const [ filters, setFilters ] = useState([])
  const [ formData, setFormData ] = useState({})
  const [ isGeneralFilter, setIsGeneralFilter ] = useState(true)
  const [ filterData, setFilterData ] = useState({})
  const [ isSubmitted, setIsSubmitted ] = useState(false)
  const [ numCsvRows, setNumCsvRows ] = useState("")


  useEffect(()=> {
    if (dataNeeded.length && isSubmitted) {
      customFormSubmitRequest({
        isCsv: false,
        setInfoMessages: setInfoMessages,
        setIsLoading: setIsLoading,
        setNumPages: setNumPages,
        setTableData: setTableData,
        activePage: activePage,
        dataNeeded: dataNeeded,
        filters: filters,
        drive_id: formData.drive_id,
      })
    }
  }, [
    activePage,
    filters,
    isSubmitted,
    dataNeeded,
    formData.drive_id,
    setInfoMessages,
    setIsLoading,
    setNumPages,
    setTableData
  ])

  const getParamStrings = (criteriaName, value, index) => {
    let param = criteria.find(x => x.name === criteriaName)
    param = (param || {}).parameters || []
    param = param[index] || ''
    if (param.length && value && value.toString().length) {
      return `${param}: ${value}`
    } else if (param.length) {
      return `${param}`
    } else {
      return ''
    }
  }

  const handleDataAdd = () => {
    // find data type
    let dataTypes = formData.columns.map(x => {
        let table = TableDefinitions[formData.topic]
        // console.log("Topic", formData.topic, 'Table', table)
        // let subtopics = Object.keys(x?.subs || {})
        if (x in table.columns) {
          return table.columns[x]
        }
        else if (2 > 1) {
          // console.log("Table", table)
          return null
        } else {
          return null
        }
      }
    )
    setIsSubmitted(false)
    setDataNeeded(old => ([...old, {
      topic: formData.topic,
      columns: formData.columns,
      data_types: dataTypes
    }]))
    setFormData(old => ({
      ...old,
      section: null,
      topic: null,
      columns: []
    }))
  }

  const handleFilterAdd = () => {
    setIsSubmitted(false)
    setFilters(old => ([...old, {
      ...filterData, filter_type: (
        isGeneralFilter
        ? "general"
        : "column"
      )
    }]))
    setFilterData({})
  }
  const handleChange = (e, { name, value }) => (
    setFormData(cur => ({ ...cur, [name]: value }))
  )

  const handleFilterChange = (e, { name, value }) => {
    setFilterData(cur => ({ ...cur, [name]: value }))
  }

  const handleDataDelete = (_, d) => {
    let index = d['data-key']
    setIsSubmitted(false)
    setDataNeeded(dn => [
      ...(dn || []).slice(0, index),
      ...(dn || []).slice(index + 1)
    ])
  }

  const handleCriteriaDelete = (_, d) => {
    let index = d['data-key']
    setIsSubmitted(false)
    setFilters(dc => [
      ...(dc || []).slice(0, index),
      ...(dc || []).slice(index + 1)
    ])
  }

  return (
  <>
    <Form>
      <Form.Group widths={'four'}>
        <Form.Dropdown
          label='Drive'
          name='drive_id'
          placeholder='Drive'
          onChange={handleChange}
          multiple
          selection
          options={
            drives
            .map(x => ({key: x, text: String(x).padStart(3, '0'), value: x}))
          }
        />
      </Form.Group>
      <Divider horizontal content={'Data selection'}/>
      <Form.Group widths={'equal'}>

        <Form.Dropdown
          onChange={handleChange}
          selection
          label='Topic'
          name='topic'
          placeholder="Choose topic"
          value={formData.topic}
          options={(Object.keys(TableDefinitions).map(x => ({
            key: x,
            text: x.replace(/_/g, " "),
            value: x
          })))}
        />

        <Form.Dropdown
          name='columns'
          label='Data'
          placeholder='Choose data'
          onChange={handleChange}
          selection
          multiple
          value={formData.columns || []}
          options={
            Object.keys((
              TableDefinitions[formData.topic] || {}
            ).columns || {}).map(x => ({
              key: x,
              text: x,
              value: x
            }))
          }
        />
      </Form.Group>
      <Form.Button content='Add data' onClick={handleDataAdd} />

      <Divider horizontal content={'Criteria'} />
      <Grid>
        <Grid.Column width={4}>
          <Form.Group>
            <Form.Radio
              label='General'
              name='general'
              value='general'
              checked={isGeneralFilter}
              onChange={() => setIsGeneralFilter(x => !x)}
            />
            <Form.Radio
              label='Column'
              name='column'
              value='column'
              checked={!isGeneralFilter}
              onChange={() => setIsGeneralFilter(x => !x)}
            />
          </Form.Group>
        </Grid.Column>
      {
        isGeneralFilter ?
        <Grid.Column width={12}>
          <Form.Dropdown
            label="Criteria"
            name="criteria"
            selection
            clearable
            value={filterData["criteria"] || null}
            options={criteria.map(x => (
              {
                key: x.name,
                text: x.name.replace(/_/g, " "),
                value: x.name
              }
            ))}
            onChange={handleFilterChange}
            key='criteria'
          />
          {
            criteria.find(
              x => (x.name !== undefined && x.name === filterData.criteria)
            )?.parameters.map((para, i) => {
              return <Form.Input
                label={para.replace(/_/g, " ")}
                name={`param${i + 1}`}
                key={para.replace(/\s/g, '_')}
                onChange={handleFilterChange}
              />
            })
          }
        </Grid.Column>
        :
        <Grid.Column width={12}>
          <Form.Group widths={'equal'}>
            <Form.Dropdown
              onChange={handleFilterChange}
              selection
              label='Topic'
              name='topic'
              placeholder="Choose topic"
              value={filterData.topic}
              options={(Object.keys(TableDefinitions).map(x => ({
                key: x,
                text: x.replace(/_/g, " "),
                value: x
              })))}
            />

            <Form.Dropdown
              name='column'
              label='Column'
              placeholder='Choose data'
              onChange={handleFilterChange}
              selection
              value={filterData.column || ""}
              disabled={!filterData?.topic}
              options={
                Object.keys((
                  TableDefinitions[filterData.topic] || {}
                ).columns || {}).map(x => ({
                  key: x,
                  text: x,
                  value: x
                }))
              }
            />

            <Form.Dropdown
              name='operator'
              label='Operator'
              placeholder='Choose data'
              onChange={handleFilterChange}
              disabled={!(filterData?.column)}
              selection
              value={filterData.operator || ""}
              options={
                filterData.column
                ? (operators[TableDefinitions[filterData.topic].columns[filterData.column]] || []).map(x => ({
                  key: x,
                  text: x,
                  value: x
                }))
                : []
              }
            />

            <Form.Input
              name='value'
              label='Value'
              placeholder='Enter data'
              onChange={handleFilterChange}
              disabled={!(filterData?.operator)}
              value={filterData.value || ""}
            />
          </Form.Group>
        </Grid.Column>
      }
      </Grid>
      <Form.Button content='Add filter' onClick={handleFilterAdd} />
    </Form>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell content='Topic'/>
              <Table.HeaderCell content='Columns'/>
              <Table.HeaderCell content='delete'/>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              (dataNeeded || []).map((x, i) => <Table.Row key={i}>
                <Table.Cell content={x.topic}/>
                <Table.Cell content={x.columns.join(', ')}/>
                <Table.Cell>
                  <Button.Group size='tiny' fluid>
                    <Popup content='Delete' trigger={
                      <Button
                        type="button"
                        basic color='red'
                        icon='trash alternate outline'
                        data-key={i}
                        onClick={handleDataDelete}
                        compact
                      />
                    }/>
                  </Button.Group>
                </Table.Cell>
              </Table.Row>)
            }
          </Table.Body>
        </Table>

        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell content='Topic'/>
              <Table.HeaderCell content='Criteria'/>
              <Table.HeaderCell content='delete'/>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              (filters || []).map((x, i) => {
                if (!('column' in x)) {
                  return <Table.Row key={i}>
                    <Table.Cell content={`${x.criteria}`}/>
                    <Table.Cell content={
                      `${getParamStrings(x.criteria, x.param1, 0)}`
                      + `, ${getParamStrings(x.criteria, x.param2, 1)}`
                    } />
                    <Table.Cell>
                      <Button.Group size='tiny' fluid>
                        <Popup content='Delete' trigger={
                          <Button
                            type="button"
                            basic color='red'
                            icon='trash alternate outline'
                            data-key={i}
                            onClick={handleCriteriaDelete}
                            compact
                          />
                        }/>
                      </Button.Group>
                    </Table.Cell>
                  </Table.Row>
                } else {
                  return <Table.Row key={i}>
                    <Table.Cell content={
                      `${x.topic}`
                    } />
                    <Table.Cell content={
                      `${x.column} ${x.operator} ${x.value}`
                    } />
                    <Table.Cell>
                      <Button.Group size='tiny' fluid>
                        <Popup content='Delete' trigger={
                          <Button
                            type="button"
                            basic color='red'
                            icon='trash alternate outline'
                            data-key={i}
                            onClick={handleCriteriaDelete}
                            compact
                          />
                        }/>
                      </Button.Group>
                    </Table.Cell>
                  </Table.Row>
                }

              })
            }
          </Table.Body>
        </Table>
        <Form>
        <Form.Group>
          <Form.Button
            content='Submit'
            onClick={() => {
              setIsSubmitted(true)
            }}
          />
          <Form.Button
            content="Get csv"
            onClick={(() => {
              setIsSubmitted(false)
              customFormSubmitRequest({
                isCsv: true,
                numRows: numCsvRows,
                setInfoMessages: setInfoMessages,
                setIsLoading: setIsLoading,
                setNumPages: setNumPages,
                setTableData: setTableData,
                activePage: activePage,
                dataNeeded: dataNeeded,
                filters: filters,
                drive_id: formData.drive_id,
              })
            })}
          />
          <Form.Input
            placeholder="Rows to return"
            value={numCsvRows}
            name="numRows"
            onChange={(_, {value}) => {
              setIsSubmitted(false)
              setNumCsvRows(value.replace(/\D/g, ''))
              setTableData({
                data: [],
                columns: [],
                data_types: []
              })
              setNumPages(1)
            }}
          />
        </Form.Group></Form>
  </>)
}

export default CustomForm
