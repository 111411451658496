import { Card, GridColumn } from "semantic-ui-react"

import formatString from './formatString'

const V2x = ({ data }) => {

  return <GridColumn>
    <Card fluid>
      <Card.Content header='V2X' />
      <Card.Content style={{fontSize: "1.5em"}}>
        {formatString(`**Events:** ${data.v2x || 0}`)}
      </Card.Content>
    </Card>
  </GridColumn>
}

export default V2x
