import axios from 'axios'
import { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Header, Container, Form, Grid, Button, Message } from 'semantic-ui-react'
import { BASE_URL } from '../settings'

const Reset = () => {
  const { token } = useParams()
  const { push } = useHistory()

  const [ password, setPassword ] = useState("")
  const [ passwordConf, setPasswordConf ] = useState("")
  const [ infoMessages, setInfoMessages ] = useState([])

  const handleFocus = e => e.target.select()

  const handleRegInfoChange = (_, data) => {
    const name = data.name
    const value = data.value
    if (name==='password') {
      setPassword(value)
    } else if (name==='passwordConf') {
      setPasswordConf(value)
    }
  }

  const submitRegistration = () => {
    axios.post(BASE_URL + '/auth/reset_confirm', {token: token, password: password})
    .then(res => {
      setInfoMessages(res?.data?.messages || [])
    })
    .then(() => {
      setTimeout(() => {
        push('/')
      }, 2000)
    })
    .catch(err => {
      setInfoMessages(err?.response?.data?.messages || [
        {
            "header": "Unspecified error",
            "message": 'Something went wrong, but was not specified.',
            "icon": "database",
            "level": "negative"
        }
    ])
    })
  }

  function handleMessageDismiss(e, data) {
    let message_id = data["data-key"]
    setInfoMessages(infoMessages.filter(
      (_, idx) => parseInt(idx) !== parseInt(message_id)
    ))
  }

  return <>
    <Container>
      <Header
        as='h1'
        style={{
        fontSize: '3em',
        fontWeight: 'normal',
        marginTop: '1.5em',
        marginBottom: '1.5em'
        }}
        content='Reset Password'
      />
    </Container>
    <Container textAlign='left' style={{marginBottom: '1.5em'}}>
      <Grid>
        <Grid.Column width='9'>
          <Form>
            <Form.Input
              label='Password'
              type='password'
              name="password"
              value={password || ""}
              onChange={handleRegInfoChange}
              autoComplete="off"
              onFocus={handleFocus}
              required
            />
            <Form.Input
              label='Password confirmation'
              type='password'
              name="passwordConf"
              value={passwordConf || ""}
              onChange={handleRegInfoChange}
              autoComplete="off"
              onFocus={handleFocus}
            />
            <Button
              content={'Submit'}
              disabled={Boolean(!password || password !== passwordConf)}
              onClick={submitRegistration}
            />
          </Form>
        </Grid.Column>
      </Grid>
    </Container>
    <Container>
      {infoMessages.map((msg, index) =>
          <Message
            key={index}
            data-key={index}
            attached='bottom'
            icon={msg.icon}
            header={msg.header}
            content={msg.message}
            warning={!Boolean(msg.level) || msg.level === "warning"}
            negative={msg.level === "negative"}
            positive={msg.level === "positive"}
            onDismiss={handleMessageDismiss}
          />
        )
      }
    </Container>
  </>
}

export default Reset
