import {
  Container,
  Divider,
  Header,
  Icon,
  Grid,
} from "semantic-ui-react"
import AutomationPerformancePie from "./AutomationPerformancePie"
import PhaseTable from "./PhaseTable"
import RoadConditions from "./RoadConditions"
import RoadTypes from "./RoadTypes"
import ApolloObstacles from "./ApolloObstacles"
import PassengerInformationAggregate from "./PassengerInformationAggregate"
import VulnerableRoadUsers from "./VulnerableRoadUsers"
import SafetyCritical from "./SafetyCritical"
import V2x from "./V2x"
import WeatherIcons from "./WeatherIcons"

const COLORS = {
  automatedBackground: 'rgba(67, 163, 243, 0.2)',
  automatedBorder: 'rgba(67, 163, 243, 1)',
  manualBackground: 'rgba(27, 190, 0, 0.2)',
  manualBorder: 'rgba(27, 190, 0, 1)',
}

const DashboardAll = ({
  chartDataDisplayed,
  push,
  units
}) => {
  return (<div style={{paddingBottom: '70px'}}>
    <Container>
      <Grid>
        <Grid.Column width={8}>
          <AutomationPerformancePie
            data={chartDataDisplayed}
            units={units}
            colors={COLORS}
            showProjectSummary
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <PhaseTable push={push} data={chartDataDisplayed}/>
        </Grid.Column>
      </Grid>
    </Container>

    <Divider horizontal section>
      <Header as='h4'>
          <Icon name='bar chart' />
          Weather
        </Header>
    </Divider>

    <Container>
      <Grid columns={1}>
        <WeatherIcons data={chartDataDisplayed} />
      </Grid>
    </Container>

    <Divider horizontal section>
      <Header as='h4'>
        <Icon name='bar chart' />
        Road
      </Header>
    </Divider>

    <Container>
      <Grid columns={2}>
        <Grid.Column>
          <RoadConditions
            data={chartDataDisplayed}
            colors={COLORS}
            units={units}
          />
        </Grid.Column>
        <Grid.Column>
          <RoadTypes
            data={chartDataDisplayed}
            colors={COLORS}
            units={units}
          />
        </Grid.Column>
      </Grid>

    </Container>

    <Divider horizontal section>
      <Header as='h4'>
        <Icon name='bar chart' />
        Encounters
      </Header>
    </Divider>

    <Container>
      <Grid columns={4}>
          <SafetyCritical data={chartDataDisplayed} />
          <ApolloObstacles data={chartDataDisplayed} />
          <VulnerableRoadUsers data={chartDataDisplayed} />
          <V2x data={chartDataDisplayed} />
      </Grid>
    </Container>

    <Divider section horizontal >
      <Header as='h4'>
        <Icon name='bar chart'/>
        Passenger information
      </Header>
    </Divider>

    <Container>
      <Grid columns={1} centered>
        <PassengerInformationAggregate data={chartDataDisplayed} />
      </Grid>
    </Container>

  </div>)
}

export default DashboardAll
