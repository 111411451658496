import { Card, GridColumn, List } from "semantic-ui-react"

import { useEffect, useState } from 'react'

import formatString from './formatString'


const VulnerableRoadUsers = ({ data }) => {
  const [ vru, setVru ] = useState({})
  useEffect(() => {
    let outData = {total: 0}
    if (data?.vru?.auto) {
      outData.auto = {}
      Object.keys(data.vru.auto).forEach(key => {
        if (!(key in outData.auto)) {
          outData.auto[key] = 0
        }
        outData.auto[key] += data.vru.auto[key]
        outData.total += data.vru.auto[key]
      })
    }
    if (data?.vru?.manual) {
      outData.manual = {}
      Object.keys(data.vru.manual).forEach(key => {
        if (!(key in outData.manual)) {
          outData.manual[key] = 0
        }
        outData.manual[key] += data.vru.manual[key]
        outData.total += data.vru.manual[key]
      })
    }
    setVru(outData)
  }, [data])

  return <GridColumn>
    <Card fluid>
      <Card.Content header='Vulnerable road users' />
      <Card.Content style={{maxHeight: "300px", overflowY: 'scroll'}}>
        <List celled>
          <List.Item
            style={{fontSize: "1.5em"}}
            content={formatString(`**Total:** ${vru?.total || 0}`)}
          />
          {
            (Object.keys(vru?.auto || {}).length > 0) ? <>
              <List.Header content="Auto:"  style={{fontSize: "1.25em"}}/>
              {
                Object.keys(vru.auto).map((key, i) => <List.Item
                  key={key}
                  content={formatString(`**${key}:** ${vru.auto[key]}`)}
                  style={(i % 2 === 1) ? {background: "#EEEEEE"} : {}}
                />)
              }
            </> : null
          }
          {
            (Object.keys(vru?.manual || {}).length > 0) ? <>
              <List.Header content="Manual:"  style={{fontSize: "1.25em"}}/>
              {
                Object.keys(vru.manual).map((key, i) => <List.Item
                  key={key}
                  content={formatString(`**${key}:** ${vru.manual[key]}`)}
                  style={(i % 2 === 1) ? {background: "#EEEEEE"} : {}}
                />)
              }
            </> : null
          }
        </List>
      </Card.Content>
    </Card>
  </GridColumn>
}

export default VulnerableRoadUsers
