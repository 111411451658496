import { Image } from "semantic-ui-react"


const Footer = () => {
  return <div>
  <div style={{
    background: "rgba(255,205,0)",
    color: 'black',
    height: '56px',
    display: 'flex',
    borderBottom: '1px solid black',
    alignItems: 'center',
    justifyContent: 'flex-start',
    bottom: '0',
    position: 'fixed',
    width: '100%'
  }}>
      <div style={{
          WebkitTextStroke: '1px rgba(100,100,100,0.1)',
          color: 'black',
          background: '#000',
          width: '119px',
          height: '56px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: '1rem',
      }}>
        <a href="https://uiowa.edu">
          <Image src="/Block-IOWA-GOLD-ffcd00-thmb200.png" />
        </a>
      </div>
      <div
        style={{
          WebkitTextStroke: '1px rgba(100,100,100,0.1)',
        }}
      >
        <a style={{
          color: 'black',
          textDecoration: 'none'
        }} href="https://dsri.uiowa.edu">
          Driving Safety Research Institute
        </a>
      </div>
  </div>
</div>
}

export default Footer
