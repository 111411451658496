import { Pie } from "react-chartjs-2"
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { useEffect, useState } from "react"
import { Card, Divider, Grid, Header, List } from "semantic-ui-react"
import ReactMarkdown from "react-markdown"

const miInKm = 0.621371

const formatString = x => {
  if (x.constructor !== String) {
    return x
  }
  // Replace <br> with the MD equivalent and trim lines
  x = x.split('<br>').map(x => x.trim()).join('  \n')
  return <ReactMarkdown children={x}/>
}

const AutomationPerformancePie = ({
  data,
  units,
  colors,
  showProjectSummary
}) => {
  const [ dat, setDat ] = useState({
    total: 0.0,
    auto: 0.0,
    manual: 0.0,
    manualPercent: 0.0,
    autoPercent: 0.0
  })

  useEffect(() => {
    let total = (
      (parseFloat(data.totals.Auto) || 0.0)
      + (parseFloat(data.totals.Manual) || 0.0)
      + (parseFloat(data.totals['Take Over']) || 0.0)
    ) * (units === 'standard' ? miInKm : 1) || 1
    let manual = (
      (parseFloat(data.totals.Manual) || 0.0)
      + (parseFloat(data.totals['Take Over']) || 0.0)
    ) * (units === 'standard' ? miInKm : 1)
    let auto = (
      parseFloat(data.totals.Auto) || 0.0
    ) * (units === 'standard' ? miInKm : 1)
    let manualPercent = `(${(manual / total * 100).toFixed(0)}%)`
    let autoPercent = `(${(auto / total * 100).toFixed(0)}%)`
    let numTakeovers = parseInt(data.numTakeovers) || 0

    setDat({
      total: total,
      manual: manual,
      auto: auto,
      manualPercent: manualPercent,
      autoPercent: autoPercent,
      numTakeovers: numTakeovers
    })
  }, [data, units])

  return <Grid.Column>
    {
      showProjectSummary === true ? <Header
        style={{verticalAlign: 'top'}}
        as={'h1'}
        content='Project summary:'
      />
      : null
    }
    <Card centered fluid>

      <Card.Content header={`Automation performance (${units === 'standard' ? 'mi' : 'km'})`}/>
      <Card.Content>
        <Grid columns={2}>
          <Grid.Column>
            <List>
              <List.Item
                style={{
                  fontSize: '1.5em'
                }}
                content={formatString(`**Total:** ${dat.total.toFixed(0)}`)}
                key={'total'}
              />
              <List.Item
                content={formatString(`**Auto:** ${dat.auto.toFixed(0)} ${dat.autoPercent}`)}
                key={'auto'}
              />
              <List.Item
                content={formatString(`**Manual:** ${dat.manual.toFixed(0)} ${dat.manualPercent}`)}
                key={'manual'}
              />
              <Divider horizontal />
              <List.Item
                style={{
                  fontSize: '1.5em'
                }}
                content={formatString(`**Takeovers:** ${dat.numTakeovers}`)}
                key={'totalTakeovers'}
              />
            </List>
          </Grid.Column>
          <Grid.Column>
            <Pie
              data={{
                labels: ['Automated', 'Manual'],
                datasets: [
                  {
                    labels: ['Automated', 'Manual'],
                    data: [
                      dat.auto,
                      dat.manual
                    ],
                    backgroundColor: [
                      colors.automatedBackground,
                      colors.manualBackground
                    ],
                    borderColor: [
                      colors.automatedBorder,
                      colors.manualBorder
                    ],
                    borderWidth: 1,
                  },
                ],
              }}
              plugins={[ChartDataLabels]}
              options={{
                plugins: {
                  datalabels: {
                    formatter: (value, ctx) => {
                      let sum = ctx.dataset.data.reduce((a, b) => a + b) || 1
                      let outVal = (
                        `${(value).toFixed(0)}`
                        + ` (${((value || 0.0) * 100 / sum).toFixed(0)+"%"})`
                      )
                      return outVal;
                    },
                  }
                }
              }}
            />
          </Grid.Column>
        </Grid>

      </Card.Content>
    </Card>
  </Grid.Column>
}

export default AutomationPerformancePie
