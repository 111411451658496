import axios from "axios"
import { BASE_URL } from "../../../settings"
import TableDefinitions from "../../PlaygroundAssets/TableDefinitions"
import criteria from "./criteria"


const customFormSubmitRequest = ({
  isCsv,
  numRows,
  setInfoMessages,
  setIsLoading,
  setNumPages,
  setTableData,
  activePage,
  dataNeeded,
  filters,
  drive_id,  // formData.drive_id
}) => {
  const makeReqData = () => {
    let data_needed = dataNeeded.map(x => {
      let topic = x.topic
      let table = TableDefinitions[topic].table
      return {table: table, columns: x.columns, data_types:x.data_types}
    })
    // Add filters that aren't drive_id
    let req_filters = filters.map(x => {
      // Add general filters
      if (x.filter_type === 'general') {
        let rule = criteria.find(y => y.name === x.criteria)
        let retVal = {
          name: rule.niceName,
          filter_type: x.filter_type,
        }
        if (x.param1) {
          retVal[rule.niceParameters[0]] = x.param1
        }
        if (x.param2) {
          retVal[rule.niceParameters[1]] = x.param2
        }
        return retVal
      } else {  // Add column filters
        let topic = TableDefinitions[x.topic]
        return {
          filter_type: "column",
          data_type: topic.columns[x.column],
          table: topic.table,
          column: x.column,
          operator: x.operator,
          value: x.value,
        }
      }
    })
    // Add drive_id filter
    if ((drive_id || []).length > 0) {
      req_filters = req_filters = [
        ...req_filters,
        {
          filter_type: "general",
          name: "drive_ids",
          drive_ids: drive_id
        }
      ]
    }
    if (isCsv) {
      return {
        data_needed: data_needed,
        filters: req_filters,
        _limit: numRows || 200,
      }
    }
    return {
      data_needed: data_needed,
      filters: req_filters,
      _page: activePage,
      _limit: 20,
    }
  }

  setIsLoading(true)
  let reqData = makeReqData()
  // console.log("request:", reqData)
  axios.post(
    BASE_URL + '/playground',
    reqData,
    isCsv ? {headers: {Accept: "text/csv"}} : null
  )
  .then(res => {
    if (typeof res.data === "object" && "messages" in res.data) {
      setInfoMessages(res.data.messages)
    } else {
      setInfoMessages([])
    }
    return res
  })
  .then(res => {
    if (isCsv) {
      const type = res.headers['content-type']
      const blob = new Blob([res.data], { type: type, encoding: 'UTF-8' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'playground.csv'
      link.click()
    } else {
      // console.log(res)
      res = res.data
      let newTableDat = {
        columns: res.data.columns || [],
        data_types: res.data.data_types || [],
        data: res.data.data || [],
        scalers: res.data.scalers || {}
      }
      setNumPages(res.data.num_pages || 1)
      // console.log(newTableDat)
      return newTableDat
    }
  })
  .then(res => {if (res) {setTableData(res)}})
  .then(() => setIsLoading(false))
  .catch(err => {
    setIsLoading(false)
    console.log(err)
    setInfoMessages(err?.response?.data?.messages || [
      {
          "header": "Unspecified error",
          "message": 'Something went wrong, but was not specified.',
          "icon": "database",
          "level": "negative"
      }
  ])
  })
}


export default customFormSubmitRequest
