import ReactMarkdown from "react-markdown"
import {
  Card,
  GridColumn,
  List
} from "semantic-ui-react"

const formatString = x => {
  if (x.constructor !== String) {
    return x
  }
  // Replace <br> with the MD equivalent and trim lines
  x = x.split('<br>').map(x => x.trim()).join('  \n')
  return <ReactMarkdown children={x}/>
}

const ApolloObstacles = ({data}) => {
  return <GridColumn>
    <Card centered fluid>
      <Card.Content header='Apollo obstacles' />
      <Card.Content>
        <List celled>
          <List.Item
            style={{
              fontSize: '1.5em'
            }}
            content={formatString(`**Total:** ${data.apolloObstacles.Total}`)}
            key={'apollo_total'}
          />
          {
            Object.keys(data.apolloObstacles)
            .filter(key => (key !== 'Total' && parseInt(data.apolloObstacles[key]) > 0))
            .map(key => ({key: key, count: parseInt(data.apolloObstacles[key])}))
            .sort((a, b) => b.count - a.count)
            .map((x, i) => <List.Item
              key={x.key}
              content={formatString(`**${x.key}:** ${x.count}`)}
              style={(i % 2 === 1) ? {background: "#EEEEEE"} : {}}
            />)
          }
        </List>
      </Card.Content>
    </Card>
  </GridColumn>
}

export default ApolloObstacles
