import { useEffect, useState } from "react"
import { Card, Grid, Table } from "semantic-ui-react"


const PassengerInformationAggregate = ({ data }) => {
  // tabDat[age][sex][impairment]
  const [ tabDat, setTabDat ] = useState({})

  useEffect(() => {
    let newTabDat = {}
    Array.from([25, 35, 45, 55, 65, 75, 85, 95]).forEach(age => {
      newTabDat[age] = {}
      Array.from(['m', 'f']).forEach(sex => {
        newTabDat[age][sex] = {}
        Array.from(['u', 'm', 'v']).forEach(impairment => {
          newTabDat[age][sex][impairment] = 0
        })
      })
    });
    [...(data?.passengers || [])].forEach(passenger => {
      let age = passenger.age || ''
      let sex = passenger.sex || ''
      let impairments = passenger.impairments || []
      if (age.includes('25')) {
        age = 25
      } else if (age.includes('35')) {
        age = 35
      } else if (age.includes('45')) {
        age = 45
      } else if (age.includes('55')) {
        age = 55
      } else if (age.includes('65')) {
        age = 65
      } else if (age.includes('75')) {
        age = 75
      } else if (age.includes('85')) {
        age = 85
      } else if (age.includes('95')) {
        age = 95
      } else {
        console.log("Age was not able to be processed", age)
        return
      }
      if (sex.includes('emale')) {
        sex = 'f'
      } else if (sex.includes('ale')) {
        sex = 'm'
      } else {
        console.log("Sex was not able to be processed", sex, passenger)
        return
      }
      let unimpaired = true
      impairments.forEach(impair => {
        if (impair.includes('obil')) {
          newTabDat[age][sex]['m'] += 1
          unimpaired = false
        }
        if (impair.includes('is')) {
          newTabDat[age][sex]['v'] += 1
          unimpaired = false
        }
      })
      if (unimpaired === true) {
        newTabDat[age][sex]['u'] += 1
      }
    })
    setTabDat(newTabDat)
  }, [data])

  return <Grid.Column>
    <Card fluid>
      <Card.Content>
        <Grid>
          <Grid.Column width={4}>
            <Card fluid>
              <Card.Content header='Total:'/>
              <Card.Content style={{fontSize: '6em', textAlign: 'center', padding: '1em 0'}}>
                {(data?.passengers || []).length}
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column width={12}>
            <Table celled compact striped textAlign="center">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell content='Age' rowSpan='2'/>
                  <Table.HeaderCell content='Unimpaired' colSpan='2'/>
                  <Table.HeaderCell content='Mobility impaired' colSpan='2'/>
                  <Table.HeaderCell content='Visually impaired' colSpan='2'/>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell content='male'/>
                  <Table.HeaderCell content='female'/>
                  <Table.HeaderCell content='male'/>
                  <Table.HeaderCell content='female'/>
                  <Table.HeaderCell content='male'/>
                  <Table.HeaderCell content='female'/>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell content='25-34'/>
                  <Table.Cell content={tabDat[25]?.m?.u || 0}/>
                  <Table.Cell content={tabDat[25]?.f?.u || 0}/>
                  <Table.Cell content={tabDat[25]?.m?.m || 0}/>
                  <Table.Cell content={tabDat[25]?.f?.m || 0}/>
                  <Table.Cell content={tabDat[25]?.m?.v || 0}/>
                  <Table.Cell content={tabDat[25]?.f?.v || 0}/>
                </Table.Row>
                <Table.Row>
                  <Table.Cell content='35-44'/>
                  <Table.Cell content={tabDat[35]?.m?.u || 0}/>
                  <Table.Cell content={tabDat[35]?.f?.u || 0}/>
                  <Table.Cell content={tabDat[35]?.m?.m || 0}/>
                  <Table.Cell content={tabDat[35]?.f?.m || 0}/>
                  <Table.Cell content={tabDat[35]?.m?.v || 0}/>
                  <Table.Cell content={tabDat[35]?.f?.v || 0}/>
                </Table.Row>
                <Table.Row>
                  <Table.Cell content='45-54'/>
                  <Table.Cell content={tabDat[45]?.m?.u || 0}/>
                  <Table.Cell content={tabDat[45]?.f?.u || 0}/>
                  <Table.Cell content={tabDat[45]?.m?.m || 0}/>
                  <Table.Cell content={tabDat[45]?.f?.m || 0}/>
                  <Table.Cell content={tabDat[45]?.m?.v || 0}/>
                  <Table.Cell content={tabDat[45]?.f?.v || 0}/>
                </Table.Row>
                <Table.Row>
                  <Table.Cell content='55-64'/>
                  <Table.Cell content={tabDat[55]?.m?.u || 0}/>
                  <Table.Cell content={tabDat[55]?.f?.u || 0}/>
                  <Table.Cell content={tabDat[55]?.m?.m || 0}/>
                  <Table.Cell content={tabDat[55]?.f?.m || 0}/>
                  <Table.Cell content={tabDat[55]?.m?.v || 0}/>
                  <Table.Cell content={tabDat[55]?.f?.v || 0}/>
                </Table.Row>
                <Table.Row>
                  <Table.Cell content='65-74'/>
                  <Table.Cell content={tabDat[65]?.m?.u || 0}/>
                  <Table.Cell content={tabDat[65]?.f?.u || 0}/>
                  <Table.Cell content={tabDat[65]?.m?.m || 0}/>
                  <Table.Cell content={tabDat[65]?.f?.m || 0}/>
                  <Table.Cell content={tabDat[65]?.m?.v || 0}/>
                  <Table.Cell content={tabDat[65]?.f?.v || 0}/>
                </Table.Row>
                <Table.Row>
                  <Table.Cell content='75-84'/>
                  <Table.Cell content={tabDat[75]?.m?.u || 0}/>
                  <Table.Cell content={tabDat[75]?.f?.u || 0}/>
                  <Table.Cell content={tabDat[75]?.m?.m || 0}/>
                  <Table.Cell content={tabDat[75]?.f?.m || 0}/>
                  <Table.Cell content={tabDat[75]?.m?.v || 0}/>
                  <Table.Cell content={tabDat[75]?.f?.v || 0}/>
                </Table.Row>
                <Table.Row>
                  <Table.Cell content='85-94'/>
                  <Table.Cell content={tabDat[85]?.m?.u || 0}/>
                  <Table.Cell content={tabDat[85]?.f?.u || 0}/>
                  <Table.Cell content={tabDat[85]?.m?.m || 0}/>
                  <Table.Cell content={tabDat[85]?.f?.m || 0}/>
                  <Table.Cell content={tabDat[85]?.m?.v || 0}/>
                  <Table.Cell content={tabDat[85]?.f?.v || 0}/>
                </Table.Row>
                <Table.Row>
                  <Table.Cell content='95+'/>
                  <Table.Cell content={tabDat[95]?.m?.u || 0}/>
                  <Table.Cell content={tabDat[95]?.f?.u || 0}/>
                  <Table.Cell content={tabDat[95]?.m?.m || 0}/>
                  <Table.Cell content={tabDat[95]?.f?.m || 0}/>
                  <Table.Cell content={tabDat[95]?.m?.v || 0}/>
                  <Table.Cell content={tabDat[95]?.f?.v || 0}/>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid>
      </Card.Content>
    </Card>
  </Grid.Column>
}

export default PassengerInformationAggregate
