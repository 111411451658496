import { useState } from "react"
import { Form, Header } from "semantic-ui-react"

import EnvironmentalData from "./CommonFormComponents/EnvironmentalData"
import RatingsOfAnxiety from "./CommonFormComponents/RatingsOfAnxiety"
import PassengerData from "./CommonFormComponents/PassengerData"
import Takeover from "./CommonFormComponents/Takeover"
import commonFormSubmitRequest from "./CommonFormFunctions/commonFormSubmitRequest"

const commonQueries = [
  {
    name: "Environmental Data",
    internalName: "environmental_data",
    form: (queryData, handleChange, phaseDrives) => <EnvironmentalData
      queryData={queryData}
      handleChange={handleChange}
      phaseDrives={phaseDrives}
    />
  }, {
    name: "Passenger Ratings of Anxiety",
    internalName: "ratings_of_anxiety",
    form: (queryData, handleChange, phaseDrives) => <RatingsOfAnxiety
      queryData={queryData}
      handleChange={handleChange}
      phaseDrives={phaseDrives}
    />
  }, {
    name: "Passenger Data",
    internalName: "passenger_data",
    form: (queryData, handleChange, phaseDrives) => <PassengerData
      queryData={queryData}
      handleChange={handleChange}
      phaseDrives={phaseDrives}
    />
  }, {
    name: "Takeovers",
    internalName: "takeover_gps_location",
    form: (queryData, handleChange, phaseDrives) => <Takeover
      queryData={queryData}
      handleChange={handleChange}
      phaseDrives={phaseDrives}
    />
  }
]


const CommonForm = ({
  phaseDrives,
  setTableData,
  setIsLoading,
  setInfoMessages,
}) => {
  const [ formData, setFormData ] = useState({filter_drives: 'all'})

  const handleChange = (e, { name, value }) => (
    setFormData(cur => ({ ...cur, [name]: value }))
  )

  return (
    <>
      <Form>
        <Header as={'h1'} content={'Common query'} />
        <Form.Group widths={'four'}>
          <Form.Dropdown
            label='Select a type of query'
            name='query_name'
            placeholder='Select a type of query'
            onChange={handleChange}
            selection
            options={commonQueries.map(x => ({
              key: x.name, text: x.name, value: x.internalName
            }))}
          />
        </Form.Group>
      </Form>
      {
        formData?.query_name
        ? (
          commonQueries
          .find(x => x.internalName === formData.query_name)
          .form(formData, handleChange, phaseDrives)
        )
        : null
      }
      <Form><Form.Group>
        <Form.Button
          content='Submit'
          onClick={() => commonFormSubmitRequest({
              isCsv: false,
              setIsLoading: setIsLoading,
              setInfoMessages: setInfoMessages,
              setTableData: setTableData,
              formData: formData
          })}
        />
        <Form.Button
          content="Get csv"
          onClick={(() => {
            commonFormSubmitRequest({
              isCsv: true,
              setInfoMessages: setInfoMessages,
              setIsLoading: setIsLoading,
              setTableData: setTableData,
              formData: formData
            })
          })}
        />
      </Form.Group></Form>
    </>
  )
}

export default CommonForm
