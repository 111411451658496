import axios from "axios"
import { BASE_URL } from "../../settings"

const getDrives = ({setIsLoading, setInfoMessages}) => {
  return (async () => {
    if (setIsLoading !== undefined) setIsLoading(x => x + 1)
  })()
  .then(() => axios.get(BASE_URL + `/drives`))
  .then(res => {
    if (setInfoMessages !== undefined) {
      setInfoMessages(res?.data?.messages || [])
    }
    return res.data
  })
  .then(res => res.data)
  .then(res => {
    if (setIsLoading !== undefined) setIsLoading(x => x - 1)
    return res
  })
  .catch(err => {
    if (setIsLoading !== undefined) setIsLoading(x => x - 1)
    if (setInfoMessages !== undefined) {
      setInfoMessages(err?.response?.data?.messages || [
        {
            "header": "Unspecified error",
            "message": 'Something went wrong, but was not specified.',
            "icon": "database",
            "level": "negative"
        }
      ])
    }
    return err
  })
}

export default getDrives
