import { useEffect, useState } from "react"
import ReactMarkdown from "react-markdown"
import {
  Button,
  Card,
  Form,
  Grid,
  Icon,
  List,
  Modal,
  Popup
} from "semantic-ui-react"
import jwt_decode from 'jwt-decode'

import {getJwt, nowTrunc} from '../../utilities/jwt_utils'
import axios from "axios"
import { BASE_URL } from "../../settings"

const formatString = x => {
  if (x.constructor !== String) {
    return x
  }
  // Replace <br> with the MD equivalent and trim lines
  x = x.split('<br>').map(x => x.trim()).join('  \n')
  return <ReactMarkdown children={x}/>
}


const putDriveNotes = ({driveName, driveNotes, setIsLoading}) => {
  if (setIsLoading !== undefined) {
    setIsLoading(x => x + 1)
  }
  return (
    getJwt()
    .then(jwt => axios.put(BASE_URL + `/drive_notes/${driveName}`,
      {drive_notes: driveNotes},
      {
        headers: {
          'Authorization': "Bearer ".concat(jwt)
        }
      })
    )
    .then(res => res.data)
    .then(res => {
      if (setIsLoading !== undefined) {
        setIsLoading(x => x - 1)
      }
      return res
    })
    .catch(err => {
      if (setIsLoading !== undefined) {
        setIsLoading(x => x - 1)
      }
      let errorStr = (
        err?.response?.data?.messages || "unknown error in putDriveNotes"
      )
      console.log(errorStr)
      throw ReferenceError(errorStr)
    })
  )
}


const DriveInformation = ({
  data,
  setData,
  setChartDataAll,
  setIsLoading
}) => {
  const [ isLoggedIn, setIsLoggedIn ] = useState(false)
  const [ userRole, setUserRole ] = useState("")
  const [ showEditNote, setShowEditNote ] = useState(false)
  const [ driveNotes, setDriveNotes ] = useState("")

  useEffect(() => {
    let refreshToken = localStorage.getItem("refresh_token")
    let role = localStorage.getItem("user_role")
    if (refreshToken) {
      let decoded = jwt_decode(refreshToken, nowTrunc())
      if ("exp" in decoded && decoded.exp > nowTrunc()){
        setIsLoggedIn(true)
        setUserRole(role)
      } else if (isLoggedIn) {
        setIsLoggedIn(false)
        setUserRole("")
      }
    } else if (isLoggedIn) {
      setIsLoggedIn(false)
      setUserRole("")
    }
  }, [isLoggedIn])

  const handleSubmitUserNote = () => {
    putDriveNotes({driveName: data.driveName, driveNotes: driveNotes})
    .then(res => {
      setData(old => ({...old, driveNotes: res.drive_notes}))
      setChartDataAll(old => {
        return {
          ...old,
          individualDrives: {
            ...old.individualDrives,
            [data.driveName]: {
              ...old.individualDrives[data.driveName],
              driveNotes: driveNotes
            }}
        }
      })
    })
    .then(() => setShowEditNote(false))
    .catch(err => console.log(err))
  }

  const InfoList = () => {
    return <List style={{
      marginLeft: '2em',
      marginRight: '2em',
    }}>
      <List.Item
        content={formatString(`**Date:** ${data.date}`)}
        key={'Date'}
      />
      <List.Item
        content={formatString(`**Phase:** ${data.phase}`)}
        key={'Phase'}
      />
      <List.Item
        content={formatString(`**Start location:** ${data.startLocation}`)}
        key={'Start location'}
        style={{paddingTop: "1em"}}
      />
      <List.Item
        content={formatString(`**Start time:** ${data.startDttm}`)}
        key={'Start time'}
      />
      <List.Item
        content={formatString(`**End time:** ${data.stopDttm}`)}
        key={'End time'}
      />
      {
        data.driveNotes ? <List.Item
          content={formatString(`**Drive notes:** ${data.driveNotes}`)}
          key={'Drive notes'}
          style={{paddingTop: "1em"}}
        />
        : null
      }
    </List>
  }
  return <Grid.Column>
    <Modal
      open={showEditNote}
      onClose={() => {
        setShowEditNote(false)
      }}
    >
      <Modal.Header>Edit drive note</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.TextArea
            label='Drive note'
            name='drive_note'
            value={driveNotes || ""}
            width='16'
            onChange={e => setDriveNotes(e.target.value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
      <Button
        type="submit"
        content="Submit"
        width='1'
        onClick={handleSubmitUserNote}
        positive
      />
      <Button
        type="button"
        content="Clear"
        width='1'
        onClick={() => setDriveNotes("")}
      />
      <Button
        type="button"
        content="Cancel"
        width='1'
        onClick={() => {
          setShowEditNote(false)
        }}
        negative
      />
    </Modal.Actions>
    </Modal>
    <Card fluid>
      <Card.Content>
        <Card.Header>
          {`Drive ${data.driveName}`}
          { ['admin', 'superuser'].includes(userRole) ?
            <Popup
              content={'Edit drive notes'}
              trigger={
                <Button
                  icon
                  style={{float: 'right'}}
                  onClick={() => {
                    setDriveNotes(data.driveNotes)
                    setShowEditNote(true)
                  }}
                >
                  <Icon name='edit' />
                </Button>
              }
            />
            : null
          }
        </Card.Header>
      </Card.Content>
      <Card.Content>
        <InfoList />
      </Card.Content>
    </Card>
  </Grid.Column>
}

export default DriveInformation
