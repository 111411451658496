import axios from 'axios'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Header,
  Container,
  Form,
  Message
} from 'semantic-ui-react'
import { getJwt } from '../utilities/jwt_utils'
import { BASE_URL } from '../settings'

const ROLE_CHOICES = [
  "unregistered",  // email not confirmed
  "registered",  // email confirmed
  "vetted",  // approved by ADS
  "dua",  // entered agreement
  "admin",  // can modify users
  "superuser"  // can modify users to admin or superuser roles
]

const Profile = () => {

  const [ userInfo, setUserInfo ] = useState({})
  const [ infoMessages, setInfoMessages ] = useState([])

  let { username } = useParams()

  // get the current info for user
  useEffect(() => {
    getJwt()
    .then(jwt => axios.get(BASE_URL + '/user',
    {
      params: {
        user_email: username
      },
      headers: {
        'Authorization': "Bearer ".concat(jwt)
      }
    }))
    .then(res => {
      setUserInfo(res.data.data)
      if ("messages" in res.data) {
        setInfoMessages(res.data.messages)
      }
    })
    .catch(err => {
      console.log("GET userInfo error: ", err, "response: ", err.response)
      if (err.response?.data?.messages !== undefined) {
        setInfoMessages(err.response.data.messages)
      }
    })
  }, [username])


  const changeUser = () => {
    getJwt()
    .then(jwt => axios.put(
      BASE_URL + '/user',
      userInfo,
      {
        headers: {
          'Authorization': "Bearer ".concat(jwt)
        }
      }
    ))
    .then(res => res.data)
    .then(res => {
      setInfoMessages(res?.messages || [])
      setUserInfo(res.data)
    })
    .catch(err => {
      setInfoMessages(err?.response?.data?.messages || [
        {
            "header": "Unspecified error",
            "message": 'Something went wrong, but was not specified.',
            "icon": "database",
            "level": "negative"
        }
      ])
    })
  }

  const handleUserInfoChange = (_, d) => {
    console.log(d)
    if (d?.name) {
      setUserInfo(old => ({...old, [d.name]: d.value}))
    }

  }

  const handleMessageDismiss = (_, data) => {
    let message_id = data["data-key"]
    setInfoMessages(infoMessages.filter(
      (_, idx) => parseInt(idx) !== parseInt(message_id)
    ))
  }

  const handleFocus = e => {
    e.target.select()
  }

  return <>
    <Container>
      <Header
        as='h1'
        style={{
          fontSize: '3em',
          fontWeight: 'normal',
          marginBottom: 0,
          marginTop: '1.5em',
        }}
      >
        {`User profile for: ${username}`}
      </Header>
    </Container>
    <Container>
      <Form>
        <Form.Group>
          <Form.Input
            name="user_name"
            label="Name"
            placeholder="Name"
            value={userInfo.user_name || ""}
            onChange={handleUserInfoChange}
            autoComplete="off"
            onFocus={handleFocus}
          />
          <Form.Input
            name="user_email"
            label="HawkID/Email"
            placeholder="HawkID/Email"
            value={userInfo.user_email || ""}
            onChange={handleUserInfoChange}
            autoComplete="off"
            onFocus={handleFocus}
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            name="user_affiliation"
            label="Affiliation"
            placeholder="Affiliation"
            value={userInfo.user_affiliation || ""}
            onChange={handleUserInfoChange}
            autoComplete="off"
            onFocus={handleFocus}
          />
          <Form.Dropdown
            name="user_role"
            label="Role"
            placeholder="Select Role"
            selection
            options={ROLE_CHOICES.map(v => ({
              key: v,
              value: v,
              text: v
            }))}
            value={userInfo.user_role || 'unknown'}
            onChange={handleUserInfoChange}
          />
          <Form.Button
            type='submit'
            content='Submit'
            onClick={changeUser}
          />
        </Form.Group>
      </Form>
      {infoMessages.map((msg, index) =>
          <Message
            key={index}
            data-key={index}
            attached='bottom'
            icon={msg.icon}
            header={msg.header}
            content={msg.message}
            warning={!Boolean(msg.level) || msg.level === "warning"}
            negative={msg.level === "negative"}
            positive={msg.level === "positive"}
            onDismiss={handleMessageDismiss}
          />
        )
      }
    </Container>
  </>
}

export default Profile
