import { Card, Embed, Grid } from "semantic-ui-react"

const DriveMap = ({driveId}) => {
  return <Grid.Column>
    <Card fluid>
      {/* <Card.Content header='Drive route' /> */}
      <Card.Content  style={{padding: "0"}}>
      <Embed
        aspectRatio='4:3'
        icon='right circle arrow'
        url={`https://www.nads-sc.uiowa.edu/ADSFRA/?drive=${driveId}`}
        active
      />
      </Card.Content>
    </Card>
  </Grid.Column>
}

export default DriveMap
