import { Card, Grid } from "semantic-ui-react"

const mphInMps = 2.23693629

const WindSpeed = ({data, units}) => {
  // Set up some default values
  let wind
  if (data?.wind_speed === undefined) {
    wind = {average: 11.1, high: 11.1, low: 11.1}
  } else {
    wind = data.wind_speed
  }

  return <Grid.Column>
    <Card fluid centered>
      <Card.Content header={`Wind speed (${units === "standard" ? "mph" : "m/s"})`} />
      <Card.Content>
        <div style={{
          display:"flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}>
          <div style={{marginRight:"20px"}}>
            <div >Avg</div>
            <div style={{fontSize: "200%"}}> {
              (wind.average * (units === "standard" ? mphInMps : 1)).toFixed(0)
            } </div>
          </div>
          <div >
            <div >
              <div style={{color: "black"}}>High</div>
              <div> {
                (wind.high * (units === "standard" ? mphInMps : 1)).toFixed(0)
              } </div>
            </div>
            <div style={{
              width: "120%",
              height: "1px",
              background:"grey",
              margin:"8px 0px"
            }}></div>
            <div style={{
              display:"flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}>
              <div style={{color: "black"}}> Low </div>
              <div> {
                (wind.low * (units === "standard" ? mphInMps : 1)).toFixed(0)
              } </div>
            </div>
          </div>
        </div>
      </Card.Content>
    </Card>
  </Grid.Column>

}
export default WindSpeed
