import { Container, Divider, Header, List, Table } from "semantic-ui-react"
import formatString from "../../utilities/formatString"

const Definition = ({descriptions}) => {
  return <Container>
    <Header as='h1' content={descriptions.name}/>
    <List>
      {
        Object.keys(descriptions)
        .filter(x => !['tables', 'nickname'].includes(x) && descriptions[x].trim().length > 0)
        .map(key => {
          // process key to make pretty
          let prettyKey = key.replaceAll('_', ' ', )
          prettyKey = prettyKey[0].toUpperCase() + prettyKey.substring(1)
          return <List.Item
            content={formatString(`**${prettyKey}:** ${descriptions[key]}`)}
            key={key}
          />
        })
      }
    </List>
    {
      descriptions.tables?.map(table => (
        <Container padded='vertically' key={table.name}>
          <Divider />
          <Header as='h2' content={table.name} />
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell content='name' />
                <Table.HeaderCell content='data_type' />
                <Table.HeaderCell content='units' />
                <Table.HeaderCell content='description' />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                table.rows.map(row => <Table.Row key={row.name}>
                  <Table.Cell content={row.name || ""}/>
                  <Table.Cell content={row.data_type || ""}/>
                  <Table.Cell content={row.units || ""}/>
                  <Table.Cell content={row.description || ""}/>
                </Table.Row>)
              }
            </Table.Body>
          </Table>
        </Container>
      ))
    }
  </Container>

}

export default Definition
