import { useEffect, useState } from "react"
import { Form } from "semantic-ui-react"

import DriveSelector from "./DriveSelector"

// const handleChange = (e, { name, value }) => (
//   setFormData(cur => ({ ...cur, [name]: value }))
// )


const EnvironmentalData = ({queryData, handleChange, phaseDrives}) => {
  const [currentSelection, setCurrentSelection] = useState(
    "road_classification"
  )

  useEffect(() => {
    if (queryData?.child_query_name !== currentSelection) {
      handleChange(
        null, {"name": "child_query_name", "value": currentSelection}
      )
    }

  }, [currentSelection, handleChange, queryData?.child_query_name])

  return (
    <Form>
      <Form.Group>
        <Form.Dropdown
          label="Select a common query"
          value={currentSelection}
          name={'child_query_name'}
          onChange={(e, {value}) => setCurrentSelection(value)}
          options={[
            {
              key: "road_classification",
              text: "Takeovers by road classification",
              value: "road_classification"
            },
            {
              key: "environmental_condition",
              text: "Takeovers by environmental condition",
              value: "environmental_condition"
            }
          ]}
        />
      </Form.Group>
      <DriveSelector
        handleChange={handleChange}
        phaseDrives={phaseDrives}
        queryData={queryData}
        hideDrives={false}
      />
    </Form>
  )
}

export default EnvironmentalData
