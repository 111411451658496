import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import jwt_decode from 'jwt-decode'
import { Embed, Message } from "semantic-ui-react"

import useWindowDimensions from "../utilities/useWindowDimensions"
import { nowTrunc } from "../utilities/jwt_utils"


const Explore = () => {
  const { pathname } = useLocation()
  const { height } = useWindowDimensions()

  const [ isLoggedIn, setIsLoggedIn ] = useState(false)
  const [ driveId, setDriveId ] = useState()

  useEffect(() => {
    let refreshToken = localStorage.getItem("refresh_token")
    if (refreshToken) {
      let decoded = jwt_decode(refreshToken, nowTrunc())
      if ("exp" in decoded && decoded.exp > nowTrunc()){
        setIsLoggedIn(true)
      } else if (isLoggedIn) {
        setIsLoggedIn(false)
      }
    } else if (isLoggedIn) {
      setIsLoggedIn(false)
    }
  }, [pathname, isLoggedIn])

  useEffect(() => {
    let newId = pathname.split('/').filter(x => x.length > 0).reverse()[0]
    if (newId) {
      setDriveId(newId)
    }

  }, [pathname])

  return <>{
    isLoggedIn ? <Embed
      icon='right circle arrow'
      url={`https://dsri-web-ms.iowa.uiowa.edu/adsfra/?drive=${driveId}`}
      active
      style={{
        height: `${height-188}px`,
        padding: '0px',
        marginTop: "-13px",
      }}
    /> :   <Message
    error
    header='User must be logged in to view this page'
    list={[
      'You must have a valid log-in and be logged in.',
      'Please click "Register" or "Login" above to gain access',
    ]}
  />
  }</>
}


export default Explore
