import { useHistory } from "react-router-dom"
import { Card, Grid, Image } from "semantic-ui-react"

const icons = {
  Clear: "/weather_icons/clear.png",
  Clouds: "/weather_icons/clouds.png",
  Rain: "/weather_icons/rain.png",
  Mist: "/weather_icons/mist.png",
  Snow: "/weather_icons/snow.png",
  Haze: "/weather_icons/haze.png"
}

const WeatherIcons = ({data, phase_number}) => {
  const {push} = useHistory()

  const handleWeatherClick = weather => {
    console.log(weather)
    push(`/drive_list/weather/${weather}${phase_number ? `/${phase_number}` : ''}`)
  }
  return <Grid.Column>
    <Card fluid centered>
      <Card.Content textAlign="center">
        <Grid columns={(data.weathers || []).length || 1}>
          {
            (data.weathers || []).map(weather => {
              return <Grid.Column key={weather}>
                <Card onClick={() => handleWeatherClick(weather)}>
                  <Card.Content>
                  <Image src={icons[weather]} size='mini' />
                  </Card.Content>
                  <Card.Content header={weather} textAlign='center' />
                </Card>
              </Grid.Column>
            })
          }
        </Grid>
      </Card.Content>
    </Card>
  </Grid.Column>
}

export default WeatherIcons
