import { useState, useEffect, Fragment } from 'react'
import {
  useLocation,
  useHistory,
  Link
} from 'react-router-dom'
import { Menu, Image, Breadcrumb } from 'semantic-ui-react'

import jwt_decode from 'jwt-decode'
import { nowTrunc, logOut } from './utilities/jwt_utils'
import React from 'react'

const MenuBarH = () => {
  const {pathname} = useLocation()
  const {push} = useHistory()

  const [ isLoggedIn, setIsLoggedIn ] = useState(false)
  const [ userRole, setUserRole ] = useState("")
  const [ userName, setUserName ] = useState("")
  const [ crumbs, setCrumbs ] = useState([])

  useEffect(() => {
    setCrumbs(pathname.split('/').filter(y => y.length))
  }, [pathname])

  useEffect(() => {
    let refreshToken = localStorage.getItem("refresh_token")
    let role = localStorage.getItem("user_role")
    let identity = localStorage.getItem("identity")
    if (refreshToken) {
      let decoded = jwt_decode(refreshToken, nowTrunc())
      if ("exp" in decoded && decoded.exp > nowTrunc()){
        setIsLoggedIn(true)
        setUserRole(role)
        setUserName(identity)
      } else if (isLoggedIn) {
        setIsLoggedIn(false)
        setUserRole("")
        setUserName("")
      }
    } else if (isLoggedIn) {
      setIsLoggedIn(false)
      setUserRole("")
      setUserName("")
    }
  }, [pathname, isLoggedIn])

  const handleLogOut = () => {
    console.log("Log out")
    logOut()
  }

  return (
    <>
      <div
        style={{
          position: 'fixed',
          width: '100%',
          zIndex: '2'
        }}
        key='div1'
      >
        <Menu
          icon='labeled'
          style={{
            'background': 'rgb(255,205,0)',
          }}
        >
          <Menu.Item key='banner'>
            <Image
              size='medium'
              src='/ADSFRA_logo_large.png'
              onClick={() => push('/')}
            />
          </Menu.Item>
          <Menu.Menu position='right' key='submenu'>
            <Menu.Item
              active={pathname === '/dictionary'}
              onClick={() => push('/dictionary')}
              content='Dictionary'
              icon='book'
              key='dictionary'
            />
            {isLoggedIn ? <Menu.Item
              active={pathname === '/playground'}
              onClick={() => push('/playground')}
              content='Playground'
              icon='lab'
              key='playground'
            /> : null}
            <Menu.Item
              onClick={() => push('/')}
              content='Help'
              icon='help'
              key='help'
              as={Link}
              to={{pathname: 'https://adsforruralamerica.uiowa.edu/data-portal-help'}}
              target="_blank"
            />
            {isLoggedIn ? <>
              <Menu.Item
                active={pathname === '/logout'}
                onClick={handleLogOut}
                content='Logout'
                icon='key'
                key='logout'
              />
            </> : <>
              <Menu.Item
                active={pathname === '/login'}
                onClick={() => push('/login')}
                content='Login'
                icon='key'
                key='login'
              />
              <Menu.Item
                active={pathname === '/register'}
                onClick={() => push('/register')}
                content='Register'
                icon='signup'
                key='register'
              />
            </>}
            {["admin", "superuser"].includes(userRole) ? <>
              <Menu.Item
                active={pathname === '/useradmin'}
                onClick={() => push('/useradmin')}
                content="User Management"
                icon='address book'
                key='useradmin'
              />
            </> : null}
              {userName && <Menu.Item
                active={pathname === `/profile/${userName}`}
                onClick={() => push(`/profile/${userName}`)}
                content='Profile'
                icon='address card'
                key='profile'
              />}
          </Menu.Menu>
        </Menu>
        <div
          style={{
            background: 'rgb(50, 50, 50)',
            paddingTop: '1em',
            paddingBottom: '1em',
            paddingLeft: '2em',
            marginTop: '-1.5em',
            marginBottom: '-1em'
          }}
          key='div3'
        >
          <Breadcrumb size='large' style={{color: 'white'}}>
            <Breadcrumb.Section
              link
              key='home'
              onClick={() => push('/')}
              style={{color: 'white', textDecoration: 'underline'}}
            >
              Home
            </Breadcrumb.Section>
            {
              crumbs.includes('dashboard') ? crumbs.map((crumb, i) => {
                if (crumb === 'dashboard') {
                  return null
                }
                let pathStr
                let crumbName
                // i === 1 (phase)
                // i === 2 (drive)
                if (i === 1) {
                  pathStr = `/dashboard/${crumb}`
                  crumbName = `Phase ${crumb}`
                } else if (i === 2) {
                  pathStr = `/dashboard/${crumbs[1]}/${crumb}`
                  crumbName = `Drive ${crumb}`
                }
                return <React.Fragment key={`frag-${pathStr}`}>
                  <Breadcrumb.Divider
                    icon='right chevron'
                    style={{color: 'white'}}
                    key={`divider-${pathStr}`}
                  />
                  <Breadcrumb.Section
                    link
                    key={pathStr}
                    style={{color: 'white', textDecoration: 'underline'}}
                    onClick={() => push(pathStr)}
                  >
                    {crumbName}
                  </Breadcrumb.Section>
                </React.Fragment>
              })
              : crumbs.map((crumb, i) => {
                let pathStr = '/' + crumbs.slice(0, i+1).join('/')
                return <React.Fragment key={`frag-${pathStr}`}>
                  <Breadcrumb.Divider
                    icon='right chevron'
                    style={{color: 'white'}}
                    key={`divider-${pathStr}`}
                  />
                  <Breadcrumb.Section
                    link
                    key={pathStr}
                    style={{color: 'white', textDecoration: 'underline'}}
                    onClick={() => push(pathStr)}
                  >
                    {crumb}
                  </Breadcrumb.Section>
                </React.Fragment>
              })
            }
          </Breadcrumb>
        </div>
      </div>
      <div style={{height: '120px'}} key='div2'></div>
    </>
  )
}

export default MenuBarH
