import axios from "axios"
import { BASE_URL } from "../../settings"

const getMarkerDisengagementTypes = ({
  setIsLoading,
  setInfoMessages,
  setMarkerDisengagementTypes
}) => {
  setIsLoading(x => x + 1)
  axios.get(BASE_URL + `/marker_disengagement_types`)
  .then(res => res?.data)
  .then(res => {
    setInfoMessages(res?.messages || [])
    setMarkerDisengagementTypes(res.data)
  })
  .then(() => setIsLoading(x => x - 1))
  .catch(err => {
    setIsLoading(x => x - 1)
    setInfoMessages(err?.response?.data?.messages || [
      {
          "header": "Unspecified error",
          "message": 'Something went wrong, but was not specified.',
          "icon": "database",
          "level": "negative"
      }
    ])
  })
}

export default getMarkerDisengagementTypes
