import {
  Container,
  Divider,
  Header,
  Icon,
  Grid
} from "semantic-ui-react"
import DriveInformation from "./DriveInformation"
import AutomationPerformancePie from "./AutomationPerformancePie"
import RoadConditions from "./RoadConditions"
import RoadTypes from "./RoadTypes"
import ApolloObstacles from "./ApolloObstacles"
import Weather from "./Weather"
import DriveMap from "./DriveMap"
import AirTemperature from "./AirTemperature"
import Markers from "./Markers"
import WindSpeed from "./WindSpeed"
import RoadTemperature from "./RoadTemperature"
import SafetyCritical from "./SafetyCritical"
import VulnerableRoadUsers from "./VulnerableRoadUsers"
import V2x from "./V2x"
import PassengerInformationIndividual from "./PassengerInformationIndividual"
import ExploreDrive from "./ExploreDrive"

const COLORS = {
  automatedBackground: 'rgba(67, 163, 243, 0.2)',
  automatedBorder: 'rgba(67, 163, 243, 1)',
  manualBackground: 'rgba(27, 190, 0, 0.2)',
  manualBorder: 'rgba(27, 190, 0, 1)',
}

const DashboardDrive = ({
  chartDataDisplayed,
  setChartDataDisplayed,
  driveId,
  units,
  setChartDataAll
}) => {
  return (<div style={{paddingBottom: '70px'}}>
    <Container>
      <Grid columns={2}>
        <Grid.Column>
          <DriveInformation
            data={chartDataDisplayed}
            setData={setChartDataDisplayed}
            setChartDataAll={setChartDataAll}
          />
          <ExploreDrive driveId={driveId}/>
        </Grid.Column>
        <DriveMap driveId={driveId} />
      </Grid>
    </Container>

    <Divider horizontal section />

    <Container>
      <Grid columns={2} centered>
        <AutomationPerformancePie
          data={chartDataDisplayed}
          units={units}
          colors={COLORS}
        />
        <Markers data={chartDataDisplayed} />
      </Grid>
    </Container>

    <Divider horizontal>
      <Header as='h4'>
        <Icon name='bar chart' />
        Environment
      </Header>
    </Divider>

    <Container>
      <Grid columns={3}>
        <Weather data={chartDataDisplayed} />
        <WindSpeed
          data={chartDataDisplayed}
          units={units}
        />
        <AirTemperature
          data={chartDataDisplayed}
          units={units}
        />
      </Grid>
    </Container>

    <Divider horizontal>
      <Header as='h4'>
        <Icon name='bar chart' />
        Road
      </Header>
    </Divider>

    <Container>
      <Grid columns={3}>
        <RoadConditions
          data={chartDataDisplayed}
          colors={COLORS}
          units={units}
        />
        <RoadTypes
          data={chartDataDisplayed}
          colors={COLORS}
          units={units}
        />
        <RoadTemperature
          data={chartDataDisplayed}
          units={units}
        />
      </Grid>
    </Container>

    <Divider horizontal>
      <Header as='h4'>
        <Icon name='bar chart' />
        Encounters
      </Header>
    </Divider>

    <Container>
      <Grid columns={4} centered>
        <SafetyCritical data={chartDataDisplayed}/>
        <ApolloObstacles data={chartDataDisplayed} />
        <VulnerableRoadUsers data={chartDataDisplayed} />
        <V2x data={chartDataDisplayed} />
      </Grid>
    </Container>

    <Divider section horizontal >
      <Header as='h4'>
        <Icon name='bar chart'/>
        Passengers
      </Header>
    </Divider>

    <Container>
      <Grid columns={1} centered>
        <PassengerInformationIndividual data={chartDataDisplayed} />
      </Grid>
    </Container>
  </div>)
}

export default DashboardDrive
